import React, { useEffect, useState } from 'react';
import './reset-password.module.scss';
import { RouteComponentProps } from 'react-router-dom';
import ResetPasswordForm from './reset-password-form';
import InvalidToken from './invalid-token';
import AuthService from '../../lib/auth/auth-service';
import Loader from '../../components/deprecated/loader/loader';

// Ensure Existence of Token
interface MatchParams {
  token: string;
}

type MatchProps = RouteComponentProps<MatchParams>;

const ResetPasswordContainer = ({ match }: MatchProps): JSX.Element => {
  const { token } = match.params;

  // Check if Token is Valid
  const [validToken, setValidToken] = useState<boolean>(false);
  useEffect(() => {
    (async (): Promise<void> => {
      await AuthService.logOut();
      setValidToken(await AuthService.checkValidToken(token));
    })();
  });

  if (validToken === null) return <Loader />;
  return validToken ? <ResetPasswordForm token={token} /> : <InvalidToken />;
};

export default ResetPasswordContainer;
