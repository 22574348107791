import { Box, Stack, Typography } from '@mui/material';
import React, { FC } from 'react';
import {
  PLAN_NAME_OBJ,
  getCurrencySymbol,
  perCycleAmountBasedOnDuration,
} from 'src/lib/utils';
import { useAppSelector } from 'src/store/hooks';
import colors from 'src/styles/equalution/colors';
import Fonts from 'src/styles/equalution/fonts';
import { EffectivePlanObjType } from '../../PaymentPage';
import { BillingText } from 'src/pages/subscription-new/components/plan-detail';
import { projectCouponCode } from 'src/pages/constants';

interface PlanChangeOptionProps {
  onChangeClick?: () => void;
  finalPlanObj: EffectivePlanObjType;
}
const PlanChangeOption: FC<PlanChangeOptionProps> = ({
  finalPlanObj,
  onChangeClick,
}) => {
  const { selectedPlanId, couponObj, isFitherProjectUser } = useAppSelector(
    state => state.signUp
  );
  const { isPaymentIntentLoading } = useAppSelector(state => state.payment);
  const planType = selectedPlanId.toLowerCase().includes('standard')
    ? 'standard'
    : 'premium';

  if (!finalPlanObj) {
    return null;
  }

  const intervalString = `${finalPlanObj.intervalCount} ${
    finalPlanObj.interval
  }${finalPlanObj.intervalCount > 1 ? 's' : ''}`;

  const unitAmount = Number(finalPlanObj?.unitAmount).toFixed(2);

  const shouldTextChange =
    couponObj && couponObj?.duration?.toLowerCase() === 'once';

  return (
    <Box
      sx={{
        backgroundColor: colors.OFF_WHITE,
        borderRadius: '20px',
      }}>
      <Typography
        sx={{
          fontFamily: Fonts.FontType.regular,
          background:
            planType === 'standard' ? colors.ORANGE_HALF : colors.SALAD,
          fontWeight: 600,
          fontSize: '14px',
          padding: '9px 24px',
          textTransform: 'uppercase',
          borderRadius: '20px 20px 0 0',
          color: planType === 'standard' ? colors.OFF_BLACK : colors.WHITE,
        }}>
        {planType}
      </Typography>
      <Box
        sx={{
          padding: '16px',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <Box width={'110px'} mr={'12px'}>
          <Typography
            sx={{
              fontFamily: Fonts.FontType.regular,
              fontSize: '22px',
              color: colors.SALAD,
              fontWeight: 800,
              textAlign: 'left',
              transform: 'uppercase',
            }}>
            {
              PLAN_NAME_OBJ[
                finalPlanObj.intervalCount + ' ' + finalPlanObj.interval
              ]
            }
          </Typography>
          <Typography
            sx={{
              fontFamily: Fonts.FontType.regular,
              fontSize: '12px',
              color: colors.OFF_BLACK,
              fontWeight: 800,
              textTransform: 'uppercase',
              letterSpacing: '2px',
              textAlign: 'left',
            }}>
            {intervalString}
          </Typography>
        </Box>
        {isFitherProjectUser ? (
          <Stack alignItems={'flex-start'}>
            <Box fontSize={'20px'} fontWeight={800}>
              Free month
            </Box>

            <Stack direction={'row'}>
              <Box fontSize={'12px'} fontWeight={400} mr={'2px'}>
                Then billed
              </Box>
              <Box fontSize={'16px'} fontWeight={700}>
                $69/month
              </Box>
            </Stack>
          </Stack>
        ) : (
          <Box>
            {shouldTextChange ? (
              <>
                <Typography
                  variant="caption"
                  sx={{
                    fontSize: '12px',
                    fontFamily: Fonts.FontType.regular,
                    display: 'inline-block',
                  }}>
                  <b style={{ fontSize: '16px' }}>${unitAmount}</b>
                  {' for first payment.'}
                  <br />
                </Typography>
              </>
            ) : (
              <>
                <Typography
                  sx={{
                    fontFamily: Fonts.FontType.regular,
                    fontSize: '24px',
                    color: colors.OFF_BLACK,
                    fontWeight: 800,
                    lineHeight: 1,
                  }}
                  variant="caption">
                  {getCurrencySymbol() +
                    perCycleAmountBasedOnDuration(
                      finalPlanObj.perCycleAmount,
                      finalPlanObj.intervalCount + ' ' + finalPlanObj.interval
                    ).toFixed(2)}
                </Typography>

                <Typography
                  variant="caption"
                  sx={{
                    fontSize: '12px',
                    fontFamily: Fonts.FontType.regular,
                  }}>
                  /{finalPlanObj.interval}
                </Typography>
              </>
            )}
            <Box
              fontSize={'12px'}
              color={colors.OFF_BLACK}
              fontWeight={400}
              lineHeight={'normal'}>
              <BillingText
                selectedPlanId={selectedPlanId}
                finalPlanObj={finalPlanObj}
              />
            </Box>
          </Box>
        )}

        {!isFitherProjectUser && (
          <Typography
            variant="button"
            sx={{
              fontFamily: Fonts.FontType.regular,
              fontSize: '14px',
              textTransform: 'unset',
              textDecoration: 'underline',
              cursor: isPaymentIntentLoading ? 'not-allowed' : 'pointer',
              color: isPaymentIntentLoading
                ? colors.BLUEBERRY_HALF
                : colors.SALAD,
              fontWeight: 600,
              padding: '8px',
            }}
            onClick={isPaymentIntentLoading ? () => {} : onChangeClick}>
            Change
          </Typography>
        )}
      </Box>
      {isFitherProjectUser && (
        <Stack pl={'16px'} pb={'16px'} mt={'-8px'} alignItems={'flex-start'}>
          <Box
            fontSize={'12px'}
            fontWeight={700}
            lineHeight={'6px'}
            color={colors.UTILITY_GREEN}>
            You've saved $69.
          </Box>
          <Box fontSize={'12px'} fontWeight={400} color={colors.UTILITY_GREEN}>
            {projectCouponCode} applied for a 100% discount for 1 month.
          </Box>
        </Stack>
      )}
    </Box>
  );
};

export default PlanChangeOption;
