import React, { FunctionComponent, useState } from 'react';
import { Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Header from '../../components/header/header';
import styles from './account.module.scss';
import ContentWrapper from '../../components/content/content-wrapper';
import ContentSection from '../../components/content/content-section';
import AccountMenu from './components/account-menu';
import AccountProfile from './sections/profile/account-profile';
import AccountExtras from './sections/profile/account-extras';
import AccountSubscription from './sections/subscription/account-subscription';
import { IAppState } from '../../store';
import moment from 'moment';

const routes = [
  {
    name: 'Profile',
    path: '/account/profile',
    component: AccountProfile,
  },
  {
    name: 'Subscription',
    path: '/account/subscription',
    component: AccountSubscription,
  },
  {
    name: 'Add-ons',
    path: '/account/addons',
    statusesGranted: ['ACTIVE', 'INITIAL'],
    component: AccountExtras,
  },
];

const Account: FunctionComponent = () => {
  const user = useSelector((state: IAppState) => state.auth?.user);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [initialValue, setInitialValue] = useState('');

  if (isInitialRender) {
    setIsInitialRender(false);
    const queryString = window.location.search;
    const parameters = new URLSearchParams(queryString);
    const value = parameters.get('planRequest');
    value ? setInitialValue(value) : setInitialValue('');

    // If the search param contains planRequest=Standard
    // Set the planRequest in session storage
    if (value) {
      sessionStorage.setItem(
        'subscriptions',
        JSON.stringify({
          planRequestExpiresAt: moment().add(30, 'minute').unix(),
          planRequest: value,
        })
      );
    }
  }

  let location = useLocation();
  location.state = initialValue;

  return (
    <>
      <Header module="login" />

      <div className={styles.account}>
        <ContentWrapper
          className={styles.wrapper}
          innerClassName={styles.innerWrapper}
          collapseAt="lg">
          <AccountMenu routes={routes} />
          <ContentSection className={styles.card} collapseAt="lg">
            <Switch>
              {routes.map(route =>
                user?.status ===
                  route?.statusesGranted?.find(r => r === user?.status) ||
                !route.statusesGranted?.length ? (
                  <Route
                    key={route.path}
                    path={route.path}
                    exact
                    component={route.component}
                    location={location}
                  />
                ) : (
                  <Redirect key={route.path} to="/account/profile" />
                )
              )}
              <Redirect to="/account/profile" />
            </Switch>
          </ContentSection>
        </ContentWrapper>
      </div>
    </>
  );
};

export default Account;
