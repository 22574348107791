import React from 'react';
import { Input } from 'reactstrap';
import { FieldProps } from 'formik';
import { useAppSelector } from 'src/store/hooks';

interface OwnProps {
  label: string;
}

type Props = OwnProps;

const PromoInput = ({
  label,
  field: { name, onChange, onBlur, value },
  form: { touched, errors },
  ...attributes
}: Props & FieldProps): JSX.Element => {
  const { isFitherProjectUser } = useAppSelector(state => state.signUp);
  return (
    <Input
      type="text"
      name={name}
      onChange={onChange}
      onBlur={onBlur}
      value={value}
      {...(isFitherProjectUser && {
        disabled: true,
      })}
      {...attributes}
    />
  );
};

export default PromoInput;
