import React, { FunctionComponent } from 'react';
import moment from 'moment';
import {
  Plans,
  Customer,
  SubscriptionSchedules,
  Prices,
} from '../../../../deprecated/payment/types';
import styles from './update-subscription-preview.module.scss';
import AllSubscriptions from 'src/models/AllSubscriptions';
import {
  getCurrencySymbol,
  checkNZUser,
  isUserFromNewZealand,
} from 'src/lib/utils';
import { useSelector } from 'react-redux';
import { IAppState } from 'src/store';

interface OwnProps {
  selectedPlan: string;
  plans: Plans | undefined;
  prices: Prices | undefined;
  customer: Customer | undefined;
  paused: boolean;
  subscriptionSchedules: SubscriptionSchedules[] | undefined;
  allSubscriptions: AllSubscriptions | undefined;
}

const UpdateSubscriptionPreview: FunctionComponent<OwnProps> = ({
  selectedPlan,
  plans,
  prices,
  customer,
  paused,
  subscriptionSchedules,
  allSubscriptions,
}) => {
  const user = useSelector((state: IAppState) => state.auth?.user);

  const subscriptionToShow = !!allSubscriptions?.trialSubscription
    ? allSubscriptions.trialSubscription
    : allSubscriptions?.currentSubscription;
  let nextBillingDateUnix;
  if (subscriptionSchedules && subscriptionSchedules.length > 0 && prices) {
    nextBillingDateUnix = subscriptionSchedules[0].phases[0].start_date;
  } else if (customer && customer.subscriptions?.data?.length > 0) {
    nextBillingDateUnix = customer?.subscriptions?.data[0]?.current_period_end;
  }

  let subscriptionPrice = checkNZUser()
    ? (subscriptionToShow?.currencyOptions?.nzd?.unit_amount || 1) / 100
    : subscriptionToShow?.totalPrice
    ? parseFloat(subscriptionToShow?.totalPrice)
    : 0;

  if (subscriptionToShow?.invoiceDiscount) {
    const { amount_off, percent_off } = subscriptionToShow.invoiceDiscount;

    if (amount_off) {
      subscriptionPrice -= amount_off;
    }

    if (percent_off) {
      subscriptionPrice -= subscriptionPrice * (percent_off / 100);
    }
  }

  const isNewZealandUser =
    isUserFromNewZealand() || (user?.country ?? '') === 'New Zealand';

  return (
    <>
      {selectedPlan === '' || !plans || !allSubscriptions ? (
        <div className={styles.noneSelected}>No plan selected yet.</div>
      ) : !allSubscriptions ? (
        <div className={styles.changeSubscriptionPreview}>
          {!!nextBillingDateUnix && !paused && (
            <div className={styles.nextDate}>
              Next billing date is on{' '}
              {moment(nextBillingDateUnix * 1000).format('DD/MM/YYYY')}
            </div>
          )}
          {paused && <div className={styles.nextDate}>Subscription Paused</div>}
        </div>
      ) : (
        <div className={styles.changeSubscriptionPreview}>
          <div className={styles.price}>
            <div className={styles.big}>{subscriptionToShow?.planName} </div>
            <div className={styles.nextDate}>
              Billed {getCurrencySymbol(isNewZealandUser)}
              {subscriptionPrice || ''} every{' '}
              {(subscriptionToShow?.intervalCount ?? 0) > 1
                ? `${subscriptionToShow?.intervalCount} ${subscriptionToShow?.interval}s`
                : subscriptionToShow?.interval}
            </div>
            {!!subscriptionToShow?.nextBillingDate && (
              <div className={styles.billingDate}>
                Next billing date is on{' '}
                {moment(subscriptionToShow?.nextBillingDate * 1000).format(
                  'DD/MM/YYYY'
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default UpdateSubscriptionPreview;
