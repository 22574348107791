import React, { FC } from 'react';
import { Box } from '@mui/material';
import styles from './info-box.module.scss';
import InfoIcon from 'src/assets/icons/info.svg';
import WarningIcon from 'src/assets/icons/warningRed.svg';

interface InfoBoxProps {
  title?: string;
  showRedIcon?: boolean;
}

export const InfoBox: FC<InfoBoxProps> = ({ title, showRedIcon }) => {
  return (
    <Box className={styles.container}>
      {showRedIcon ? (
        <img src={WarningIcon} alt="warning" />
      ) : (
        <img src={InfoIcon} alt="info" />
      )}
      <Box className={styles.title}>{title}</Box>
    </Box>
  );
};
