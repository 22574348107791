import Axios, { AxiosRequestConfig } from 'axios';
import { decodeCookie } from '../../../lib/cookie-parser';

const paymentAPI = Axios.create({
  baseURL: process.env.REACT_APP_PAYMENT_API,
});

paymentAPI.interceptors.request.use(
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    const cookie = decodeCookie();
    const interceptedConfig = config;
    interceptedConfig.headers = {
      Authorization: (cookie && cookie.token) || '',
    };
    return interceptedConfig;
  }
);
export default paymentAPI;
