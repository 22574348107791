export const UPFRONT = 'UPFRONT';
export const WEEKLY = 'WEEKLY';

export const ENABLE_PAYMENT_OPTIONS = true;
export const CHOOSING_PRODUCTS = 'CHOOSING_PRODUCTS';
export const READY_TO_PURCHASE = 'READY_TO_PURCHASE';
export const GETTING_BRAINTREE_INSTANCE = 'GETTING_BRAINTREE_INSTANCE';
export const HAVE_BRAINTREE_INSTANCE = 'HAVE_BRAINTREE_INSTANCE';
export const PAYMENT_IN_PROGRESS = 'PAYMENT_IN_PROGRESS';
export const COMPLETE = 'COMPLETE';
export const PLAN_NAME = {
  DAILY_TARGETS_ONLY_PLAN: [
    'Daily Targets Only - Weekly Plan',
    'Daily Targets Only - 2 Month Plan',
    'Daily Targets Only - 3 Month Plan',
    'Daily Targets Only - 6 Month Plan',
  ],
  STANDARD_PLAN: [
    'Standard Plan - 1 Month',
    'Standard Plan - 3 Months',
    'Standard Plan - 6 Months',
  ],
  PREMIUM_PLAN: [
    'Premium Plan - Weekly',
    'Premium Plan - 2 Months',
    'Premium Plan - 3 Months',
    'Premium Plan - 6 Months',
  ],
};
export const QUERY_PARAM_STANDARD_PLAN = 'Standard';
export const QUERY_PARAM_STANDARD_PLAN_ONLY = 'StandardOnly';
export const EMAIL_IN_USE = 'Your email is already in use';

export const USER_STATUS = {
  ACTIVE: 'ACTIVE',
  INACTIVE: 'INACTIVE',
  PROSPECT: 'PROSPECT',
  FINAL: 'FINAL',
  SUSPENDED: 'SUSPENDED',
  INITIAL: 'INITIAL',
};

export const selectablePlanIds: number[] = [1, 2];

export enum PLAN_ID {
  INTAKE_WITH_MEAL_PLAN = 1,
  INTAKE_ONLY = 2,
  MEAL_PLAN_ONLY = 3,
  STANDARD = 4,
}

export enum PLAN_TAB {
  STANDARD = 0,
  PREMIUM = 1,
}

export enum PLANS {
  STANDARD_PLAN = 'Standard Plan',
  PREMIUM_PLAN = 'Premium Plan',
  DAILY_TARGETS_ONLY_PLAN = 'Daily Targets Only Plan',
}

export enum TAGS {
  ELLA_MAY_DING_CHALLENGE = 12,
}
export const CUSTOMER_ACCEPTANCE = {
  customer_acceptance: {
    type: 'online',
    online: {
      ip_address: '127.0.0.0',
      user_agent: 'device',
    },
  },
};

export const CUSTOMER_BILLING_ADDRESS = {
  billing_details: {
    name: '',
    email: '',
    address: {
      line1: '',
    },
  },
};

export const CUSTOMER_SHIPPING_ADDRESS = {
  name: '',
  address: {
    line1: '',
  },
};

const commonPlanDetails = [
  {
    title: 'Personalised',
    description: '1 x full-day, custom meal plan provided weekly',
  },
  {
    title: 'Delicious recipes',
    description: 'Discover delicious and healthy recipes all created in-house',
  },
  {
    title: 'Meal swaps feature',
    description: 'Flexible meal options with different recipes',
  },
  {
    title: 'Weekly check-ins',
    description: 'Progress tracker and chat support to maintain motivation',
  },
  {
    title: 'Food diary',
    description:
      'Keep track of your food and water intake through our easy to use tools',
  },
  {
    title: 'Access to the community',
    description: 'Lifetime access to the private Equ Facebook community',
  },
];

export const PLAN_DETAILS_TEXTS: any = {
  standard: {
    shortDesc3Month:
      'Our three month plan is designed to help you establish healthy habits and achieve lasting results, with the support and resources you need to succeed.',
    shortDesc6Month:
      "By committing to a longer-term plan, you'll have the opportunity to make meaningful changes to your health and wellness, and create sustainable habits for a lifetime of optimal health.",
    shortDesc1Month:
      "With our monthly plan you'll start to have access to convenient and delicious recipes that meet your unique dietary needs.",
    details: commonPlanDetails,
  },
  premium: {
    shortDesc6Month:
      'Transform your health with our 6-month plan, designed to give you the time and tools to make lasting changes and see significant progress towards your health goals.',
    shortDesc3Month:
      'Elevate your health and achieve your goals with our 3-month plan, providing you with the resources and support you need to stay on track and make meaningful progress.',
    shortDesc2Month:
      'Kickstart your health journey with our monthly subscription, giving you the flexibility to make changes at your own pace and with ongoing support to help you reach your goals.',
    shortDescWeekly:
      'Try the Equ experience without the commitment - opt for our weekly subscription, cancel anytime after 8 weeks.',
    details: [
      {
        title: 'Personal coaching',
        description: '1:1 personal coaching with an expert',
      },
      ...commonPlanDetails,
    ],
  },
};

export type PlanType = 'standard' | 'premium';

export const projectName = 'fither';

export const projectDuration = {
  start: '2023-10-06T06:00:00',
  end: '2023-10-10T23:59:59',
};

export const projectCouponCode = 'FITHER2023';

export const GOALS = {
  maintain: 2,
  muscle_gain: 3,
  fat_loss: 4,
};

export const GOAL_ADHERENCE_CHALLENGES = {
  IMPROVED_MOOD_AND_ENERGY: 1,
  IMPROVED_ATHLETIC_PERFORMANCE: 2,
  HEALTHIER_RELATIONSHIP_WITH_FOOD: 3,
  BETTER_SLEEP: 4,
  FEEL_MORE_CONFIDENT: 5,
  IMPROVED_CLOTHING_FIT: 6,
  OTHER: 7,
};

export const PROBLEM_HITTING_GOALS = {
  STICKING_TO_NUTRITIONAL_NEEDS: 1,
  NOT_KNOWING_TO_STRUCTURE_DIET: 2,
  NOT_ENOUGH_EXERCISE: 3,
  LACK_OF_TIME: 4,
  LIFESTYLE_OR_ROUTINE: 5,
  OTHER: 6,
};

export const MEAL_ADHERENCE_CHALLENGES = {
  BOREDOM_OR_LATE_NIGHT_SNACKING: 1,
  STRESS_EATING: 2,
  BINGE_EATING: 3,
  NO_TIME_TO_MAKE_NUTRITIONAL_MEALS: 4,
  PORTION_SIZES: 5,
  DINEOUT_ALCOHOL: 6,
  TOO_EXPENSIVE: 7,
  HEALTHY_FOOD_UNINTERESTING: 8,
  OTHER: 9,
};

export const STRESS_LEVEL = {
  MINIMAL: 1,
  MILD: 2,
  MODERATE: 3,
  HIGH: 4,
  SEVERE: 5,
};

export const ACTIVITY_LEVEL = {
  CHILLER: 1,
  STEADY: 2,
  GO_GETTER: 3,
  SUPER_CHARGED: 4,
};

export const HEARD_ABOUT_EQ = {
  FRIEND_FAMILY: 1,
  INSTAGRAM: 2,
  FACEBOOK: 3,
  TIKTOK: 4,
  GOOGLE_SEARCH: 5,
  SPOTIFY: 6,
  RETURNING_USER: 7,
  NONE: 8,
};
export const INTEREST_ARR = {
  MEAL_DELIVERIES: 1,
  ONLINE_GROCERY_SHOPPING_INTEGRATION: 2,
  WEARABLE_INTEGRATION: 3,
  // ACCESS_TO_RECIPE_LIBRARY: 4, // DEPRECATED ANSWER
  ACCOUNTABILITY_PARTNERS: 5,
  LOCAL_MEETUP_AND_EVENTS: 6,
  SUPPLEMENT_PRODUCTS: 7,
  OZEMPIC: 8,
  NUTRITION_FOR_SLEEP_ENERGY_MOOD: 9,
  NONE: 10,
};

export const SYNC_FAILED_REASON = {
  SYNCED_FROM_USER_NOT_ACTIVE: 'SyncedFrom user is not active',
  SYNCED_TO_USER_NOT_ACTIVE: 'SyncedTo user is not active',
  CHECK_IN_DAY_MISMATCH:
    'Check-in day of synced from user and synced to user is mismatched',
};

export enum BREASTFEEDING_FREQUENCY {
  NOT_APPLICABLE = 0,
  EVERY_2_4_HOURS = 1,
  EVERY_4_5_HOURS = 2,
  EVERY_6_8_HOURS = 3,
}

export const QUIZ_STEPS = {
  QUIZ_COMPLETED: 1,
  TASTE_PREFERENCES_SUBMITTED: 2,
  PERSONAL_COMMITMENT_SUBMITTED: 3,
};

export enum MeasurementType {
  Metric = 'Metric',
  Imperial = 'Imperial',
}

// Summary Screen Goal Description
export const goalDetails = [
  {
    id: 4,
    name: 'Fat Loss',
    description:
      'We can support you to sustainably hit your weight loss goals through consistency on the Equ program over a realistic timeline.',
  },
  {
    id: 3,
    name: 'Muscle Gain',
    description:
      'We can support your goal to convert extra energy from a calorie surplus to build muscle mass. An exercise program incorporating weight training is essential.',
  },
  {
    id: 2,
    name: 'Maintain',
    description:
      'We can support optimal body composition changes to reduce body fat whilst maintaining your overall weight.',
  },
];

export const motivationDetails = [
  {
    id: 1,
    name: 'Improved mood and energy',
    description:
      'We promote balanced nutrition to provide your body with essential nutrients for optimal brain function and energy production.',
  },
  {
    id: 2,
    name: 'Improved athletic performance',
    description:
      'We break down your macronutrient needs which are essential to maintain energy levels, muscle growth and improve mental focus.',
  },
  {
    id: 3,
    name: 'Healthier relationship with food',
    description:
      'We follow an 80/20 approach to nutrition: 80% nutrient-dense whole food, and 20% your favorite indulgences. You’ll enjoy the food you love on a balanced diet without feeling restricted!',
  },
  {
    id: 4,
    name: 'Better sleep',
    description:
      'Our balanced approach to nutrition made up largely of whole foods, provide a range of macronutrients, vitamins and minerals which contribute to better sleep.',
  },
  {
    id: 5,
    name: 'Feel more confident',
    description:
      'By consistently working towards your goal on the program, you’ll see progress that builds your confidence from the inside out. We equip you with the knowledge and tools to make lasting changes.',
  },
  {
    id: 6,
    name: 'Improved clothing fit',
    description:
      'We believe that nourishing your body with balanced nutrition has long term benefits to your health and body composition, so you’ll look and feel better than ever.',
  },
];

export const MAX_HEIGHT_CENTIMETERS = 300;
export const MAX_HEIGHT_FEET = 9;
export const MAX_HEIGHT_INCH = 12;
export const MAX_WEIGHT_KG = 600;
export const MAX_WEIGHT_POUND = 1322;
export const MAX_WATER_INTAKE = 30;
export const MIN_WATER_INTAKE = 1;
export const MAX_SLEEP_HOURS = 24;
export const MIN_SLEEP_HOURS = 1;
