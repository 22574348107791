import React, { FunctionComponent } from 'react';
import { PaymentMethod } from '@stripe/stripe-js';
import styles from './payment-preview.module.scss';

interface OwnProps {
  paymentMethod: PaymentMethod | undefined;
}

type Props = OwnProps;

const PaymentPreview: FunctionComponent<Props> = ({ paymentMethod }: Props) => {
  if (!paymentMethod) {
    return (
      <div className={styles.noneSelected}>No payment details entered</div>
    );
  }
  if (!paymentMethod.card) {
    return <div className={styles.noneSelected}>Payment details submitted</div>;
  }

  return (
    <div className={styles.paymentPreview}>
      <div
        className={`${styles.cardBrandIcon} ${
          styles[paymentMethod.card.brand]
        }`}
      />
      <div className={styles.card}>
        <div className={styles.brandNumber}>
          <span className={styles.big}>{paymentMethod.card.brand}</span> ending
          in {paymentMethod.card.last4}
        </div>
        <div className={styles.expiry}>
          Expires:{' '}
          {`${paymentMethod.card.exp_month}/${paymentMethod.card.exp_year}`}
        </div>
      </div>
    </div>
  );
};

export default PaymentPreview;
