import React, { FC, useCallback, useState } from 'react';
import { Box, ButtonBase } from '@mui/material';
import Alert from '../alert/Alert';
import eye from 'src/assets/icons/eye2.svg';
import styles from './text-input.module.scss';

export interface TextInputProps {
  label: string;
  value: string;
  id?: string;
  name?: string;
  onChangeValue?: (value: string) => void;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: React.FocusEventHandler<HTMLInputElement>;
  placeholder?: string;
  errorText?: string;
  passwordField?: boolean;
  disabled?: boolean;
}

export const TextInput: FC<TextInputProps> = ({
  label,
  value,
  id,
  name,
  onChangeValue,
  onChange,
  onBlur,
  placeholder,
  errorText,
  passwordField,
  disabled,
}) => {
  const [passwordHidden, setPasswordHidden] = useState(true);
  const togglePasswordHidden = useCallback((): void => {
    setPasswordHidden(!passwordHidden);
  }, [passwordHidden]);

  return (
    <Box className={styles.container}>
      <span className={styles.label}>{label}</span>
      <Box className={`${styles.inputContainer} ${errorText && styles.error}`}>
        <input
          type={passwordField && passwordHidden ? 'password' : 'text'}
          placeholder={placeholder}
          className={styles.input}
          id={id}
          name={name}
          disabled={disabled}
          value={value}
          onChange={e => {
            onChange?.(e);
            onChangeValue?.(e.target.value);
          }}
          onBlur={onBlur}
        />
        {passwordField && (
          <ButtonBase
            onClick={togglePasswordHidden}
            disableRipple
            className={styles.eyeButton}>
            <img
              src={eye}
              alt="eye"
              className={`${!passwordHidden && styles.iconOff}`}
            />
          </ButtonBase>
        )}
      </Box>
      <Alert message={errorText} />
    </Box>
  );
};
