import { combineReducers, Reducer, AnyAction } from 'redux';
import IAuthState from '../lib/auth/types';
import ISteps from './signup-workflow/types';
import authReducer from '../lib/auth/reducers';
import signupReducer from './signup-workflow/reducers';
import signUpReducer, { InitialStateType } from './signup';
import quizReducer, { QuizStateType } from './quiz';
import paymentReducer, { PaymentStateType } from './payment';

export interface IAppState {
  auth: IAuthState;
  signupWorkflow: ISteps;
  signUp: InitialStateType;
  quiz: QuizStateType;
  payment: PaymentStateType;
}

const createRootReducer = (): Reducer<
  {
    auth: IAuthState;
    signupWorkflow: ISteps;
    signUp: InitialStateType;
    quiz: QuizStateType;
    payment: PaymentStateType;
  },
  AnyAction
> =>
  combineReducers({
    auth: authReducer,
    signupWorkflow: signupReducer,
    signUp: signUpReducer,
    quiz: quizReducer,
    payment: paymentReducer,
  }) as Reducer<
    {
      auth: IAuthState;
      signupWorkflow: ISteps;
      signUp: InitialStateType;
      quiz: QuizStateType;
      payment: PaymentStateType;
    },
    AnyAction
  >;

export default createRootReducer;
