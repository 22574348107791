import React, {
  FunctionComponent,
  PropsWithChildren,
  useRef,
  useState,
} from 'react';
import { Alert } from 'reactstrap';
import styles from './toast.module.scss';

type ToastType = 'success' | 'info' | 'danger';

interface Toast {
  id: number;
  text: string;
  type: ToastType;
  hidden: boolean;
}

export const ToastContext = React.createContext({
  addToast: (text: string, type: ToastType, timeout: number): void => {},
});

let toastCount = 0;

const ToastWrapper: FunctionComponent<PropsWithChildren<{}>> = ({
  children,
}) => {
  const [toasts, setToasts] = useState<Toast[]>([]);
  const toastsRef = useRef(toasts);
  toastsRef.current = toasts;

  const removeFinal = (id: number): void => {
    const newToasts = toastsRef.current.filter(
      (toast: Toast): boolean => toast.id !== id
    );
    setToasts(newToasts);
  };

  const removeToast = (id: number): void => {
    const newToast = toastsRef.current.filter(
      (toast: Toast): boolean => toast.id === id
    )[0];
    if (!newToast) return;

    newToast.hidden = true;

    const newToasts = toastsRef.current.filter(
      (toast: Toast): boolean => toast.id !== id
    );
    setToasts([...newToasts, newToast].sort((a, b): number => a.id - b.id));

    setTimeout(() => removeFinal(id), 1000);
  };

  const addToast = (text: string, type: ToastType, timeout = 3000): void => {
    toastCount += 1;
    const toast: Toast = { id: toastCount, text, type, hidden: false };

    setToasts([...toasts, toast]);
    setTimeout(() => removeToast(toast.id), timeout);
  };

  return (
    <ToastContext.Provider value={{ addToast }}>
      {children}
      <div className={styles.toastWrapper}>
        {toasts.map(
          (toast: Toast): JSX.Element => (
            <div key={toast.id} className={toast.hidden ? styles.hidden : ''}>
              <Alert
                color={toast.type}
                onClick={(): void => removeToast(toast.id)}>
                {toast.text}
              </Alert>
            </div>
          )
        )}
      </div>
    </ToastContext.Provider>
  );
};

export default ToastWrapper;
