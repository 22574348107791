import React, { FC } from 'react';
import { Box, ButtonBase } from '@mui/material';
import styles from './counter-button.module.scss';
import colors from 'src/styles/equalution/colors';

interface CounterButtonProps {
  value: number;
  setCounter: (value: number) => void;
  MIN?: number;
  MAX?: number;
  overRideCounterStepBackground?: boolean;
}

const CounterButton: FC<CounterButtonProps> = ({
  value,
  setCounter,
  MIN = 1,
  MAX = 7,
  overRideCounterStepBackground = false,
}) => {
  return (
    <Box className={styles.counter}>
      <ButtonBase
        onClick={() => {
          if (value > MIN) {
            setCounter(value - 1);
          }
        }}
        className={styles.decrementButton}
        style={{
          backgroundColor: overRideCounterStepBackground
            ? colors.CREAM_HALF
            : colors.CREAM,
        }}
        disabled={value <= MIN}>
        <div
          className={styles.value}
          style={{
            color: value <= MIN ? colors.WHITE : colors.SALAD,
          }}>
          -
        </div>
      </ButtonBase>
      <div
        className={`${styles.buttonBox} ${styles.valueBox}  ${styles.value}`}>
        {value}
      </div>
      <ButtonBase
        onClick={() => {
          if (value < MAX) {
            setCounter(value + 1);
          }
        }}
        disabled={value >= MAX}
        style={{
          backgroundColor: overRideCounterStepBackground
            ? colors.CREAM_HALF
            : colors.CREAM,
        }}>
        <div
          className={styles.value}
          style={{ color: value >= MAX ? colors.WHITE : colors.SALAD }}>
          +
        </div>
      </ButtonBase>
    </Box>
  );
};

export default CounterButton;
