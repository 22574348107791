import React, { useContext, useMemo } from 'react';
import { Reinforcer } from 'src/components/reinforcer/Reinforcer';
import reinforcer_bg_3 from 'src/assets/images/reinforcer_bg_3.jpg';
import reinforcer_bg_small_3 from 'src/assets/images/reinforcer_bg_small_3.jpg';
import QuizPageWrapper from '../QuizPageWrapper';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from 'src/store';
import { Box, Typography } from '@mui/material';

import { OptionButton } from 'src/components/option-button/OptionButton';
import {
  ACTIVITY_LEVEL,
  MAX_SLEEP_HOURS,
  MAX_WATER_INTAKE,
  MEAL_ADHERENCE_CHALLENGES,
  MIN_SLEEP_HOURS,
  MIN_WATER_INTAKE,
  PROBLEM_HITTING_GOALS,
  STRESS_LEVEL,
} from 'src/pages/constants';
import { updateQuizData } from 'src/store/quiz';
import { CircleButton } from 'src/components/circle-button/CircleButton';
import { NumberInput } from 'src/components/number-input/NumberInput';
import { InfoBox } from 'src/components/info-box/InfoBox';
import colors from 'src/styles/equalution/colors';
import CounterButton from 'src/components/counter-button/CounterButton';
import { ToastContext } from 'src/components/toast/toast';
import { useQuizTracking } from '../../hooks';

const BiggestChallenge = () => {
  const { quizData } = useSelector((state: IAppState) => state.quiz);
  const dispatch = useDispatch();
  const trackEvent = useQuizTracking();

  return (
    <Box>
      <OptionButton
        title="Sticking to nutritional needs"
        isSelected={
          quizData.problem_hitting_goal ===
          PROBLEM_HITTING_GOALS.STICKING_TO_NUTRITIONAL_NEEDS
        }
        onPress={() => {
          trackEvent('GoalChallenge', 'Sticking to nutritional needs');
          dispatch(
            updateQuizData({
              problem_hitting_goal:
                PROBLEM_HITTING_GOALS.STICKING_TO_NUTRITIONAL_NEEDS,
              currentStep: 15,
            })
          );
        }}
      />
      <OptionButton
        title="Not knowing how to structure my diet"
        isSelected={
          quizData.problem_hitting_goal ===
          PROBLEM_HITTING_GOALS.NOT_KNOWING_TO_STRUCTURE_DIET
        }
        onPress={() => {
          trackEvent('GoalChallenge', 'Not knowing how to structure my diet');
          dispatch(
            updateQuizData({
              problem_hitting_goal:
                PROBLEM_HITTING_GOALS.NOT_KNOWING_TO_STRUCTURE_DIET,
              currentStep: 18,
            })
          );
        }}
      />
      <OptionButton
        title="Not enough exercise"
        isSelected={
          quizData.problem_hitting_goal ===
          PROBLEM_HITTING_GOALS.NOT_ENOUGH_EXERCISE
        }
        onPress={() => {
          trackEvent('GoalChallenge', 'Not enough exercise');
          dispatch(
            updateQuizData({
              problem_hitting_goal: PROBLEM_HITTING_GOALS.NOT_ENOUGH_EXERCISE,
              currentStep: 16,
            })
          );
        }}
      />
      <OptionButton
        title="Lack of time"
        isSelected={
          quizData.problem_hitting_goal === PROBLEM_HITTING_GOALS.LACK_OF_TIME
        }
        onPress={() => {
          trackEvent('GoalChallenge', 'Lack of time');
          dispatch(
            updateQuizData({
              problem_hitting_goal: PROBLEM_HITTING_GOALS.LACK_OF_TIME,
              currentStep: 19,
            })
          );
        }}
      />
      <OptionButton
        title="My lifestyle or routine"
        isSelected={
          quizData.problem_hitting_goal ===
          PROBLEM_HITTING_GOALS.LIFESTYLE_OR_ROUTINE
        }
        onPress={() => {
          trackEvent('GoalChallenge', 'My lifestyle or routine');
          dispatch(
            updateQuizData({
              problem_hitting_goal: PROBLEM_HITTING_GOALS.LIFESTYLE_OR_ROUTINE,
              currentStep: 17,
            })
          );
        }}
      />
      <OptionButton
        title="Other"
        isSelected={
          quizData.problem_hitting_goal === PROBLEM_HITTING_GOALS.OTHER
        }
        onPress={() => {
          trackEvent('GoalChallenge', 'Other');
          dispatch(
            updateQuizData({
              problem_hitting_goal: PROBLEM_HITTING_GOALS.OTHER,
              currentStep: 20,
            })
          );
        }}
      />
    </Box>
  );
};

const MealChallenge = () => {
  const dispatch = useDispatch();
  const trackEvent = useQuizTracking();

  const { addToast } = useContext(ToastContext);
  const [mealAdherenceChallenges, setMealAdherenceChallenges] = React.useState<
    Array<number>
  >([]);

  const handleClick = (value: number) => {
    const mealAdherenceChallengesCopy = [...mealAdherenceChallenges];
    if (!mealAdherenceChallengesCopy.includes(value)) {
      if (mealAdherenceChallengesCopy.length < 3) {
        mealAdherenceChallengesCopy.push(value);
      } else {
        addToast('Your can select upto 3 options.', 'info', 5000);
      }
    } else {
      mealAdherenceChallengesCopy.splice(
        mealAdherenceChallengesCopy.indexOf(value),
        1
      ); //deleting
    }
    setMealAdherenceChallenges(mealAdherenceChallengesCopy);
  };
  const handleSubmit = () => {
    dispatch(
      updateQuizData({
        meal_adherence_challenges: mealAdherenceChallenges,
        currentStep: 20,
      })
    );
  };
  return (
    <Box>
      <OptionButton
        title="Boredom / late night snacking"
        isSelected={mealAdherenceChallenges.includes(
          MEAL_ADHERENCE_CHALLENGES.BOREDOM_OR_LATE_NIGHT_SNACKING
        )}
        onPress={() => {
          trackEvent('MealChallenge', 'Boredom / late night snacking');
          handleClick(MEAL_ADHERENCE_CHALLENGES.BOREDOM_OR_LATE_NIGHT_SNACKING);
        }}
      />
      <OptionButton
        title="Stress / emotional eating"
        isSelected={mealAdherenceChallenges.includes(
          MEAL_ADHERENCE_CHALLENGES.STRESS_EATING
        )}
        onPress={() => {
          trackEvent('MealChallenge', 'Stress / emotional eating');
          handleClick(MEAL_ADHERENCE_CHALLENGES.STRESS_EATING);
        }}
      />
      <OptionButton
        title="Binge eating"
        isSelected={mealAdherenceChallenges.includes(
          MEAL_ADHERENCE_CHALLENGES.BINGE_EATING
        )}
        onPress={() => {
          trackEvent('MealChallenge', 'Binge eating');
          handleClick(MEAL_ADHERENCE_CHALLENGES.BINGE_EATING);
        }}
      />
      <OptionButton
        title="No time to make nutritious meals"
        isSelected={mealAdherenceChallenges.includes(
          MEAL_ADHERENCE_CHALLENGES.NO_TIME_TO_MAKE_NUTRITIONAL_MEALS
        )}
        onPress={() => {
          trackEvent('MealChallenge', 'No time to make nutritious meals');
          handleClick(
            MEAL_ADHERENCE_CHALLENGES.NO_TIME_TO_MAKE_NUTRITIONAL_MEALS
          );
        }}
      />
      <OptionButton
        title="Portion sizes"
        isSelected={mealAdherenceChallenges.includes(
          MEAL_ADHERENCE_CHALLENGES.PORTION_SIZES
        )}
        onPress={() => {
          trackEvent('MealChallenge', 'Portion sizes');
          handleClick(MEAL_ADHERENCE_CHALLENGES.PORTION_SIZES);
        }}
      />
      <OptionButton
        title="Dining out / alcohol"
        isSelected={mealAdherenceChallenges.includes(
          MEAL_ADHERENCE_CHALLENGES.DINEOUT_ALCOHOL
        )}
        onPress={() => {
          trackEvent('MealChallenge', 'Dining out / alcohol');
          handleClick(MEAL_ADHERENCE_CHALLENGES.DINEOUT_ALCOHOL);
        }}
      />
      <OptionButton
        title="Too expensive"
        isSelected={mealAdherenceChallenges.includes(
          MEAL_ADHERENCE_CHALLENGES.TOO_EXPENSIVE
        )}
        onPress={() => {
          trackEvent('MealChallenge', 'Too expensive');
          handleClick(MEAL_ADHERENCE_CHALLENGES.TOO_EXPENSIVE);
        }}
      />
      <OptionButton
        title="Healthy food is uninteresting"
        isSelected={mealAdherenceChallenges.includes(
          MEAL_ADHERENCE_CHALLENGES.HEALTHY_FOOD_UNINTERESTING
        )}
        onPress={() => {
          trackEvent('MealChallenge', 'Healthy food is uninteresting');
          handleClick(MEAL_ADHERENCE_CHALLENGES.HEALTHY_FOOD_UNINTERESTING);
        }}
      />
      <OptionButton
        title="Other"
        isSelected={mealAdherenceChallenges.includes(
          MEAL_ADHERENCE_CHALLENGES.OTHER
        )}
        onPress={() => {
          trackEvent('MealChallenge', 'Other');
          handleClick(MEAL_ADHERENCE_CHALLENGES.OTHER);
        }}
      />
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'flex-end',
          justifyContent: 'end',
        }}>
        <CircleButton
          onPress={handleSubmit}
          disabled={mealAdherenceChallenges.length === 0}
        />
      </Box>
    </Box>
  );
};

const ActivityStep = () => {
  const { quizData } = useSelector((state: IAppState) => state.quiz);
  const dispatch = useDispatch();
  const trackEvent = useQuizTracking();

  const handleClick = (value: number) => {
    dispatch(updateQuizData({ activity_level: value, currentStep: 22 }));
  };
  return (
    <Box>
      <Box sx={{ margin: '10px 0px 20px', width: '100%' }}>
        <InfoBox title="Please note, daily activity can include walking." />
      </Box>
      <OptionButton
        title="Chiller"
        description="Sedentary, i.e. less than 6000 steps per day"
        isSelected={quizData.activity_level === ACTIVITY_LEVEL.CHILLER}
        onPress={() => {
          trackEvent('ActivityLevel', 'Chiller');
          handleClick(ACTIVITY_LEVEL.CHILLER);
        }}
      />
      <OptionButton
        title="Steady Strider"
        description="Lightly active, i.e. between 6000 - 10,000 steps per day"
        isSelected={quizData.activity_level === ACTIVITY_LEVEL.STEADY}
        onPress={() => {
          trackEvent('ActivityLevel', 'Steady Strider');
          handleClick(ACTIVITY_LEVEL.STEADY);
        }}
      />
      <OptionButton
        title="Go-Getter"
        description="Moderately active, i.e. between 10,000 - 12,000 steps per day"
        isSelected={quizData.activity_level === ACTIVITY_LEVEL.GO_GETTER}
        onPress={() => {
          trackEvent('ActivityLevel', 'Go-Getter');
          handleClick(ACTIVITY_LEVEL.GO_GETTER);
        }}
      />
      <OptionButton
        title="Supercharged"
        description="Highly active, i.e. 12,000+ steps per day"
        isSelected={quizData.activity_level === ACTIVITY_LEVEL.SUPER_CHARGED}
        onPress={() => {
          trackEvent('ActivityLevel', 'Supercharged');
          handleClick(ACTIVITY_LEVEL.SUPER_CHARGED);
        }}
      />
    </Box>
  );
};

const SleepStep = () => {
  const { quizData } = useSelector((state: IAppState) => state.quiz);
  const dispatch = useDispatch();
  const trackEvent = useQuizTracking();

  const { addToast } = useContext(ToastContext);

  const handleSubmit = () => {
    trackEvent('Sleep', quizData.sleep_hours);
    dispatch(
      updateQuizData({
        currentStep: 23,
      })
    );
  };
  const handleChange = (val: string) => {
    dispatch(
      updateQuizData({
        sleep_hours: val,
      })
    );
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Box sx={{ margin: '15% 0px' }}>
        <NumberInput
          label="Hours"
          value={quizData.sleep_hours}
          autoFocus
          onChangeValue={val => {
            if (
              (val && parseFloat(val) > MAX_SLEEP_HOURS) ||
              (val && parseFloat(val) < MIN_SLEEP_HOURS)
            ) {
              addToast('Please enter valid hours.', 'danger', 5000);
            } else {
              handleChange(val);
            }
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'flex-end',
          justifyContent: 'end',
          marginTop: 3,
        }}>
        <CircleButton onPress={handleSubmit} disabled={!quizData.sleep_hours} />
      </Box>
    </Box>
  );
};

const HydrationStep = () => {
  const { quizData } = useSelector((state: IAppState) => state.quiz);
  const dispatch = useDispatch();
  const trackEvent = useQuizTracking();

  const { addToast } = useContext(ToastContext);

  const handleSubmit = () => {
    trackEvent('WaterIntake', quizData.water_intake);
    dispatch(
      updateQuizData({
        currentStep: 24,
      })
    );
  };
  const handleChange = (val: string) => {
    dispatch(
      updateQuizData({
        water_intake: val,
      })
    );
  };
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Box sx={{ margin: '15% 0px' }}>
        <NumberInput
          label="Litres"
          value={quizData.water_intake}
          autoFocus
          onChangeValue={val => {
            if (
              (val && parseFloat(val) > MAX_WATER_INTAKE) ||
              (val && parseFloat(val) < MIN_WATER_INTAKE)
            ) {
              addToast('Please enter valid amount.', 'danger', 5000);
            } else {
              handleChange(val);
            }
          }}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          alignItems: 'flex-end',
          justifyContent: 'end',
          marginTop: 3,
        }}>
        <CircleButton
          onPress={handleSubmit}
          disabled={!quizData.water_intake}
        />
      </Box>
    </Box>
  );
};

const StressLevelStep = () => {
  const { quizData } = useSelector((state: IAppState) => state.quiz);
  const dispatch = useDispatch();
  const trackEvent = useQuizTracking();

  const handleClick = (value: number) => {
    dispatch(updateQuizData({ stress_level: value, currentStep: 25 }));
  };
  return (
    <Box>
      <OptionButton
        title="Minimal"
        description="Rarely stressed with no impact to daily life or wellbeing."
        isSelected={quizData.stress_level === STRESS_LEVEL.MINIMAL}
        onPress={() => {
          trackEvent('StressLevel', 'Minimal');
          handleClick(STRESS_LEVEL.MINIMAL);
        }}
      />
      <OptionButton
        title="Mild"
        description="Occasional stress with minor impact to daily life or wellbeing."
        isSelected={quizData.stress_level === STRESS_LEVEL.MILD}
        onPress={() => {
          trackEvent('StressLevel', 'Mild');
          handleClick(STRESS_LEVEL.MILD);
        }}
      />
      <OptionButton
        title="Moderate"
        description="Noticeable stress which occasionally impacts daily life or wellbeing"
        isSelected={quizData.stress_level === STRESS_LEVEL.MODERATE}
        onPress={() => {
          trackEvent('StressLevel', 'Moderate');
          handleClick(STRESS_LEVEL.MODERATE);
        }}
      />
      <OptionButton
        title="High"
        description="Significant stress which often impacts daily life or wellbeing."
        isSelected={quizData.stress_level === STRESS_LEVEL.HIGH}
        onPress={() => {
          trackEvent('StressLevel', 'High');
          handleClick(STRESS_LEVEL.HIGH);
        }}
      />
      <OptionButton
        title="Severe"
        description="Extreme or chronic stress which always impacts daily life or wellbeing."
        isSelected={quizData.stress_level === STRESS_LEVEL.SEVERE}
        onPress={() => {
          trackEvent('StressLevel', 'Severe');
          handleClick(STRESS_LEVEL.SEVERE);
        }}
      />
    </Box>
  );
};

const WorkoutStep = () => {
  const { quizData } = useSelector((state: IAppState) => state.quiz);
  const dispatch = useDispatch();
  const trackEvent = useQuizTracking();

  const handleClick = () => {
    trackEvent('ExerciseDay', {
      low_intensity_days: quizData.low_intensity_days,
      high_intensity_days: quizData.high_intensity_days,
    });
    dispatch(
      updateQuizData({
        currentStep: 21,
      })
    );
  };

  const overRideCounterStepBackground = useMemo(
    () => [20].includes(quizData.currentStep),
    [quizData.currentStep]
  );

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: { xs: 'space-between', md: 'start' },
      }}>
      <Box sx={{ width: '100%', margin: '15px 0px' }}>
        <InfoBox title="Please note, this excludes walking activity." />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          margin: '10% 0px',
          width: '100%',
        }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            sx={{
              fontSize: '20px',
              fontWeight: 700,
              color: colors.OFF_BLACK,
              lineHeight: 1.5,
            }}>
            Low intensity workout
          </Typography>
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: 400,
              color: colors.BLUEBERRY_HALF,
              lineHeight: 1.5,
            }}>
            eg. Pilates, yoga
          </Typography>
        </Box>
        <CounterButton
          value={quizData.low_intensity_days}
          MIN={0}
          setCounter={val => {
            dispatch(updateQuizData({ low_intensity_days: val }));
          }}
          overRideCounterStepBackground={overRideCounterStepBackground}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          margin: '10% 0px',
          width: '100%',
        }}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
          <Typography
            sx={{
              fontSize: '20px',
              fontWeight: 700,
              color: colors.OFF_BLACK,
              lineHeight: 1.5,
            }}>
            High intensity workout
          </Typography>
          <Typography
            sx={{
              fontSize: '12px',
              fontWeight: 400,
              color: colors.BLUEBERRY_HALF,
              lineHeight: 1.5,
            }}>
            eg. running, cycling
          </Typography>
        </Box>
        <CounterButton
          value={quizData.high_intensity_days}
          MIN={0}
          setCounter={val => {
            dispatch(updateQuizData({ high_intensity_days: val }));
          }}
          overRideCounterStepBackground={overRideCounterStepBackground}
        />
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'end',
          width: '100%',
          margin: '10% 0px',
        }}>
        <CircleButton onPress={() => handleClick()} />
      </Box>
    </Box>
  );
};

const LifestyleSection = () => {
  const { quizData } = useSelector((state: IAppState) => state.quiz);
  const dispatch = useDispatch();

  if (quizData.currentStep === 14) {
    return (
      <QuizPageWrapper
        title="What’s the biggest challenge to hitting your goals?"
        subTitle="So we can support you to overcome these.">
        <BiggestChallenge />
      </QuizPageWrapper>
    );
  }
  if (quizData.currentStep === 15) {
    return (
      <QuizPageWrapper
        title="What makes it challenging to stick to nutritional meals?"
        subTitle="Select up to 3 to start with.">
        <MealChallenge />
      </QuizPageWrapper>
    );
  }
  if (quizData.currentStep === 16) {
    return (
      <Reinforcer
        buttonClick={() => dispatch(updateQuizData({ currentStep: 20 }))}
        buttonText="I’m ready to build my knowledge!"
        titleText="Let's crush diet myths; Exercise isn't needed to see fat loss goals."
        subtitleText="With proper nutrition in a calorie deficit, you can see results irrespective of your exercise level."
        backgroundImage={`url(${reinforcer_bg_3})`}
        backgroundImageSmall={`url(${reinforcer_bg_small_3})`}
      />
    );
  }
  if (quizData.currentStep === 17) {
    return (
      <Reinforcer
        buttonClick={() => dispatch(updateQuizData({ currentStep: 20 }))}
        buttonText="I’m ready to build my knowledge!"
        titleText="You’re in luck - our tools can help integrate balanced nutrition into any lifestyle!"
        backgroundImage={`url(${reinforcer_bg_3})`}
        backgroundImageSmall={`url(${reinforcer_bg_small_3})`}
      />
    );
  }
  if (quizData.currentStep === 18) {
    return (
      <Reinforcer
        buttonClick={() => dispatch(updateQuizData({ currentStep: 20 }))}
        buttonText="I’m ready to build my knowledge!"
        titleText="We've got you covered with a balanced meal plan from breakfast to dessert!"
        subtitleText="Our plans are designed to assist in structuring your day to hit your daily targets."
        backgroundImage={`url(${reinforcer_bg_3})`}
        backgroundImageSmall={`url(${reinforcer_bg_small_3})`}
      />
    );
  }
  if (quizData.currentStep === 19) {
    return (
      <Reinforcer
        buttonClick={() => dispatch(updateQuizData({ currentStep: 20 }))}
        buttonText="I’m ready to build my knowledge!"
        titleText="Our recipes are quick, easy and nutritionally balanced to save you time."
        subtitleText="But remember, you always need to invest a little time for your health."
        backgroundImage={`url(${reinforcer_bg_3})`}
        backgroundImageSmall={`url(${reinforcer_bg_small_3})`}
      />
    );
  }
  if (quizData.currentStep === 20) {
    return (
      <QuizPageWrapper title="How many days per week do you workout?">
        <WorkoutStep />
      </QuizPageWrapper>
    );
  }
  if (quizData.currentStep === 21) {
    return (
      <QuizPageWrapper title="Beyond your workout, how active are you during the day?">
        <ActivityStep />
      </QuizPageWrapper>
    );
  }
  if (quizData.currentStep === 22) {
    return (
      <QuizPageWrapper
        title="On average, how many hours of sleep do you get per night?"
        subTitle="Sleep supports proper metabolism, hormone regulation and appetite control.">
        <SleepStep />
      </QuizPageWrapper>
    );
  }
  if (quizData.currentStep === 23) {
    return (
      <QuizPageWrapper
        title="How many litres of water do you typically drink per day?"
        subTitle="Maintaining proper hydration aids digestion and supports metabolism.">
        <HydrationStep />
      </QuizPageWrapper>
    );
  }
  if (quizData.currentStep === 24) {
    return (
      <QuizPageWrapper
        title="How would you rate your general stress levels?"
        subTitle="Stress levels can influence eating behaviours and impacts metabolism.">
        <StressLevelStep />
      </QuizPageWrapper>
    );
  }
  return <></>;
};
export default LifestyleSection;
