import { Box, Button, Typography } from '@mui/material';
import React, { FC } from 'react';
import colors from 'src/styles/equalution/colors';
import { MeasurementType } from 'src/pages/constants';

interface MeasurementUnitTabsProps {
  selected: string;
  setSelected: React.Dispatch<React.SetStateAction<MeasurementType>>;
}
const MeasurementUnitTabs: FC<MeasurementUnitTabsProps> = props => {
  const { selected, setSelected } = props;

  return (
    <Box
      sx={{
        display: 'flex',
        marginBottom: 2,
      }}>
      <Button
        onClick={() => setSelected(MeasurementType.Metric)}
        sx={{
          padding: '6px 12px',
          borderRadius: '20px',
          textTransform: 'none',
          backgroundColor:
            selected === MeasurementType.Metric
              ? colors.SALAD
              : colors.OFF_WHITE,
          color:
            selected === MeasurementType.Metric
              ? colors.WHITE
              : colors.OFF_BLACK,
          '&:hover': {
            backgroundColor: colors.CUCUMBER,
            color: colors.WHITE,
          },
        }}>
        <Typography>{MeasurementType.Metric}</Typography>
      </Button>
      <Button
        sx={{
          marginLeft: '20px',
          padding: '6px 12px',
          borderRadius: '20px',
          textTransform: 'none',
          backgroundColor:
            selected === MeasurementType.Imperial
              ? colors.SALAD
              : colors.OFF_WHITE,
          color:
            selected === MeasurementType.Imperial
              ? colors.WHITE
              : colors.OFF_BLACK,
          '&:hover': {
            backgroundColor: colors.CUCUMBER,
            color: colors.WHITE,
          },
        }}
        onClick={() => setSelected(MeasurementType.Imperial)}>
        <Typography>{MeasurementType.Imperial}</Typography>
      </Button>
    </Box>
  );
};

export default MeasurementUnitTabs;
