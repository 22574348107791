import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { PaymentMethod } from '@stripe/stripe-js';
import styles from './account-extras.module.scss';
import { IAppState } from '../../../../store';
import ExtrasStep from '../../../signup/steps/extras/extras-step';
import { Products } from '../../../deprecated/payment/types';
import paymentAPI from '../../../deprecated/payment/api';
import Loader from '../../../../components/content/loader';

const AccountExtras = (): JSX.Element => {
  const user = useSelector((state: IAppState) => state.auth?.user);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
  const [products, setProducts] = useState<Products>();
  const [regetProducts, setRegetProducts] = useState<boolean>(false);

  useEffect(() => {
    (async (): Promise<void> => {
      try {
        const response = await paymentAPI.get('stripe/customer-my-account', {
          params: {
            email: user?.email,
          },
        });

        const defaultPaymentMethod: PaymentMethod =
          response?.data?.customer.invoice_settings.default_payment_method;

        if (defaultPaymentMethod) {
          setPaymentMethod(defaultPaymentMethod);
        }

        // Get Products Data
        const { data: productsData } = await paymentAPI.get(
          '/user/getClientConsumableProducts'
        );
        setProducts(productsData);
      } catch (e) {
        console.log('getProducts error', e);
      }

      setIsLoading(false);
      setRegetProducts(false);
    })();
  }, [user, regetProducts]);

  return (
    <div className={styles.accountExtras}>
      <h3>Add-ons</h3>
      {!user || isLoading || regetProducts ? (
        <Loader />
      ) : (
        <ExtrasStep
          paymentMethod={paymentMethod}
          products={products}
          setRegetProducts={setRegetProducts}
        />
      )}
    </div>
  );
};

export default AccountExtras;
