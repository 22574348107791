import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import ContentCard from '../../../../components/content/content-card';
import { Plans, Product, Coupon } from '../../../deprecated/payment/types';
import SubscriptionPreview from './subscription-preview';
import SubscriptionPopup from './subscription-popup';
import { IAppState } from '../../../../store';
import ContentCardLabel from '../../../../components/content/content-card-label';
import StepLabel from '../step-label';
import { stripeProductsApi } from 'src/services';

//step 1 of choose payment section

interface OwnProps {
  productId: string;
  previouslySelectedPlan: string;
  selectedPlan: string;
  setSelectedPlan: (id: string) => void;
  setProductId: (id: string) => void;
  setPriceId: (id: string) => void;
  setPreviouslySelectedPlan: (id: string) => void;
  setTotalPrice: (totalPrice: string) => void;
  setIntervals: (interval: string) => void;
  completed: boolean;
  coupon?: Coupon;
  setCoupon: (coupon: any) => void;
  planRequest: string | null;
  setAvailablePlans: (plans: Plans) => void;
  weeklyMealPlanQty: number;
  setWeeklyMealPlanQty: (qty: number) => void;
}

type Props = OwnProps;

const SubscriptionStep = ({
  productId,
  previouslySelectedPlan,
  setPreviouslySelectedPlan,
  setProductId,
  setPriceId,
  selectedPlan,
  setSelectedPlan,
  completed,
  setTotalPrice,
  coupon,
  setIntervals,
  setCoupon,
  planRequest,
  setAvailablePlans,
  setWeeklyMealPlanQty,
  weeklyMealPlanQty,
}: Props) => {
  // Redux Selectors
  const user = useSelector((state: IAppState) => state.auth?.user);
  const [plans, setPlans] = useState<Plans>();
  // Form State
  // Modal Visibility
  const [popupVisible, setPopupVisible] = useState<boolean>(false);

  const extractPlans = (currentPlans: Plans, productData: Product): Plans => {
    return Object.values(productData.prices).reduce<Plans>(
      (accumulator, price) => {
        if (
          !price.active ||
          !price.recurring ||
          price.recurring.weeklyMealPlanQty === null
        ) {
          return accumulator;
        }

        const planName = `${price.metadata.planName}`;

        // If plan does not exist, create plan
        if (!accumulator[planName]) {
          accumulator[planName] = {
            id: planName,
            interval: price.recurring.interval,
            intervalCount: price.recurring.intervalCount,
            mealPlanVariants: {},
            active: price.active,
            metadata: {},
            currency_options: price.currency_options || [],
          };
        }

        // const unitAmount = checkNZUser()
        //   ? price?.currency_options?.nzd?.unit_amount / 100 ||
        //     price?.currency_options?.aud?.unit_amount / 100
        //   : price?.currency_options?.aud?.unit_amount / 100;

        // Add meal plan variant
        accumulator[planName].mealPlanVariants[
          price.recurring.weeklyMealPlanQty
        ] = {
          id: price.id,
          productId: productData.id,
          unitAmount: price.unitAmount,
          unitAmountCents: price.unitAmountCents,
          // unitAmount: unitAmount?.toString() || price.unitAmount,
          // unitAmountCents: unitAmount * 100 || price.unitAmountCents,
          unitAmountPerDay: price.unitAmountPerDay,
          unitAmountPerWeek: price.unitAmountPerWeek,
          weeklyMealPlanQty: price.recurring.weeklyMealPlanQty,
          weeksPurchased: price.weeksPurchased,
        };

        return accumulator;
      },
      currentPlans
    );
  };

  // Get Product Data on Mount
  useEffect(() => {
    let mounted = true;
    (async (): Promise<void> => {
      try {
        // Get Products Data
        const response = await stripeProductsApi();
        const productsData = response.data.products;

        if (!mounted) return;

        const tempPlans: Plans = {};

        // Find Product
        Object.values(productsData).forEach((productEntry: unknown) => {
          const tempProduct: Product = productEntry as Product;

          if (
            tempProduct?.metadata?.EQPlanID === '1' ||
            tempProduct?.metadata?.EQPlanID === '4' ||
            tempProduct?.metadata?.EQPlanID === '2'
          )
            extractPlans(tempPlans, tempProduct);
        });
        setPlans(tempPlans);
        setAvailablePlans(tempPlans);
      } catch (e) {
        console.log('getProducts error', e);
      }
    })();
    return (): void => {
      mounted = false;
    };
  }, [setAvailablePlans]);

  // Upon Plan Selection and Plan Loading, add to outer scope
  useEffect(() => {
    if (plans && selectedPlan.includes('Daily Targets Only - ')) {
      setProductId(plans[selectedPlan]?.mealPlanVariants[0].productId);
      setPriceId(plans[selectedPlan]?.mealPlanVariants[0].id);
    } else if (plans && plans[selectedPlan] && weeklyMealPlanQty > 0) {
      const totalPrice = Number(
        plans[selectedPlan]?.mealPlanVariants[weeklyMealPlanQty].unitAmount
      ).toFixed(2);
      setProductId(
        plans[selectedPlan]?.mealPlanVariants[weeklyMealPlanQty].productId
      );
      setPriceId(plans[selectedPlan]?.mealPlanVariants[weeklyMealPlanQty].id);
      setIntervals(
        `${selectedPlan}${plans[selectedPlan]?.intervalCount > 1 ? 's' : ''}`
      );
      setTotalPrice(totalPrice);
      setPreviouslySelectedPlan(selectedPlan);

      // If the user selects a different priceId reset the coupon
      const priceId =
        plans[selectedPlan]?.mealPlanVariants[weeklyMealPlanQty].id;
      const metadataPriceIds = (coupon?.metadata?.priceId ?? '')
        .split(',')
        .map(i => i.trim());
      if (
        coupon?.metadata?.priceId &&
        metadataPriceIds?.includes(priceId) === false
      ) {
        setCoupon(undefined);
      }
    }
  }, [
    plans,
    selectedPlan,
    weeklyMealPlanQty,
    setProductId,
    setPriceId,
    setIntervals,
    productId,
    previouslySelectedPlan,
    setTotalPrice,
    setPreviouslySelectedPlan,
    setCoupon,
    coupon,
  ]);

  return (
    <>
      <div>
        <ContentCardLabel>
          <StepLabel completed={completed}>Choose Subscription</StepLabel>
        </ContentCardLabel>

        <ContentCard>
          <>
            <SubscriptionPreview
              selectedPlan={selectedPlan}
              weeklyMealPlanQty={weeklyMealPlanQty}
              plans={plans}
              coupon={coupon}
              planRequest={planRequest ?? ''}
            />
            <Button
              size="sm"
              outline={completed}
              onClick={(): void => {
                setCoupon(undefined);
                setPopupVisible(true);
              }}>
              {completed ? 'Change' : 'Select plan'}
            </Button>
          </>
        </ContentCard>
      </div>

      {popupVisible && (
        <SubscriptionPopup
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
          weeklyMealPlanQty={weeklyMealPlanQty}
          setWeeklyMealPlanQty={setWeeklyMealPlanQty}
          plans={plans}
          setPopupVisible={setPopupVisible}
          user={user}
          planRequest={planRequest}
        />
      )}
    </>
  );
};

export default SubscriptionStep;
