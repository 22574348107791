import React, { ReactNode, useCallback, useState } from 'react';
import { FormFeedback, FormGroup, Input, InputGroup, Label } from 'reactstrap';
import { FieldProps } from 'formik';
import styles from './password.module.scss';
import eye from '../../assets/icons/eye.svg';

interface OwnProps {
  label: string;
  status: string;
}

type Props = OwnProps;

const PasswordInput = ({
  label,
  status,
  field: { name, onChange, onBlur },
  form: { touched, errors },
}: Props & FieldProps): JSX.Element => {
  const [passwordHidden, setPasswordHidden] = useState(true);
  const togglePasswordHidden = useCallback((): void => {
    setPasswordHidden(!passwordHidden);
  }, [passwordHidden]);

  return (
    <FormGroup
      className={!!touched[name] && !!errors[name] ? 'is-invalid' : ''}>
      <div className="label-feedback">
        <Label for={name}>{label}</Label>
        <FormFeedback valid>Success</FormFeedback>
        {errors[name] === 'Required' && (
          <FormFeedback valid={false}>
            {errors[name] as ReactNode}{' '}
          </FormFeedback>
        )}
      </div>
      <InputGroup>
        <Input
          type={passwordHidden ? 'password' : 'text'}
          name={name}
          placeholder="&bull;&bull;&bull;&bull;&bull;&bull;&bull;&bull;"
          onChange={onChange}
          onBlur={onBlur}
        />
        <div
          role="button"
          onClick={togglePasswordHidden}
          className={`eye ${!passwordHidden && 'opaque'}`}>
          <img
            src={eye}
            alt={passwordHidden ? 'Show Password' : 'Hide Password'}
          />
        </div>
      </InputGroup>
      {!!touched[name] && !!errors[name] && errors.password !== 'Required' && (
        <div>
          {' '}
          <br />
          <FormFeedback className={styles.passwordFeedback} valid={false}>
            {errors[name] as ReactNode}
          </FormFeedback>{' '}
          <br />{' '}
        </div>
      )}
    </FormGroup>
  );
};

export default PasswordInput;
