import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';

interface QuizPageWrapperProps {
  title: string;
  subTitle?: string;
  children: React.ReactNode;
}
const QuizPageWrapper: FC<QuizPageWrapperProps> = props => {
  const { title, subTitle, children } = props;

  return (
    <Box sx={{ maxWidth: '420px', width: '100%' }}>
      <Typography
        sx={{
          fontSize: '22px',
          fontWeight: 700,
          marginTop: '20px',
        }}>
        {title}
      </Typography>
      {subTitle && (
        <Typography
          variant="body2"
          sx={{
            fontSize: '16px',
            fontWeight: 300,
            marginBottom: '20px',
            color: '#6B6C7B',
          }}>
          {subTitle}
        </Typography>
      )}
      {children}
    </Box>
  );
};

export default QuizPageWrapper;
