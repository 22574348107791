import { createReducer } from '@reduxjs/toolkit';
import steps from './services';

const signupReducer = createReducer(steps, {
  showSignupStep: state => {
    return state;
  },
  completeSignupStep: state => {
    return state;
  },
});

export default signupReducer;
