import React, { useCallback, useEffect } from 'react';
import './App.scss';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Router } from 'react-router-dom';
import history from './lib/history';
import Pages from './pages/pages';
import { checkAuthentication } from './lib/auth/actions';
import ScrollLocker from './components/content/scroll-locker/scroll-locker';
import { IAppState } from './store';
import ToastWrapper from './components/toast/toast';
import SetupOptimizely from './setupOptimizely';
import { ThemeProvider, createTheme } from '@mui/material';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import colors from './styles/equalution/colors';

const App = (): JSX.Element => {
  const isAuthenticated = useSelector(
    (state: IAppState) => state.auth?.isAuthenticated
  );
  const dispatch = useDispatch();
  const checkAuthenticationConnect = useCallback(
    () => dispatch(checkAuthentication()),
    [dispatch]
  );

  useEffect(() => {
    checkAuthenticationConnect();
  }, [checkAuthenticationConnect]);
  const customTheme = createTheme({
    palette: {
      primary: {
        main: colors.SALAD,
        contrastText: colors.WHITE,
      },
      secondary: {
        main: colors.CREAM,
        contrastText: '#000',
      },
    },
    components: {
      MuiDatePicker: {
        styleOverrides: {
          root: {
            backgroundColor: 'green',
            borderRadius: 2,
            borderWidth: 1,
            borderColor: '#2196f3',
            border: '1px solid',
          },
        },
      },
    },
    typography: {
      fontFamily: ['Lexend', 'sans-serif'].join(','),
    },
  });

  const app =
    isAuthenticated == null ? null : (
      <SetupOptimizely>
        <ScrollLocker>
          <ToastWrapper>
            <ThemeProvider theme={customTheme}>
              <Router history={history}>
                <Route component={Pages} />
              </Router>
            </ThemeProvider>
          </ToastWrapper>
        </ScrollLocker>
      </SetupOptimizely>
    );

  return <div className="App">{app}</div>;
};

export default App;
