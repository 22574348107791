import React, { createRef, useState } from 'react';
import { Field, Formik } from 'formik';
import * as Yup from 'yup';
import { Alert, Button, Form } from 'reactstrap';
import ReCAPTCHA from 'react-google-recaptcha';
import UserPayload from '../../models/payload/UserPayload';
import history from '../../lib/history';
import AuthService from '../../lib/auth/auth-service';
import styles from './reset-password.module.scss';
import Header from '../../components/header/header';
import ContentWrapper from '../../components/content/content-wrapper';
import ContentSection from '../../components/content/content-section';
import EmailInput from '../../components/fields/email';

const schema = Yup.object({
  firstName: Yup.string(),
  lastName: Yup.string(),
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string(),
});

interface FormInputs {
  email: string;
}

const ForgotPassword = (): JSX.Element => {
  const [status, setStatus] = useState('');

  const captchaRef = createRef<ReCAPTCHA>();

  const submitForm = async (formValues: FormInputs): Promise<void> => {
    const token = await captchaRef.current?.executeAsync();

    const { email } = formValues;
    try {
      if (token) {
        const message: string = await AuthService.resetUserPassword(
          new UserPayload('', '', email, '')
        );

        if (message === 'Sent new password') {
          setStatus('success');
        } else {
          setStatus('fail');
        }
      }
    } catch (error) {
      console.error('Error while attempting to save user', error);
    }
  };

  const initialValues: FormInputs = {
    email: '',
  };

  return (
    <>
      <Header module="login" />
      <div className={styles.resetPassword}>
        <ContentWrapper collapseAt="sm">
          <ContentSection collapseAt="sm">
            <h3>Reset password</h3>
            {status !== 'success' ? (
              <Formik
                validationSchema={schema}
                onSubmit={submitForm}
                initialValues={initialValues}>
                {(formikProps): JSX.Element => (
                  <Form noValidate onSubmit={formikProps.handleSubmit}>
                    <Field name="email" label="Email" component={EmailInput} />

                    <Alert color="info">
                      We will send the password reset link to the email above.
                    </Alert>

                    {status === 'fail' && (
                      <Alert color="danger">
                        Something went wrong. Please check your email and try
                        again.
                      </Alert>
                    )}

                    <ReCAPTCHA
                      ref={captchaRef}
                      sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                      size="invisible"
                      onExpired={(): void => setStatus('fail')}
                      onErrored={(): void => setStatus('fail')}
                    />

                    <Button
                      type="submit"
                      size="lg"
                      block
                      disabled={formikProps.isSubmitting || status === 'fail'}
                      className={styles.button}>
                      Submit
                    </Button>
                  </Form>
                )}
              </Formik>
            ) : (
              <>
                <Alert color="success">Email sent successfully.</Alert>
                <Button
                  type="button"
                  size="lg"
                  block
                  onClick={(): void => {
                    history.push('/login-account');
                  }}
                  className={styles.button}>
                  Go to login
                </Button>
              </>
            )}
          </ContentSection>
        </ContentWrapper>
      </div>
    </>
  );
};

export default ForgotPassword;
