import React, { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { PLANS, PLAN_ID, PLAN_TAB } from 'src/pages/constants';
import { IAppState } from 'src/store';
import PopupPlanDescription from './popup-plan-description';
import styles from './subscription-popup-tabs.module.scss';
import { isUserFromAustralia, isUserFromNewZealand } from 'src/lib/utils';

interface Props {
  planTabSelected: number;
  planRequested?: string | null;
  currentPlan: string;
  weeklyMealPlanQty: number;
  onClickTab: (planTab: PLAN_TAB) => void;
  isNewUser?: boolean;
  showText?: boolean;
}

const isUserOnStandardPlan = (currentPlan: string, planId: number) => {
  return (
    currentPlan.includes(PLANS.STANDARD_PLAN) || planId === PLAN_ID.STANDARD
  );
};

export const getShowStandardPlan = (
  currentPlan: string,
  planId: number,
  userCountry: string
) => {
  const isNewZealandUser =
    isUserFromNewZealand() || userCountry === 'New Zealand';
  const isAustralianUser = isUserFromAustralia() || userCountry === 'Australia';

  // show Standard plan if user is from NZ or AU
  if (isNewZealandUser || isAustralianUser) {
    return true;
  }

  // don't show Standard plan if user not from Australia
  if (!isAustralianUser) {
    return false;
  }

  return isUserOnStandardPlan(currentPlan, planId);
};

const SubscriptionPopupTabs = ({
  planTabSelected,
  weeklyMealPlanQty,
  onClickTab,
  isNewUser,
  showText,
}: Props): JSX.Element => {
  const user = useSelector((state: IAppState) => state.auth?.user);

  const isNewZealandUser =
    isUserFromNewZealand() || (user?.country ?? '') === 'New Zealand';
  const isAustralianUser =
    isUserFromAustralia() || (user?.country ?? '') === 'Australia';

  // if user is from any country other than AU and NZ, show only Premium Plan
  const showOnlyPremiumPlan = !isNewZealandUser && !isAustralianUser;

  // if user is from NZ, show only Standard Plan
  const showOnlyStandardPlan = isNewZealandUser && !isAustralianUser;

  useEffect(() => {
    // switch to Standard Plan if user is from NZ
    if (showOnlyStandardPlan && planTabSelected === PLAN_TAB.PREMIUM) {
      onClickTab(PLAN_TAB.STANDARD);
    }
    // switch to Premium Plan if user is International
    if (showOnlyPremiumPlan && planTabSelected === PLAN_TAB.STANDARD) {
      onClickTab(PLAN_TAB.PREMIUM);
    }
  }, [onClickTab, planTabSelected, showOnlyPremiumPlan, showOnlyStandardPlan]);

  const show8WeekText = useMemo(() => {
    if (isNewUser) {
      return true;
    }
    if (!!showText) {
      return true;
    }
  }, [isNewUser, showText]);

  const PlanSelectionTabs = useMemo(
    () => (
      <div className={styles.titleRow}>
        <div
          className={
            planTabSelected === PLAN_TAB.STANDARD
              ? styles.borderBottom
              : styles.borderBottomSelected
          }>
          <span
            className={
              planTabSelected === PLAN_TAB.STANDARD
                ? styles.planTitle
                : styles.planTitleSelected
            }
            onClick={() => onClickTab(PLAN_TAB.PREMIUM)}>
            Premium
          </span>
        </div>
        <div
          className={
            planTabSelected === PLAN_TAB.STANDARD
              ? styles.borderBottomSelected
              : styles.borderBottom
          }>
          <span
            className={
              planTabSelected === PLAN_TAB.STANDARD
                ? styles.planTitleSelected
                : styles.planTitle
            }
            onClick={() => onClickTab(PLAN_TAB.STANDARD)}>
            Standard
          </span>
        </div>
      </div>
    ),
    [onClickTab, planTabSelected]
  );

  const renderPopup = useMemo(() => {
    if (showOnlyStandardPlan) {
      return (
        <div>
          <PopupPlanDescription
            planTabSelected={PLAN_TAB.STANDARD}
            weeklyMealPlanQty={0}
          />
        </div>
      );
    }

    if (showOnlyPremiumPlan) {
      return (
        <div>
          {show8WeekText && (
            <div className={styles.eightWeekMinimum}>
              8 week minimum for Premium Subscriptions
            </div>
          )}
          <PopupPlanDescription
            planTabSelected={PLAN_TAB.PREMIUM}
            weeklyMealPlanQty={weeklyMealPlanQty}
            show8WeekText={show8WeekText}
          />
        </div>
      );
    }

    if (
      planTabSelected === PLAN_TAB.STANDARD ||
      planTabSelected === PLAN_TAB.PREMIUM
    ) {
      return (
        <div>
          {PlanSelectionTabs}
          <PopupPlanDescription
            planTabSelected={planTabSelected}
            weeklyMealPlanQty={weeklyMealPlanQty}
            show8WeekText={show8WeekText}
          />
        </div>
      );
    }
  }, [
    PlanSelectionTabs,
    planTabSelected,
    show8WeekText,
    showOnlyPremiumPlan,
    showOnlyStandardPlan,
    weeklyMealPlanQty,
  ]);

  return <div>{renderPopup}</div>;
};

export default SubscriptionPopupTabs;
