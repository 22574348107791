import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface PaymentStateType {
  isPaymentProcessing: boolean;
  isPaymentIntentLoading: boolean;
}

const initialState: PaymentStateType = {
  isPaymentProcessing: false,
  isPaymentIntentLoading: false,
};
export const paymentSlice = createSlice({
  name: 'payment',
  initialState,
  reducers: {
    setPaymentProcessing: (state, action: PayloadAction<boolean>) => {
      state.isPaymentProcessing = action.payload;
    },
    setPaymentIntentLoading: (state, action: PayloadAction<boolean>) => {
      state.isPaymentIntentLoading = action.payload;
    },
  },
});

export const { setPaymentProcessing, setPaymentIntentLoading } =
  paymentSlice.actions;

export default paymentSlice.reducer;
