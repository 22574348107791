import React, { FC } from 'react';
import { Box, ButtonBase, Stack } from '@mui/material';
import styles from './app-qr-code.module.scss';

import appstoreLogo from 'src/assets/images/appstore-logo.png';
import playstoreLogo from 'src/assets/images/playstore-logo.png';
import appstoreQrCode from 'src/assets/images/appstore-qr.svg';
import playstoreQrCode from 'src/assets/images/playstore-qr.svg';
import { EVENTS, track } from 'src/util/track';

export const handleStoreButtonClick = (store: 'appstore' | 'playstore') => {
  const appstoreUrl = 'https://apps.apple.com/au/app/equalution/id1456085024';
  const playstoreUrl =
    'https://play.google.com/store/apps/details?id=com.equalution.equalution';

  const url = store === 'appstore' ? appstoreUrl : playstoreUrl;

  track(EVENTS.appstoreButtonClick, {
    store,
  });

  window.open(url, '_blank');
};

export const AppQRCode: FC = () => {
  return (
    <Box className={styles.container}>
      <Stack className={styles.card}>
        <ButtonBase
          disableRipple
          onClick={() => handleStoreButtonClick('appstore')}>
          <img
            src={appstoreLogo}
            alt="appstoreLogo"
            className={styles.logoImage}
          />
        </ButtonBase>
        <img
          src={appstoreQrCode}
          alt="appstoreQrCode"
          className={styles.qrImage}
        />
      </Stack>
      <Stack className={styles.card}>
        <ButtonBase
          disableRipple
          onClick={() => handleStoreButtonClick('playstore')}>
          <img
            src={playstoreLogo}
            alt="playstoreLogo"
            className={styles.logoImage}
          />
        </ButtonBase>
        <img
          src={playstoreQrCode}
          alt="playstoreQrCode"
          className={styles.qrImage}
        />
      </Stack>
    </Box>
  );
};
