import { ThunkDispatch as Dispatch } from 'redux-thunk';
import {
  AUTHENTICATE,
  IAuthCredentials,
  IAuthenticate,
  IUnauthenticate,
  UNAUTHENTICATE,
} from './types';
import AuthService from './auth-service';
import User from '../../models/User';
// import UserPayload from '../../models/payload/UserPayload';

export function authenticate(user: User): IAuthenticate {
  return {
    type: AUTHENTICATE,
    user,
  };
}

export function unauthenticate(): IUnauthenticate {
  return {
    type: UNAUTHENTICATE,
    user: undefined,
  };
}

export type AuthenticationAction = IAuthenticate | IUnauthenticate;

// TODO: Replace window.localStorage with DB/API async calls
export function logIn(credentials: IAuthCredentials) {
  return async (
    dispatch: Dispatch<AuthenticationAction, {}, any>
  ): Promise<IAuthenticate | IUnauthenticate> => {
    const user: User | undefined = await AuthService.logIn(credentials);
    return user ? dispatch(authenticate(user)) : dispatch(unauthenticate());
  };
}

export function logOut() {
  return async (
    dispatch: Dispatch<AuthenticationAction, {}, any>
  ): Promise<IUnauthenticate> => {
    try {
      AuthService.logOut();
    } catch (error) {
      console.error('Error attempting to logout', error);
    }

    return dispatch(unauthenticate());
  };
}

export function checkAuthentication() {
  return async (
    dispatch: Dispatch<AuthenticationAction, {}, any>
  ): Promise<IAuthenticate | IUnauthenticate> => {
    try {
      const user: User | undefined = await AuthService.getLoggedInUser();
      if (user) {
        return dispatch(authenticate(user));
      }
    } catch (error) {
      console.error(error);
    }
    window.localStorage.removeItem('user');
    return dispatch(unauthenticate());
  };
}
