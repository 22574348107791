import React, { FC, ReactNode } from 'react';
import { Stack, Box } from '@mui/material';

// Styles and Assets
import warning from 'src/assets/icons/warning.svg';
import colors from 'src/styles/equalution/colors';

export interface AlertProps {
  message: string | ReactNode | undefined;
  color?: string;
  hideIcon?: boolean;
  iconPath?: string;
}

const Alert: FC<AlertProps> = ({ message, color, hideIcon, iconPath }) => {
  const textColor = color ?? colors.ERROR_RED;
  const imagePath = iconPath ?? warning;

  if (!message) {
    return null;
  }

  return (
    <Stack
      flexDirection={'row'}
      gap={'8px'}
      alignItems={'flex-start'}
      mt={'8px'}>
      {!hideIcon && (
        <img src={imagePath} alt="alert-icon" height={'18px'} width={'18px'} />
      )}
      <Box
        fontWeight={400}
        fontSize={'12px'}
        lineHeight={'16px'}
        color={textColor}>
        {message}
      </Box>
    </Stack>
  );
};

export default Alert;
