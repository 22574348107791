import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { useSelector } from 'react-redux';
import {
  Plans,
  Customer,
  Coupon,
  Plan,
} from '../../../../deprecated/payment/types';
import Popup from '../../../../../components/content/popup/popup';
import ContentSection from '../../../../../components/content/content-section';
import ContentWrapper from '../../../../../components/content/content-wrapper';
import styles from './update-subscription-popup.module.scss';
import { IAppState } from '../../../../../store';
import AllSubscriptions from 'src/models/AllSubscriptions';
import { UpdateSubscription } from '../update-subscription-popup/UpdateSubscription';
import { UpdateSubscriptionSummary } from '../update-subscription-popup/UpdateSubscriptionSummary';
import paymentAPI from 'src/pages/deprecated/payment/api';
import history from '../../../../../lib/history';
import { EVENTS, track } from 'src/util/track';
import { checkNZUser } from 'src/lib/utils';

interface OwnProps {
  selectedPlan: string;
  weeklyMealPlanQty: number;
  plans: Plans | undefined;
  customer: Customer | undefined;
  repurchaseSubscription: boolean;
  planRequested: string | undefined;
  showText: boolean;
  setSelectedPlan: (id: string) => void;
  setWeeklyMealPlanQty: (val: number) => void;
  setPopupVisible: (val: boolean) => void;
  setAllsubscriptions: (state: AllSubscriptions) => void;
}

const defaultPlan: Plan = {
  id: '',
  interval: '',
  intervalCount: 0,
  mealPlanVariants: [
    {
      id: '',
      productId: '',
      weeklyMealPlanQty: 0,
      unitAmountCents: 0,
      unitAmount: '0',
      unitAmountPerWeek: 0,
      unitAmountPerDay: 0,
      weeksPurchased: {
        interval: 'day',
        intervalCount: 0,
      },
    },
  ],
  active: false,
  metadata: {},
  currency_options: [],
};

export const UpdateSubscriptionPopup = ({
  plans,
  customer,
  planRequested,
  repurchaseSubscription,
  selectedPlan,
  setSelectedPlan,
  weeklyMealPlanQty,
  setWeeklyMealPlanQty,
  setAllsubscriptions,
  setPopupVisible,
  showText,
}: OwnProps): JSX.Element => {
  const user = useSelector((state: IAppState) => state.auth?.user);

  const [coupon, setCoupon] = useState<Coupon | undefined>();

  const [newSelectedPlanName, setNewSelectedPlanName] =
    useState<string>(selectedPlan);
  const [newWeeklyMealPlanQty, setNewWeeklyMealPlanQty] =
    useState<number>(weeklyMealPlanQty);

  const [showSummary, setShowSummary] = useState<boolean>(false);

  const purchaseSubscription = async () => {
    if (customer && plans) {
      if (!repurchaseSubscription) {
        const allSubscriptions = await paymentAPI.post(
          'stripe/subscription/update',
          {
            subscriptionID:
              customer.subscriptions?.data.length > 0
                ? customer?.subscriptions?.data[0].id
                : '',
            priceID:
              plans[newSelectedPlanName].mealPlanVariants[newWeeklyMealPlanQty]
                .id,
            customerID: customer.id,
            promoID: coupon?.id || null,
            productId:
              plans[newSelectedPlanName].mealPlanVariants[newWeeklyMealPlanQty]
                .productId,
          }
        );

        setAllsubscriptions(allSubscriptions.data);
        setSelectedPlan(newSelectedPlanName);
        setWeeklyMealPlanQty(newWeeklyMealPlanQty);
      } else {
        const defaultPaymentMethod =
          customer.invoice_settings.default_payment_method;
        await paymentAPI.post('stripe/invoice/purchase', {
          paymentMethodID: defaultPaymentMethod.id,
          customerID: customer.id,
          priceIDs: [
            plans[newSelectedPlanName].mealPlanVariants[weeklyMealPlanQty].id,
          ],
          promoID: coupon?.id || null,
          userID: user?.id,
          isChallenge: false,
          productId:
            plans[newSelectedPlanName].mealPlanVariants[newWeeklyMealPlanQty]
              .productId,
        });

        if (user?.status !== 'PROSPECT') {
          history.push(
            '/confirmation?ref=TransactionComplete',
            track(EVENTS.updateSubscription, {
              currency: checkNZUser() ? 'NZD' : 'AUD',
              purchaseComplete: 'ClientRenewal',
              priceIDs: [
                plans[newSelectedPlanName].mealPlanVariants[weeklyMealPlanQty]
                  .id,
              ],
            })
          );
        } else {
          history.push(
            '/confirmation?ref=TransactionComplete',
            track(EVENTS.updateSubscription, {
              currency: checkNZUser() ? 'NZD' : 'AUD',
              purchaseComplete: 'ClientNewPurchase',
              priceIDs: [
                plans[newSelectedPlanName].mealPlanVariants[weeklyMealPlanQty]
                  .id,
              ],
            })
          );
        }
      }
    }
  };

  return (
    <Popup>
      <ContentWrapper className={styles.background} collapseAt="sm">
        <ContentSection
          className={styles.updateSubscriptionPopup}
          collapseAt="sm">
          <Button
            className={styles.close}
            onClick={(): void => setPopupVisible(false)}
          />
          {!showSummary ? (
            <UpdateSubscription
              currentPlanId={user?.plan?.plan_id}
              plans={plans}
              selectedPlan={selectedPlan}
              weeklyMealPlanQty={weeklyMealPlanQty}
              planRequested={planRequested}
              showText={showText}
              currentCheckinCount={(user && user.current_checkin) || 0}
              onClickContinue={(
                newSelectedPlan,
                newWeeklyMealPlanQty,
                coupon
              ) => {
                setCoupon(coupon);
                setNewSelectedPlanName(newSelectedPlan);
                setNewWeeklyMealPlanQty(newWeeklyMealPlanQty);
                setShowSummary(true);
              }}
            />
          ) : (
            <UpdateSubscriptionSummary
              plan={(plans && plans[newSelectedPlanName]) || defaultPlan}
              weeklyMealPlanQty={newWeeklyMealPlanQty}
              currentCheckinCount={user?.current_checkin}
              setPopupVisible={setPopupVisible}
              purchaseSubscription={purchaseSubscription}
              coupon={coupon}
            />
          )}
        </ContentSection>
      </ContentWrapper>
    </Popup>
  );
};
