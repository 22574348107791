import React, { useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { checkQuizStatus } from 'src/services/quiz-service';
import { useHistory } from 'react-router-dom';
import { ToastContext } from 'src/components/toast/toast';
import { QuizType, updateQuizData, updateQuizType } from 'src/store/quiz';

import Quiz from './Quiz';
import { handleEncodingURL, hideIntercomElement } from 'src/lib/utils';
import { IAppState } from 'src/store';
import Loader from 'src/components/content/loader';
import { Box } from '@mui/material';
import { EVENTS, track } from 'src/util/track';

const QuizPage = () => {
  const history = useHistory();
  const [loading, setIsLoading] = React.useState(false);
  const { addToast } = useContext(ToastContext);
  const dispatch = useDispatch();
  const { quizData } = useSelector((state: IAppState) => state.quiz);

  const searchParams = new URLSearchParams(window.location.search);

  const emailParam = searchParams.get('email');

  // for checking quiz status
  if (window.location.href.indexOf('+') !== -1) {
    handleEncodingURL(); //if plus sign is present in the url meaning missed encoding it
  }

  const checkEmailQuizStatus = useCallback(
    async (email: string) => {
      dispatch(updateQuizData({ email })); // update email in redux store
      const response = await checkQuizStatus({ email });
      if (response.status === 200) {
        if (response?.data?.data) {
          dispatch(updateQuizData(response.data.data)); // update isReturning in redux store
        }
        if (
          response?.data?.isRegistered &&
          !response?.data?.isReturning &&
          !response?.data?.isQuizExpired
        ) {
          addToast('You are already registered. Please login', 'info', 5000);
          history.replace('/login');
        }
        if (response?.data?.isReturning) {
          dispatch(updateQuizData({ isReturning: true })); // update isReturning in redux store
        }
        if (
          response?.data?.data &&
          !response?.data?.isRegistered &&
          !response?.data?.isQuizExpired
        ) {
          dispatch(updateQuizData({ currentStep: 29 })); // update in redux store and navigate to plan review screen
        }
      }
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    },
    [addToast, dispatch, history]
  );

  useEffect(() => {
    if (emailParam) {
      setIsLoading(true);
      checkEmailQuizStatus(emailParam);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const quizType: QuizType = 'equalution';

    dispatch(updateQuizType(quizType));
    track(EVENTS.quizStart, { quiz: quizType });
  }, [dispatch]);

  useEffect(() => {
    setTimeout(() => {
      hideIntercomElement();
    }, 1000);
  }, [quizData.currentStep]);

  if (loading) {
    return (
      <Box sx={{ height: '100vh', width: '100vw' }}>
        <Loader />
      </Box>
    );
  }

  return <Quiz />;
};

export default QuizPage;
