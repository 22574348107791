import React, { FunctionComponent } from 'react';
import { Button } from 'reactstrap';
import history from '../../../lib/history';
import styles from './text-button.module.scss';

interface OwnProps {
  text: string;
  buttonText: string;
  path: string;
  textColor?: string;
}

type Props = OwnProps;

const TextButton: FunctionComponent<Props> = ({
  text,
  buttonText,
  path,
  textColor,
}: OwnProps) => {
  return (
    <div className={styles.textButton}>
      <p style={{ color: textColor }}>{text}</p>
      <Button
        key="login-btn"
        size="md"
        outline
        onClick={(): void => history.push(path)}>
        {buttonText}
      </Button>
    </div>
  );
};

export default TextButton;
