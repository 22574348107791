import User from '../../models/User';

export const AUTHENTICATE = 'AUTHENTICATE';
export const UNAUTHENTICATE = 'UNAUTHENTICATE';

export interface IAuthenticate {
  type: typeof AUTHENTICATE;
  user: User;
}

export interface IUnauthenticate {
  type: typeof UNAUTHENTICATE;
  user?: User;
}

export interface IAuthCredentials {
  email: string;
  password: string;
}

export default interface IAuthState {
  isAuthenticated?: boolean;
  user?: User;
}
