import { useStripe } from '@stripe/react-stripe-js';
import React, { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import User from 'src/models/User';
import { IAppState } from 'src/store';
import { Coupon } from 'src/pages/deprecated/payment/types';
import { validateCoupons } from '../signup/signup';
import { PaymentMethod } from '@stripe/stripe-js';
import { TagData } from 'src/store/signup-workflow/types';
import paymentAPI from '../deprecated/payment/api';
import history from 'src/lib/history';
import { EVENTS, track } from 'src/util/track';
import Loader from 'src/components/content/loader';
import { ToastContext } from 'src/components/toast/toast';
import { checkAuthentication } from 'src/lib/auth/actions';
import { useAppSelector } from 'src/store/hooks';
import { getEcommerceDataProperties } from 'src/lib/utils';
import { checkQuizStatus } from 'src/services/quiz-service';

export interface WalletSubscriptionType {
  user: User;
  paymentMethod?: PaymentMethod;
  priceId: string;
  coupon: Coupon | undefined;
  isChallengeAccepted: boolean;
  totalPrice: string;
  futureCommencement: boolean;
  tagId: number;
  productId: string;
  challengeObject: TagData | null;
  customerID: string;
  paymentIntentId: string;
  paymentMethodID?: string;
}

const AfterPay = () => {
  const user = useSelector((state: IAppState) => state.auth?.user);
  const { quizData } = useSelector((state: IAppState) => state.quiz);
  const { isFitherProjectUser, selectedPlanId, allPlansSorted } =
    useAppSelector(state => state.signUp);

  const stripe = useStripe();
  const dispatch = useDispatch();
  const { addToast } = useContext(ToastContext);

  const payObj = JSON.parse(localStorage.getItem('payObj') || '{}');

  useEffect(() => {
    const completeWalletPayment = async (
      props: WalletSubscriptionType,
      user: User
    ) => {
      const {
        priceId,
        coupon,
        isChallengeAccepted,
        totalPrice,
        futureCommencement,
        tagId,
        productId,
        challengeObject,
        customerID,
        paymentIntentId,
        paymentMethodID,
      } = props;
      const errorMsg = validateCoupons(priceId, coupon?.metadata);
      if (errorMsg) {
        return { errorMsg };
      }

      let quizFinalStatus = false;

      //checking quiz status before final payment
      const quizStatusRes = await checkQuizStatus({ email: user.email });
      if (quizStatusRes.status === 200) {
        if (quizStatusRes?.data?.data) {
          quizFinalStatus = true;
        }
      }

      let purchaseInvoicePayload: any = {
        paymentMethodID,
        customerID: customerID,
        productId: productId,
        priceIDs: [priceId],
        promoID: coupon?.id || null,
        userID: user.id,
        isChallenge: !!isChallengeAccepted,
        paymentIntentId: paymentIntentId,
        isCardPayment: false, //make it true only if is card payment
        isAfterPay: true,
        futureCommencement,
        isQuiz: quizFinalStatus,
      };
      if (!!isChallengeAccepted && challengeObject?.id) {
        purchaseInvoicePayload.challenge_ids = [challengeObject.id];
      }

      // Process Payment
      try {
        await paymentAPI.post(
          'stripe/invoice/purchase/wallets',
          purchaseInvoicePayload
        );
        dispatch(checkAuthentication());

        const trackProperties =
          getEcommerceDataProperties(selectedPlanId, allPlansSorted, {
            payment_type: 'AFTERPAY',
            coupon_id: coupon?.id,
            coupon_name: coupon?.name,
          }) ?? {};

        track(EVENTS.addPaymentInfo, { ecommerce: null });
        track(EVENTS.addPaymentInfo, trackProperties);

        // Update Google Analytics
        if (user.status !== 'PROSPECT') {
          history.push('/success?ref=TransactionComplete', {
            tagId,
            futureCommencement,
            priceId,
            coupon,
            totalPrice,
            dataLayer: track(EVENTS.programPurchase, {
              currency: 'AUD',
              value: totalPrice,
              purchaseComplete: 'ClientRenewal',
              priceIDs: [priceId],
              paymentMode: 'AfterPay',
              isFitherProjectUser,
            }),
          });
        } else {
          history.push('/success?ref=TransactionComplete', {
            tagId,
            futureCommencement,
            priceId,
            coupon,
            totalPrice,
            dataLayer: track(EVENTS.programPurchase, {
              currency: 'AUD',
              value: totalPrice,
              purchaseComplete: 'ClientNewPurchase',
              priceIDs: [priceId],
              paymentMode: 'AfterPay',
              isFitherProjectUser,
            }),
          });
        }
      } catch (error) {
        console.log('error', JSON.stringify(error));
        addToast('Something went wrong.', 'danger', 5000);
        history.replace('/signup');
        return;
      }
    };

    const checkPaymentStatus = async (stripe: any, user: User) => {
      const url = new URL(window.location.href);
      const clientSecret = url.searchParams.get('payment_intent_client_secret');
      if (clientSecret) {
        const { paymentIntent } =
          await stripe.retrievePaymentIntent(clientSecret);
        let isSuccessful = false;
        switch (paymentIntent.status) {
          case 'succeeded':
            isSuccessful = true;
            localStorage.setItem('payObj', '{}'); //reset local storage
            break;

          case 'processing':
            addToast(
              "Payment processing. We'll update you when payment is received.",
              'info',
              5000
            );
            break;

          case 'requires_payment_method':
            // Redirect your user back to your payment page to attempt collecting
            // payment again
            addToast(
              'Payment failed. Please try another payment method.',
              'danger',
              5000
            );
            history.replace('/signup');
            break;

          default:
            addToast('Something went wrong. Please try again.', 'danger', 5000);
            localStorage.setItem('payObj', '{}'); //reset local storage
            history.replace('/signup');
            break;
        }
        if (payObj && isSuccessful) {
          payObj.paymentMethodID = paymentIntent.payment_method;
          completeWalletPayment(payObj, user);
        } else {
          addToast(
            'No valid Payment data found. Please try again.',
            'danger',
            5000
          );
          history.replace('/signup');
        }
      } else {
        addToast('Details are invalid. Please try again.', 'danger', 5000);
        localStorage.setItem('payObj', '{}'); //reset local storage
        history.replace('/signup');
      }
    };

    if (stripe && user) {
      checkPaymentStatus(stripe, user);
    }
  }, [
    addToast,
    allPlansSorted,
    dispatch,
    isFitherProjectUser,
    payObj,
    quizData.isQuiz,
    selectedPlanId,
    stripe,
    user,
  ]);

  return (
    <div
      style={{
        width: '100%',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      }}>
      <Loader className="" />
    </div>
  );
};

export default AfterPay;
