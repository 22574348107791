import React from 'react';
import { PLAN_TAB } from 'src/pages/constants';
import styles from './popup-plan-description.module.scss';

interface Props {
  planTabSelected: number;
  weeklyMealPlanQty: number;
  showStandardPlanOnly?: boolean;
  show8WeekText?: boolean;
}

const PopupPlanDescription = ({
  planTabSelected,
  weeklyMealPlanQty,
  showStandardPlanOnly,
  show8WeekText = false,
}: Props): JSX.Element => {
  if (showStandardPlanOnly) {
    return <></>;
  }

  return (
    <div className={styles.background}>
      {planTabSelected === PLAN_TAB.STANDARD ? (
        <div>
          <div className={styles.titleBackground}>
            <span className={styles.title}>Self-paced journey</span>
          </div>
          <div className={styles.descriptionContent}>
            <span className={styles.planDescriptionTitle}>
              No minimum commitment required
            </span>
            <span className={styles.inclusionsText}>Program inclusions</span>
            <ul className={styles.programDescriptionModal}>
              <li>Calculated daily targets for your goals</li>
              <li>1x personalised meal plan with recipes updated weekly</li>
              <li>Unlimited meal swaps</li>
              <li>Weekly check-in</li>
              <li>Food and water tracker</li>
              <li>Exclusive access to the community</li>
              <li>Educational guides</li>
            </ul>
          </div>
        </div>
      ) : weeklyMealPlanQty !== 0 ? (
        <div>
          <div className={styles.titleBackground}>
            <span className={styles.title}>Coach-supported journey</span>
          </div>
          <div className={styles.descriptionContent}>
            <span className={styles.planDescriptionTitle}>
              {show8WeekText ? '8 week minimum required' : ''}
            </span>
            <span className={styles.inclusionsText}>Program inclusions</span>
            <ul className={styles.programDescriptionModal}>
              <li>1:1 personal coaching with an expert</li>
              <li>In-app chat support</li>
              <li>Calculated daily targets for your goals</li>
              <li>Personalised meal plan with recipes updated weekly</li>
              <li>Unlimited meal swaps</li>
              <li>Weekly check-in</li>
              <li>Food and water tracker</li>
              <li>Exclusive access to the community</li>
            </ul>
          </div>
        </div>
      ) : (
        weeklyMealPlanQty === 0 && (
          <div>
            <div className={styles.titleBackground}>
              <span className={styles.title}>Daily Targets Only Plan</span>
            </div>
            <div className={styles.descriptionContent}>
              <span className={styles.planDescriptionTitle}>
                Perfect for tracking your own food, with coach support
              </span>
              <span className={styles.inclusionsText}>Program inclusions</span>
              <ul className={styles.programDescriptionModal}>
                <li>Custom calorie and macro targets</li>
                <li>Unlimited meal swaps (Aus clients)</li>
                <li>Food and water tracking</li>
                <li>Weekly check-in</li>
                <li>1:1 personal coaching</li>
                <li>In-app chat support</li>
                <div className={styles.notIncluded}>
                  1 full day meal plan (sent weekly)
                </div>
                <div className={styles.notIncluded}>
                  Unlimited meal swaps (Aus clients)
                </div>
              </ul>
            </div>
          </div>
        )
      )}
    </div>
  );
};

export default PopupPlanDescription;
