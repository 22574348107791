import React, { FunctionComponent } from 'react';
import AllSubscriptions from 'src/models/AllSubscriptions';
import styles from './update-subscription-preview.module.scss';
import {
  getCurrencySymbol,
  checkNZUser,
  isUserFromNewZealand,
} from 'src/lib/utils';
import { useSelector } from 'react-redux';
import { IAppState } from 'src/store';

interface OwnProps {
  allSubscriptions: AllSubscriptions | undefined;
}

const UpcomingSubscription: FunctionComponent<OwnProps> = ({
  allSubscriptions,
}) => {
  const user = useSelector((state: IAppState) => state.auth?.user);

  const isNewZealandUser =
    isUserFromNewZealand() || (user?.country ?? '') === 'New Zealand';

  return (
    <div className={styles.changeSubscriptionPreview}>
      <div className={styles.price}>
        <div className={styles.big}>
          {allSubscriptions?.upcomingSubscription.planName}{' '}
        </div>
        <div className={styles.nextDate}>
          {`Billed ${
            getCurrencySymbol(isNewZealandUser) +
            (checkNZUser()
              ? (
                  (allSubscriptions?.upcomingSubscription?.currencyOptions?.nzd
                    ?.unit_amount || 1) / 100
                ).toString()
              : allSubscriptions?.upcomingSubscription?.price.toFixed(2) || '')
          } every ${allSubscriptions?.upcomingSubscription?.intervalCount} `}
          {allSubscriptions?.upcomingSubscription?.intervalCount || 0 > 1
            ? `${allSubscriptions?.upcomingSubscription?.interval}s`
            : ``}
        </div>
      </div>

      <div className={styles.nextDate}>
        In effect from {allSubscriptions?.upcomingSubscription.startDate}
      </div>
    </div>
  );
};

export default UpcomingSubscription;
