import React, { FunctionComponent, PropsWithChildren, useState } from 'react';
import styles from './scroll-locker.module.scss';

type Props = any;

export const ScrollContext = React.createContext({
  setParentAllowScroll: (val: boolean) => {},
});

// Scroll Locker creates a new scroll context that can be locked by a child element.
// This enables pop ups and layered components to disable scrolling in parent elements.
// This could break if two consumers lock this context, and one then unlocks it. Shouldn't happen, so she'll be right.
const ScrollLocker: FunctionComponent<Props> = ({
  children,
}: PropsWithChildren<Props>) => {
  const [allowScroll, setAllowScroll] = useState<boolean>(true);

  return (
    <ScrollContext.Provider value={{ setParentAllowScroll: setAllowScroll }}>
      <div className={allowScroll ? '' : styles.disableScroll}>{children}</div>
    </ScrollContext.Provider>
  );
};

export default ScrollLocker;
