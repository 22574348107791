// Third-Party Imports
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

// components
import Alert from 'src/components/alert/Alert';
import { Layout } from 'src/components/layout-wrapper/Layout';
import { TextInput } from 'src/components/text-input/TextInput';

// Types and Constants
import { AuthenticationAction, logIn, logOut } from 'src/lib/auth/actions';
import { IAuthCredentials, AUTHENTICATE } from 'src/lib/auth/types';
import { IAppState } from 'src/store';
import { USER_STATUS } from '../constants';

// Styles & utilities
import styles from './login-account.module.scss';
import { useViewport } from 'src/lib/utils';
import history from 'src/lib/history';
import { handleProspectUserRedirect } from 'src/store/signup';
import { track, EVENTS } from 'src/util/track';
import { checkQuizStatus } from 'src/services/quiz-service';
import User from 'src/models/User';

interface LoginFormValues {
  email: string;
  password: string;
}

const validationSchema = Yup.object({
  email: Yup.string().email('Invalid email').required('Email is required'),
  password: Yup.string().required('Password is required'),
});

const initialValues: LoginFormValues = {
  email: '',
  password: '',
};

const LoginAccount = () => {
  // hooks and states
  const { isMobile } = useViewport();
  const dispatch: ThunkDispatch<AuthenticationAction, any, AnyAction> =
    useDispatch();

  const user = useSelector((state: IAppState) => state.auth?.user);
  const isAuthenticated = useSelector(
    (state: IAppState) => state.auth?.isAuthenticated
  );

  const [errorMessage, setErrorMessage] = useState('');

  // formik
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async values => {
      await submitForm(values);
    },
  });

  // functions
  const buttonDisabled = useMemo((): boolean => {
    if (
      Object.keys(formik.errors).length > 0 ||
      Object.values(formik.values).some(value => value === '')
    ) {
      return true;
    }
    return false;
  }, [formik]);

  const getErrors = (key: keyof LoginFormValues): string | undefined => {
    if (formik.touched[key] && formik.errors[key]) {
      return formik.errors[key];
    }
  };

  const doLogin = useCallback(
    (credentials: IAuthCredentials) => dispatch(logIn(credentials)),
    [dispatch]
  );

  const doLogout = useCallback(() => dispatch(logOut()), [dispatch]);

  const checkQuizStatusFn = useCallback(async () => {
    if (user) {
      const quizStatusRes = await checkQuizStatus({ email: user.email });
      if (quizStatusRes.status === 200) {
        if (quizStatusRes?.data?.isQuizExpired) {
          history.replace('/noActivePlan');
        }
      }
    } else {
      dispatch(handleProspectUserRedirect());
      history.push('/signup?planType=standard&duration=3');
    }
  }, [dispatch, user]);

  const handleRedirectionCondition = useCallback((user: User | undefined) => {
    if (
      user?.status === USER_STATUS.ACTIVE ||
      user?.status === USER_STATUS.INITIAL ||
      user?.status === USER_STATUS.FINAL
    ) {
      history.push('/account');
    } else if (user?.status === USER_STATUS.PROSPECT) {
      history.push('/signup');
    } else if (user?.status === USER_STATUS.INACTIVE) {
      checkQuizStatusFn();
    } else if (user?.isQuiz && user?.status !== USER_STATUS.ACTIVE) {
      history.replace('/account'); // to redirect user to quiz page
    } else {
      dispatch(handleProspectUserRedirect());
      history.push('/signup?planType=premium&duration=3');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isAuthenticated && user) {
      handleRedirectionCondition(user);
    }
  }, [
    checkQuizStatusFn,
    dispatch,
    handleRedirectionCondition,
    isAuthenticated,
    user,
  ]);

  // handle submit
  const submitForm = useCallback(
    async (values: LoginFormValues): Promise<void> => {
      const { email } = values;
      const auth = await doLogin(values as IAuthCredentials);
      if (auth.type !== AUTHENTICATE) {
        await doLogout();

        setErrorMessage(
          'The email and password you entered do not match our records, please try again'
        );
      } else {
        track(EVENTS.loginAccount, {
          email,
          userStatus: user?.status,
        });
      }
    },
    [doLogin, doLogout, user]
  );

  return (
    <Layout showLighterColors module={'signup'}>
      <Stack sx={{ paddingBlock: isMobile ? '36px' : '58px' }} gap={'20px'}>
        <Stack className={styles.stackContainer} alignItems="center">
          <Box
            className={`${styles.backButtonWrapper} ${
              isMobile && styles.backButtonWrapper_mobile
            }`}
          />
        </Stack>

        <Stack gap={isMobile ? '16px' : '64px'} alignItems="center">
          <Box className={styles.heading}>Log in</Box>
          <Stack gap={isMobile ? '32px' : '16px'}>
            <Stack sx={{ width: isMobile ? 300 : 380 }} gap={'20px'}>
              <TextInput
                id="email"
                label="Email"
                placeholder="john.smith@gmail.com"
                {...formik.getFieldProps('email')}
                errorText={getErrors('email')}
              />

              <TextInput
                label="Password"
                placeholder="•••••••••"
                passwordField
                {...formik.getFieldProps('password')}
                errorText={getErrors('password')}
              />

              <Alert message={errorMessage} />
            </Stack>

            <Box mt={'16px'}>
              <Button
                type="submit"
                block
                disabled={buttonDisabled || formik.isSubmitting}
                onClick={() => formik.handleSubmit()}
                className={styles.button}>
                Log in
              </Button>
            </Box>
          </Stack>
        </Stack>
        <a href="/forgot-password" className={styles.helperText}>
          Forgotten password?
        </a>
      </Stack>
    </Layout>
  );
};

export default LoginAccount;
