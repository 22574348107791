import { createInstance, OptimizelyProvider } from '@optimizely/react-sdk';
import React, { useEffect, useState } from 'react';

export const optimizely = createInstance({
  sdkKey: process.env.REACT_APP_OPTIMIZELY_API_KEY,
});

type OptimizelyProps = {
  children: React.ReactNode;
};

function SetupOptimizely({ children }: OptimizelyProps) {
  const [userId, setUserId] = useState('');
  useEffect(() => {
    const storedUserId = localStorage.getItem('user_id');
    if (!storedUserId) {
      const newUserId = Date.now().toString();
      localStorage.setItem('user_id', newUserId);
      setUserId(newUserId);
    } else {
      setUserId(storedUserId);
    }
  }, []);

  return userId ? (
    <OptimizelyProvider
      optimizely={optimizely}
      user={{
        id: userId,
      }}>
      {children}
    </OptimizelyProvider>
  ) : (
    <></>
  );
}
export default SetupOptimizely;
