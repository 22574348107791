import React, { useState } from 'react';
import { Field, Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { Alert, Button, Form } from 'reactstrap';
import styles from './reset-password.module.scss';
import AuthService from '../../lib/auth/auth-service';
import history from '../../lib/history';
import Header from '../../components/header/header';

import ContentWrapper from '../../components/content/content-wrapper';
import ContentSection from '../../components/content/content-section';
import PasswordInput from '../../components/fields/password';

const schema = Yup.object({
  password: Yup.string() // TODO: Break up the password regex
    .required('Please enter a password')
    .matches(
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[-!$@#%^&*()_+|~=`{}:";'<>?,./]).{8,}$/,
      {
        message:
          'Password must be at least 8 characters with 1 number, 1 symbol, 1 upper case and 1 lower case letter',
        excludeEmptyString: true,
      }
    ),
  confirmPassword: Yup.string() // TODO: Break up the password regex
    .required('Please confirm your password')
    .oneOf([Yup.ref('password'), null], 'Passwords must match'),
});

interface OwnProps {
  token: string;
}

interface FormInputs {
  password: string;
  confirmPassword: string;
}

const initialValues: FormInputs = {
  password: '',
  confirmPassword: '',
};

const ResetPasswordForm = ({ token }: OwnProps): JSX.Element => {
  const [status, setStatus] = useState<string>('');

  const submitForm = async (
    { password }: FormInputs,
    actions: FormikHelpers<FormInputs>
  ): Promise<void> => {
    try {
      const message: string = await AuthService.changePassword(token, password);
      actions.setSubmitting(false);
      if (message === 'Password has changed') {
        setStatus('success');
      } else {
        setStatus('fail');
      }
    } catch (error) {
      console.error(
        "Error while attempting to update-subscription user's password",
        error
      ); // TODO ADD TOAST HANDLER WITH TIMEOUT LENGTH
    }
  };

  return (
    <>
      <Header />
      <div className={styles.resetPassword}>
        <ContentWrapper collapseAt="sm">
          <ContentSection collapseAt="sm">
            <h3>Reset password</h3>
            {status !== 'success' ? (
              <Formik
                validationSchema={schema}
                onSubmit={submitForm}
                initialValues={initialValues}>
                {(formikProps): JSX.Element => (
                  <Form noValidate onSubmit={formikProps.handleSubmit}>
                    <Field
                      name="password"
                      label="Password"
                      component={PasswordInput}
                    />
                    <Field
                      name="confirmPassword"
                      label="Confirm Password"
                      component={PasswordInput}
                    />

                    {status === 'fail' && (
                      <Alert color="danger">
                        Something went wrong. Please refresh the page and try
                        again.
                      </Alert>
                    )}

                    <Button
                      type="submit"
                      size="lg"
                      block
                      disabled={formikProps.isSubmitting}
                      className={styles.button}>
                      Reset password
                    </Button>
                  </Form>
                )}
              </Formik>
            ) : (
              <>
                <Alert color="success">
                  Your password was successfully reset.
                </Alert>
                <Button
                  type="button"
                  size="lg"
                  block
                  onClick={() => history.push('/login-account')}
                  className={styles.button}>
                  Go to login
                </Button>
              </>
            )}
          </ContentSection>
        </ContentWrapper>
      </div>
    </>
  );
};

export default ResetPasswordForm;
