import React, { useEffect, useState } from 'react';
import { Alert, Button } from 'reactstrap';
import { Coupon, Plans } from 'src/pages/deprecated/payment/types';
import PlanSelector from '../../../../../components/subscriptions/plan-selector/plan-selector';
import PlanQuantityCounter from '../../../../../components/subscriptions/plan-quantity-counter/plan-quantity-counter';
import {
  PLANS,
  PLAN_ID,
  PLAN_NAME,
  PLAN_TAB,
  QUERY_PARAM_STANDARD_PLAN_ONLY,
} from 'src/pages/constants';
import SubscriptionPopupTabs from 'src/pages/signup/steps/subscription/subscription-popup-tabs';
import styles from './update-subscription-popup.module.scss';
import PromoCode from 'src/components/fields/promo-code/promo-code';

type UpdateSubscriptionProps = {
  planRequested: string | undefined;
  plans: Plans | undefined;
  currentPlanId: number | undefined;
  showText: boolean;
  onClickContinue: (
    innerSelectedPlan: string,
    innerWeeklyMealPlanQty: number,
    coupon?: Coupon
  ) => void;
  selectedPlan: string;
  weeklyMealPlanQty: number;
  currentCheckinCount: number;
};

const isSubscriptionUnchanged = (
  selectedPlan: string,
  weeklyMealPlanQty: number,
  innerSelectedPlan: string,
  innerWeeklyMealPlanQty: number
) => {
  if (
    PLAN_NAME.DAILY_TARGETS_ONLY_PLAN.includes(innerSelectedPlan) &&
    innerWeeklyMealPlanQty > 0
  ) {
    return true;
  }

  if (
    PLAN_NAME.PREMIUM_PLAN.includes(innerSelectedPlan) &&
    innerWeeklyMealPlanQty === 0
  ) {
    return true;
  }

  return (
    innerSelectedPlan === selectedPlan &&
    innerWeeklyMealPlanQty === weeklyMealPlanQty
  );
};

export const UpdateSubscription = ({
  plans,
  selectedPlan,
  weeklyMealPlanQty,
  currentCheckinCount,
  currentPlanId,
  onClickContinue,
  planRequested,
  showText,
}: UpdateSubscriptionProps) => {
  const [innerSelectedPlan, setInnerSelectedPlan] =
    useState<string>(selectedPlan);
  const [innerWeeklyMealPlanQty, setInnerWeeklyMealPlanQty] =
    useState<number>(weeklyMealPlanQty);
  const [coupon, setCoupon] = useState<Coupon | undefined>();
  const [planTabSelected, setPlanTabSelected] = useState(1);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const searchParams = new URLSearchParams(window.location.search);
  const focusPremium = searchParams.get('focusPremium');

  useEffect(() => {
    if (
      (currentPlanId === PLAN_ID.STANDARD &&
        selectedPlan !== '' &&
        selectedPlan.includes('Standard')) ||
      focusPremium === 'false'
    ) {
      setPlanTabSelected(PLAN_TAB.STANDARD);
    }
  }, [currentPlanId, focusPremium, selectedPlan]);

  const subscriptionUnchanged = isSubscriptionUnchanged(
    selectedPlan,
    weeklyMealPlanQty,
    innerSelectedPlan,
    innerWeeklyMealPlanQty
  );

  const handleClickContinue = (): void => {
    if (!plans) {
      setErrorMessage(
        'Something went wrong... Please contact your coach or try again later'
      );
    } else if (subscriptionUnchanged) {
      setErrorMessage('You have selected the subscription you already have.');
    } else {
      onClickContinue(innerSelectedPlan, innerWeeklyMealPlanQty, coupon);
    }
  };

  useEffect(() => {
    if (innerSelectedPlan.includes('Daily Targets Only')) {
      setCoupon(undefined);
    }
  }, [innerSelectedPlan]);

  return (
    <div>
      <h3>Update subscription</h3>

      <SubscriptionPopupTabs
        currentPlan={selectedPlan}
        planTabSelected={planTabSelected}
        planRequested={
          planRequested === QUERY_PARAM_STANDARD_PLAN_ONLY
            ? 'Standard'
            : planRequested
        }
        showText={showText}
        weeklyMealPlanQty={innerWeeklyMealPlanQty}
        isNewUser={false}
        onClickTab={planTab => {
          setPlanTabSelected(planTab);
          if (planTab === PLAN_TAB.STANDARD) {
            setCoupon(undefined);
            setInnerWeeklyMealPlanQty(weeklyMealPlanQty);
            setInnerSelectedPlan(selectedPlan);
          }
        }}
      />

      <PlanSelector
        selectedPlan={innerSelectedPlan}
        setSelectedPlan={setInnerSelectedPlan}
        weeklyMealPlanQty={innerWeeklyMealPlanQty}
        plans={plans}
        planRequested={
          planRequested === QUERY_PARAM_STANDARD_PLAN_ONLY
            ? 'Standard'
            : planRequested
        }
        planTabSelected={planTabSelected}
      />
      <div className={styles.divider} />
      {!innerSelectedPlan.includes(PLANS.STANDARD_PLAN) &&
        planTabSelected !== PLAN_TAB.STANDARD && (
          <PlanQuantityCounter
            MAX={7}
            MIN={currentCheckinCount > 8 ? 0 : 1}
            weeklyMealPlanQty={innerWeeklyMealPlanQty}
            onChange={mealPlanQty => {
              setInnerWeeklyMealPlanQty(mealPlanQty);
            }}
          />
        )}

      {!innerSelectedPlan.includes(PLANS.STANDARD_PLAN) &&
        planTabSelected !== PLAN_TAB.STANDARD &&
        !innerSelectedPlan.includes('Daily Targets Only') &&
        innerWeeklyMealPlanQty > 0 &&
        selectedPlan.includes(PLANS.STANDARD_PLAN) && (
          <PromoCode
            priceId={
              (plans &&
                plans[innerSelectedPlan]?.mealPlanVariants[
                  innerWeeklyMealPlanQty
                ]?.id) ||
              ''
            }
            coupon={coupon}
            setCoupon={setCoupon}
            finalPrice={
              (plans &&
                plans[innerSelectedPlan]?.mealPlanVariants[
                  innerWeeklyMealPlanQty
                ].unitAmountCents / 100) ||
              0
            }
          />
        )}

      {errorMessage !== '' && <Alert color="danger">{errorMessage}</Alert>}

      <Button
        block
        onClick={handleClickContinue}
        disabled={subscriptionUnchanged && !!innerSelectedPlan}>
        Continue
      </Button>
    </div>
  );
};
