import Axios, { AxiosResponse } from 'axios';
import User from '../../models/User';
import { decodeCookie, encodeCookie } from '../cookie-parser';
import { IAuthCredentials } from './types';
import UserPayload from '../../models/payload/UserPayload';
import paymentAPI from 'src/pages/deprecated/payment/api';
import { sha256 } from 'js-sha256';

export default class AuthService {
  public static async logIn(
    credentials: IAuthCredentials
  ): Promise<User | undefined> {
    try {
      const response: AxiosResponse = await Axios.post(
        '/auth/login',
        credentials
      );
      encodeCookie({
        key: 'token',
        value: response.data.headers && response.data.headers.authorization,
      });
      return AuthService.getLoggedInUser(response.data?.body?.isQuiz);
    } catch (error) {
      console.error('Error logging in', error);
    }
  }

  public static logOut(): void {
    decodeCookie();
    encodeCookie({ key: 'token', value: '' });
    window.localStorage.removeItem('user');
    decodeCookie();
  }

  public static async getLoggedInUser(
    isQuiz = false
  ): Promise<User | undefined> {
    const authCookie: any = decodeCookie();
    let user: User | undefined;
    if (
      authCookie &&
      authCookie.token !== '' &&
      authCookie.token !== undefined
    ) {
      try {
        const userRecordResponse: AxiosResponse =
          await paymentAPI.get('/getUserDetail');
        user = userRecordResponse.data as User;
        const userDetailResponse: AxiosResponse = await paymentAPI.get(
          '/user/get-client-detail'
        );
        const {
          fName,
          lName,
          week_remaining,
          plan,
          isExClient,
          dayOfWeek,
          currentCheckin,
          payment_provider,
          country,
        } = userDetailResponse.data;
        user.isQuiz = isQuiz;
        user.firstName = fName;
        user.lastName = lName;
        user.week_remaining = week_remaining;
        user.current_checkin = currentCheckin;
        user.plan = plan;
        user.isExClient = isExClient;
        user.country = country;
        user.dayOfWeek = dayOfWeek;
        user.payment_provider = payment_provider;
        if (user.status && user.status === 'ACTIVE') {
          encodeCookie({
            key: 'UserID',
            value: sha256(user.email),
            domain: 'joinequ.com',
          });
        }
        window.localStorage.setItem('user', JSON.stringify(user));
        // user = await AuthService.getUserProfile(userRecordResponse.data.id);
      } catch (error) {
        // TODO: create an action to handle the error state
        console.error('Error getting logged-in user', error);
      }
    }
    return user;
  }

  public static async saveUserProfile(
    userPayload: UserPayload,
    recaptchaToken: string
  ): Promise<any> {
    const authCookie: any = decodeCookie();
    if (
      authCookie &&
      authCookie.token !== '' &&
      authCookie.token !== undefined
    ) {
      return {
        response: null,
        errorString: 'User is already logged in.',
      };
    }

    try {
      const userSaveResponse: AxiosResponse = await paymentAPI.post(
        `/sign-up-account?g-recaptcha-response=${recaptchaToken}`,
        userPayload
      );
      return { response: userSaveResponse.data, errorString: null };
    } catch (error) {
      console.error('Could not save user', error);
      return {
        response: null,
        errorString: error?.response?.data?.message,
      };
    }
  }

  public static async resetUserPassword(
    userPayload: UserPayload
  ): Promise<string> {
    const authCookie: any = decodeCookie();
    let successString = '';
    if (
      authCookie &&
      authCookie.token !== '' &&
      authCookie.token !== undefined
    ) {
      throw new Error('User already logged in.');
    } else {
      try {
        const userSaveResponse: AxiosResponse = await paymentAPI.post(
          '/forgot-password',
          userPayload
        );
        successString = userSaveResponse.data;
      } catch (error) {
        console.error('Could not save user', error);
      }
    }
    return successString;
  }

  public static async checkValidToken(token: string): Promise<boolean> {
    const authCookie: any = decodeCookie();
    let successString = '';
    if (
      authCookie &&
      authCookie.token !== '' &&
      authCookie.token !== undefined
    ) {
      throw new Error('User already logged in.');
    } else {
      try {
        const userSaveResponse: AxiosResponse = await paymentAPI.post(
          `/validate-token/${token}`
        );
        successString = userSaveResponse.data;
        return successString === 'Valid token';
      } catch (error) {
        console.error('Could not save user', error);
      }
      return false;
    }
  }

  public static async changePassword(
    token: string,
    newpassword: string
  ): Promise<string> {
    const authCookie: any = decodeCookie();
    let successString = '';
    if (
      authCookie &&
      authCookie.token !== '' &&
      authCookie.token !== undefined
    ) {
      throw new Error('User already logged in.');
    } else {
      try {
        const userSaveResponse: AxiosResponse = await paymentAPI.post(
          `/reset/${token}`,
          { newpassword }
        );
        successString = userSaveResponse.data;
        return successString;
      } catch (error) {
        console.error('Could not save user', error);
      }
      return '';
    }
  }
}
