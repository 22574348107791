import React, { FC, useEffect } from 'react';
import { Box, ButtonBase, Typography } from '@mui/material';
import colors from 'src/styles/equalution/colors';
import Fonts from 'src/styles/equalution/fonts';
import { getEcommerceDataProperties, useViewport } from 'src/lib/utils';
import { Plan } from 'src/pages/deprecated/payment/types';
import { EVENTS, track } from 'src/util/track';
import { useSelector } from 'react-redux';
import { IAppState } from 'src/store';
import { useAppSelector } from 'src/store/hooks';

interface Props {
  selected?: string;
  availablePlans: Plan[];
  onSelect: (id: string) => void;
  variant?: 'dark' | 'light';
}
const SubscriptionDurationButtons: FC<Props> = props => {
  const { isMobile } = useViewport();
  const user = useSelector((state: IAppState) => state.auth?.user);

  const { selectedPlanId } = useAppSelector(state => state.signUp);

  const { selected, availablePlans, onSelect, variant = 'light' } = props;

  const selectedBackgroundColor =
    variant === 'dark' ? colors.SALAD : colors.ORANGE_HALF;
  const selectedBorderColor =
    variant === 'dark' ? colors.SALAD : colors.ORANGE_HALF;
  const selectedColor = variant === 'dark' ? colors.WHITE : colors.OFF_BLACK;

  const gridTemplateColumns =
    availablePlans.length > 3 ? '1fr 1fr' : '1fr 1fr 1fr';

  useEffect(() => {
    track(EVENTS.viewItem, { ecommerce: null });
    track(EVENTS.viewItem, {
      logged_in_status: Boolean(user),
      ecommerce: getEcommerceDataProperties(selectedPlanId, availablePlans),
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, selectedPlanId]);

  return (
    <Box
      sx={{
        display: 'grid',
        gridTemplateColumns,
      }}>
      {availablePlans.map(planObj => (
        <ButtonBase
          key={planObj.id}
          sx={{
            display: 'flex',
            flex: 1,
            paddingBlock: '8px',
            paddingInline: isMobile ? '8px' : '16px',
            minWidth: isMobile ? 'unset' : '120px',
            backgroundColor:
              selected === planObj.id ? selectedBackgroundColor : 'transparent',
            border: `1px solid ${
              selected === planObj.id ? selectedBorderColor : colors.ORANGE_HALF
            }`,
            borderRadius: '20px',
            alignItems: 'center',
            justifyContent: 'center',
            margin: '8px',
            transitionDuration: '0.3s',
          }}
          disableRipple
          onClick={() => onSelect(planObj.id)}>
          <Typography
            sx={{
              ...Fonts.style.boldBody,
              color:
                selected === planObj.id ? selectedColor : colors.BLUEBERRY_HALF,
              fontSize: '16px',
              fontWeight: 700,
            }}>
            {`${planObj.intervalCount} ${planObj.interval}`}
            {planObj.intervalCount !== 1 && `s`}
          </Typography>
        </ButtonBase>
      ))}
    </Box>
  );
};

export default SubscriptionDurationButtons;
