const colors = {
  // NEW COLORS:
  CUCUMBER: '#116A48',

  SALAD: '#01965E',
  SALAD_HALF: '#C1F0DF',

  ORANGE: '#F58C35',
  ORANGE_HALF: '#F9BF8F',

  BLUE: '#3DA5D9',
  DEEP_BLUE: '#1F5C8C',

  BLUEBERRY: '#555768',
  BLUEBERRY_HALF: '#6B6C7B',

  CREAM: '#FFDFB4',
  CREAM_HALF: '#FFEEDA',

  OFF_BLACK: '#101820',

  OFF_WHITE: '#FCF9EF',
  WHITE: '#FFFFFF',

  UTILITY_GREEN: '#3B9578',

  ERROR_RED: '#E54639',
};

export default colors;
