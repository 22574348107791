import React, { FunctionComponent, useState, useEffect, Fragment } from 'react';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import { Button } from 'reactstrap';
import history from '../../../lib/history';
import styles from './account-menu.module.scss';
import { AccountRoute } from '../types';
import { IAppState } from '../../../store';
import ContentSection from '../../../components/content/content-section';
import { USER_STATUS } from 'src/pages/constants';
import { handleProspectUserRedirect } from 'src/store/signup';
import { useAppDispatch } from 'src/store/hooks';

interface OwnProps {
  routes: AccountRoute[];
}

type Props = OwnProps;

const AccountMenu: FunctionComponent<Props> = ({ routes }: Props) => {
  const user = useSelector((state: IAppState) => state.auth?.user);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (
      user?.status === USER_STATUS.PROSPECT ||
      user?.status === USER_STATUS.INACTIVE
    ) {
      dispatch(handleProspectUserRedirect());
      setTimeout(() => {
        history.push('/signup?planType=standard&duration=3');
      }, 500);
    }
  }, [dispatch, user]);

  const location = useLocation();
  const [collapsed, setCollapsed] = useState<boolean>(true);

  return (
    <ContentSection
      className={`${styles.accountMenu} ${collapsed ? styles.closed : ''}`}>
      <Button
        className={styles.close}
        onClick={(): void => setCollapsed(!collapsed)}
      />
      {routes.map(route => {
        return user?.status ===
          route?.statusesGranted?.find(r => r === user?.status) ||
          !route.statusesGranted?.length ? (
          <Link
            key={route.path}
            to={route.path}
            className={route.path === location.pathname ? styles.active : ''}
            onClick={(): void => setCollapsed(!collapsed)}>
            {route.name}
          </Link>
        ) : (
          <Fragment key={route.path} />
        );
      })}
    </ContentSection>
  );
};

export default AccountMenu;
