import React, { PropsWithChildren, useEffect, useRef } from 'react';

type Props = {
  onClickOutside: () => void;
};

const OutsideAlerter = ({
  onClickOutside,
  children,
}: PropsWithChildren<Props>): JSX.Element => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent): void => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        onClickOutside();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return (): void => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClickOutside, ref]);

  return (
    <div ref={ref} style={{ display: 'contents' }}>
      {children}
    </div>
  );
};

export default OutsideAlerter;
