import React, { useCallback, useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import styles from './user-button.module.scss';
import OutsideAlerter from 'src/components/content/outside-alerter/outside-alerter';
import { AuthenticationAction, logOut } from 'src/lib/auth/actions';
import { IAppState } from 'src/store';
import history from 'src/lib/history';
import { useAppDispatch } from 'src/store/hooks';
import { setSignupStep } from 'src/store/signup';

interface Props {
  hideAccount: boolean | undefined;
}

const UserButton = (props: Props): React.ReactElement => {
  const { hideAccount } = props;
  // Redux Selectors
  const user = useSelector((state: IAppState) => state.auth?.user);

  // Redux Dispatch
  const dispatch: ThunkDispatch<AuthenticationAction, never, AnyAction> =
    useDispatch();
  const appDispatch = useAppDispatch();

  const doLogout = useCallback(() => {
    dispatch(logOut());
    appDispatch(setSignupStep(1));
    setTimeout(() => {
      history.replace('/login-account');
    }, 500);
  }, [appDispatch, dispatch]);

  const [menuVisible, setMenuVisible] = useState<boolean>(false);

  return (
    <div
      className={styles.userButton}
      onClick={(): void => setMenuVisible(true)}>
      <p>
        {user?.email} <span className={styles.arrow} />
      </p>
      <div className={styles.userIcon} />

      {menuVisible && (
        <OutsideAlerter onClickOutside={(): void => setMenuVisible(false)}>
          <div className={styles.userMenu}>
            {!hideAccount ? <Link to="/account">Account</Link> : <></>}
            <Link to="/login-account" onClick={doLogout} className={styles.red}>
              Log out
            </Link>
          </div>
        </OutsideAlerter>
      )}
    </div>
  );
};

export default UserButton;
