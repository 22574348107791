import React, { ReactNode, useEffect, useRef } from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { FieldProps } from 'formik';

interface OwnProps {
  label: string;
  setStatus: (value: string) => void;
}

type Props = OwnProps;

const EmailInput = ({
  label,
  setStatus,
  field: { name, onChange, onBlur },
  form: { touched, errors },
}: Props & FieldProps): JSX.Element => {
  // Handle email from Query params -> coming from quiz form for actual input value
  const searchParams = new URLSearchParams(window.location.search);
  const urlParamEmail = searchParams.get('email') || '';
  const inputRef: any = useRef(null);

  useEffect(() => {
    if (inputRef && urlParamEmail) {
      inputRef.current.value = urlParamEmail;
      inputRef.current.focus();
    }
  }, [urlParamEmail]);

  return (
    <FormGroup
      className={!!touched[name] && !!errors[name] ? 'is-invalid' : ''}>
      <div className="label-feedback">
        <Label for={name}>{label}</Label>
        <FormFeedback valid>Success</FormFeedback>
        <FormFeedback valid={false}>{errors[name] as ReactNode}</FormFeedback>
      </div>
      <Input
        type="email"
        innerRef={inputRef}
        id={name}
        name={name}
        placeholder="example@example.com"
        onChange={e => {
          if (setStatus) {
            setStatus('');
          }
          onChange(e);
        }}
        onBlur={onBlur}
      />
    </FormGroup>
  );
};

export default EmailInput;
