import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

import colors from 'src/styles/equalution/colors';

interface SwiperTextMobileStepperProps {
  comment: string;
  by: string;
  image: string;
}

const SwiperTextMobileStepper = (props: {
  data: SwiperTextMobileStepperProps[];
}) => {
  const { data } = props;
  const [activeStep] = React.useState(0);

  return (
    <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 7000,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={false}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper">
        {data.map((step, index) => (
          <div key={index}>
            {Math.abs(activeStep - index) <= 2 ? (
              <>
                <SwiperSlide>
                  <Box
                    sx={{
                      backgroundColor: colors.CREAM,
                      padding: '12px 20px',
                      borderRadius: '20px',
                      display: 'flex',
                      flexDirection: 'column',
                      margin: '20px 0px',
                    }}>
                    <Typography
                      sx={{
                        fontSize: '15px',
                        weight: 400,
                        color: colors.OFF_BLACK,
                      }}>
                      {step.comment}
                    </Typography>
                    <Typography
                      sx={{
                        textAlign: 'end',
                        fontSize: '15px',
                        weight: 400,
                        color: colors.OFF_BLACK,
                      }}>
                      {step.by}
                    </Typography>
                  </Box>
                  <Box
                    component="img"
                    sx={{
                      display: 'block',
                      maxWidth: 400,
                      overflow: 'hidden',
                      width: '100%',
                    }}
                    src={step.image}
                    alt={'Testimonial' + index}
                  />
                </SwiperSlide>
              </>
            ) : null}
          </div>
        ))}
      </Swiper>
    </Box>
  );
};

export default SwiperTextMobileStepper;
