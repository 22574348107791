import React, { FC, PropsWithChildren } from 'react';
import { Box, Stack } from '@mui/material';
import styles from './layout.module.scss';
import HeaderNew, { HeaderProps } from 'src/pages/header-new/HeaderNew';
import { useSelector } from 'react-redux';
import { IAppState } from 'src/store';
import colors from 'src/styles/equalution/colors';

interface LayoutProps extends HeaderProps {
  hideLogo?: boolean;
  hideHeader?: boolean;
  showLighterColors?: boolean;
}

export const Layout: FC<PropsWithChildren<LayoutProps>> = ({
  children,
  hideLogo,
  module,
  hideHeader,
  showLighterColors,
}) => {
  const hideSemiCircleSteps = [13, 25, 29];
  const darkQuizSteps = [12];

  const { quizData } = useSelector((state: IAppState) => state.quiz);
  return (
    <Box
      className={styles.layoutContainer}
      style={{
        backgroundColor: showLighterColors
          ? colors.OFF_WHITE
          : colors.CREAM_HALF,
      }}>
      <Box
        className={
          hideSemiCircleSteps.includes(quizData.currentStep)
            ? ''
            : darkQuizSteps.includes(quizData.currentStep)
            ? styles.darkCircleBackground
            : styles.circleBackground
        }
        style={{
          backgroundColor: darkQuizSteps.includes(quizData.currentStep)
            ? ''
            : showLighterColors
            ? colors.CREAM_HALF
            : colors.CREAM,
        }}
      />
      {!hideHeader && <HeaderNew module={module} hideLogo={hideLogo} />}
      <Stack className={styles.childrenContainer}>{children}</Stack>
    </Box>
  );
};
