import React from 'react';
import { Alert, Button } from 'reactstrap';
import Header from '../../components/header/header';
import styles from './reset-password.module.scss';
import ContentWrapper from '../../components/content/content-wrapper';
import ContentSection from '../../components/content/content-section';
import history from '../../lib/history';

const InvalidToken = (): JSX.Element => {
  return (
    <>
      <Header />
      <div className={styles.resetPassword}>
        <ContentWrapper collapseAt="sm">
          <ContentSection collapseAt="sm">
            <h3>Oops this link has expired</h3>
            <Alert color="danger">
              It&apos;s okay let&apos;s get you a new one.
            </Alert>
            <Button
              type="button"
              size="lg"
              block
              onClick={(): void => history.push('/forgot-password')}
              className={styles.button}>
              Request another
            </Button>
          </ContentSection>
        </ContentWrapper>
      </div>
    </>
  );
};

export default InvalidToken;
