import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import ContentCard from '../../../../../components/content/content-card';
import {
  Plans,
  Customer,
  Subscription,
  SubscriptionSchedules,
  Price,
  Prices,
} from '../../../../deprecated/payment/types';
import { UpdateSubscriptionPopup } from '../update-subscription-popup/update-subscription-popup';
import User from '../../../../../models/User';
import ContentCardLabel from '../../../../../components/content/content-card-label';
import styles from './update-subscription.module.scss';
import UpdateSubscriptionPreview from './update-subscription-preview';
import UpcomingSubscription from './upcoming-subscription-preview';
import AllSubscriptions from 'src/models/AllSubscriptions';
import { PLAN_NAME } from 'src/pages/constants';
import moment from 'moment';
import Popup from 'src/components/content/popup/popup';
import ContentWrapper from 'src/components/content/content-wrapper';
import ContentSection from 'src/components/content/content-section';
import warning from '../../../../../assets/icons/warning.svg';
import paymentAPI from 'src/pages/deprecated/payment/api';
import { UserTags } from 'src/pages/account/types';

interface OwnProps {
  allSubscriptions: AllSubscriptions | undefined;
  setProductId: (id: string) => void;
  setPriceId: (id: string) => void;
  completed: boolean;
  user: User | undefined;
  plans: Plans;
  prices: Prices | undefined;
  customer: Customer | undefined;
  showText: boolean;
  subscriptionSchedules: SubscriptionSchedules[] | undefined;
  scheduledPrice: Price | undefined;
  planRequested: string | undefined;
  setAllsubscriptions: (state: AllSubscriptions) => void;
}

const UpdateSubscription = ({
  allSubscriptions,
  setProductId,
  setPriceId,
  completed,
  user,
  customer,
  plans,
  prices,
  subscriptionSchedules,
  scheduledPrice,
  planRequested,
  setAllsubscriptions,
  showText,
}: OwnProps): React.ReactElement => {
  // Form State
  const [selectedPlan, setSelectedPlan] = useState<string>('');
  const [weeklyMealPlanQty, setWeeklyMealPlanQty] = useState<number>(1);
  const [repurchaseSubscription, setRepurchaseSubscription] =
    useState<boolean>(false);
  const [subscription, setSubscription] = useState<Subscription>();
  // Modal Visibility
  const [popupVisible, setPopupVisible] = useState<boolean>(false);
  const [warningPopupVisible, setWarningPopupVisible] =
    useState<boolean>(false);
  const [userTags, setUserTags] = useState<UserTags>();
  const formattedDate = moment().utc().format('YYYY MM DD');
  const localDate = moment(formattedDate);
  const challengeStartDate = moment(userTags?.tag.challenge_start_date);
  const searchParams = new URLSearchParams(window.location.search);
  const focusPremium = searchParams.get('focusPremium');

  useEffect(() => {
    if (focusPremium !== null && focusPremium !== 'undefined') {
      setPopupVisible(true);
    }
  }, [focusPremium]);

  useEffect(() => {
    const subscriptions = customer?.subscriptions?.data;

    if (!subscriptions && !scheduledPrice) {
      return;
    }

    if (scheduledPrice) {
      const planName = `${scheduledPrice?.metadata.planName}`;
      setSelectedPlan(planName);
    } else if (subscriptions?.length) {
      const planName = `${subscriptions[0].plan.metadata.planName}`;
      setSubscription(subscriptions[0]);
      setSelectedPlan(planName);
    } else {
      setSelectedPlan('');
      setRepurchaseSubscription(true);
    }
  }, [customer, planRequested, plans, scheduledPrice]);

  // Upon Plan Selection and Plan Loading, add to outer scope
  useEffect(() => {
    if (
      plans &&
      plans[selectedPlan] &&
      PLAN_NAME.DAILY_TARGETS_ONLY_PLAN.includes(selectedPlan)
    ) {
      setProductId(plans[selectedPlan]?.mealPlanVariants[0].productId);
      setPriceId(plans[selectedPlan]?.mealPlanVariants[0].id);
    } else if (plans && plans[selectedPlan] && weeklyMealPlanQty > 0) {
      // TODO quick fix for a crash when moving from daily targets to premium plan.
      // AccountSubscription state can be {selectedPlan: 'Premium Plan - 6 Months', weeklyMealPlanQty: 0}
      // which throws an error, need to merge setSelectedPlan and setSelectedMealPlanQty into one hook
      // weeklyMealPlanQty > 0 is added to avoid the crash
      setProductId(
        plans[selectedPlan]?.mealPlanVariants[weeklyMealPlanQty].productId
      );
      setPriceId(plans[selectedPlan]?.mealPlanVariants[weeklyMealPlanQty].id);
    }
  }, [plans, selectedPlan, weeklyMealPlanQty, setProductId, setPriceId]);

  // If user logs out, reset meal plan quantity.
  useEffect(() => {
    if (!user) {
      setWeeklyMealPlanQty(1);
    }
  }, [user]);

  useEffect(() => {
    (async () => {
      const result = await paymentAPI.get('user/userTagDetails');
      if (result?.data) {
        setUserTags(result?.data);
      }
    })();
  }, [customer]);

  const showPopupModal = () => {
    if (user?.pay_now_start_later && localDate.isBefore(challengeStartDate)) {
      setWarningPopupVisible(true);
      setPopupVisible(false);
    } else {
      setPopupVisible(true);
      setWarningPopupVisible(false);
    }
  };

  return (
    <>
      <div>
        <ContentCardLabel className={styles.cardLabel}>
          <div>Current subscription</div>
        </ContentCardLabel>
        {allSubscriptions?.trialSubscription && (
          <div style={{ marginBottom: '10px' }}>
            {allSubscriptions?.trialSubscription?.remaining_days === 0 ? (
              <div className={styles.freeTrialText}>
                Today is the{' '}
                <span className={styles.redTrialText}>final day </span>
                of your free month offer
              </div>
            ) : (
              <div className={styles.freeTrialText}>
                <span className={styles.redTrialText}>
                  {allSubscriptions?.trialSubscription.remaining_days} days{' '}
                </span>
                left on your free month offer
              </div>
            )}
          </div>
        )}

        <ContentCard lighter className={styles.card}>
          <UpdateSubscriptionPreview
            subscriptionSchedules={subscriptionSchedules}
            selectedPlan={selectedPlan}
            plans={plans}
            prices={prices}
            customer={customer}
            paused={!!subscription?.pause_collection}
            allSubscriptions={allSubscriptions}
          />
          <Button
            size="sm"
            outline={completed}
            onClick={(): void => showPopupModal()}
            disabled={!!subscription?.pause_collection}>
            {completed ? 'Update' : 'Select plan'}
          </Button>
        </ContentCard>
      </div>

      {allSubscriptions?.upcomingSubscription && (
        <div>
          <ContentCardLabel className={styles.cardLabel}>
            <div>Upcoming subscription</div>
          </ContentCardLabel>
          <ContentCard lighter className={styles.card}>
            <UpcomingSubscription allSubscriptions={allSubscriptions} />
          </ContentCard>
        </div>
      )}

      {popupVisible && plans && (
        <UpdateSubscriptionPopup
          repurchaseSubscription={repurchaseSubscription}
          selectedPlan={selectedPlan}
          setSelectedPlan={setSelectedPlan}
          weeklyMealPlanQty={weeklyMealPlanQty}
          setWeeklyMealPlanQty={setWeeklyMealPlanQty}
          plans={plans}
          setPopupVisible={setPopupVisible}
          customer={customer}
          planRequested={planRequested}
          setAllsubscriptions={setAllsubscriptions}
          showText={showText}
        />
      )}

      {warningPopupVisible && (
        <Popup>
          <ContentWrapper className={styles.background} collapseAt="sm">
            <ContentSection
              className={styles.updateSubscriptionWarning}
              collapseAt="sm">
              <Button
                className={styles.close}
                onClick={(): void => setWarningPopupVisible(false)}
              />
              <div
                style={{
                  textAlign: 'center',
                  marginBottom: '15px',
                }}>
                <img src={warning} alt={''} />
              </div>
              <div className={styles.title}>
                Your subscription could not be updated
              </div>
              {challengeStartDate.diff(localDate, 'days') > 7 ? (
                <div className={styles.bodytext}>
                  You are unable to update your subscription until the start of
                  the Do It For You Challenge on{' '}
                  {moment(challengeStartDate).format('MMMM DD YYYY')}. To
                  activate your account now, reach out via the in-app chat or{' '}
                  <b>info@equalution.com</b>.
                </div>
              ) : (
                <div className={styles.bodytext}>
                  You are unable to update your subscription until the start of
                  the Do It For You Challenge on{' '}
                  {moment(challengeStartDate).format('MMMM DD YYYY')}. If you
                  require support, please reach out via the in-app chat or{' '}
                  <b>info@equalution.com</b>.
                </div>
              )}

              <Button block onClick={() => setWarningPopupVisible(false)}>
                Ok
              </Button>
            </ContentSection>
          </ContentWrapper>
        </Popup>
      )}
    </>
  );
};

export default UpdateSubscription;
