import React from 'react';
import { getCurrencySymbol } from 'src/lib/utils';
import { Coupon } from 'src/pages/deprecated/payment/types';

interface CouponProps {
  coupon: Coupon;
  couponCode: string;
}
const GenerateCouponText = (props: CouponProps): JSX.Element => {
  const { coupon, couponCode } = props;
  if (couponCode === 'EOFY50') {
    // EOFY50 is a special case
    return (
      <div>
        Promo code <strong>EOFY50</strong> applied for a{' '}
        <strong>50% discount</strong> on your first payment of your
        subscription.
      </div>
    );
  }
  return (
    <div>
      {coupon?.metadata?.futureCommencement === 'true' &&
        'First payment will be taken upon sign up. '}
      Promo code <strong>{couponCode}</strong> applied for a{' '}
      <strong>
        {coupon.amountOff > 0
          ? `${getCurrencySymbol()}${coupon.amountOff / 100} discounted`
          : coupon.percentOff > 0
          ? `${coupon.percentOff}% discount`
          : ''}
      </strong>{' '}
      to your{' '}
      {coupon.duration.toLowerCase() === 'once'
        ? `first payment of your subscription`
        : `subscription`}
      {coupon.name.toLowerCase().includes('3 month period')
        ? `, for over 3 months. Price reverts back to normal pricing after a 3 month
                                            period. All standard Terms & Conditions apply.`
        : ''}
    </div>
  );
};

export default GenerateCouponText;
