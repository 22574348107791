import React, { FC } from 'react';
import { Box } from '@mui/material';

import { PlanType } from 'src/pages/constants';
import SubscriptionDurationButtons from '../subscription-duration-buttons';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { setSelectedPlanId } from 'src/store/signup';

interface DurationProps {
  planType: PlanType;
}

const SubscriptionDurationContainer: FC<DurationProps> = ({
  planType = 'premium',
}) => {
  const dispatch = useAppDispatch();
  const isStandardPlan = planType === 'standard';
  const { availablePlans, selectedPlanId } = useAppSelector(
    state => state.signUp
  );

  const handleSelect = (planId: string) => {
    dispatch(setSelectedPlanId(planId));
  };

  return (
    <Box
      my={'16px'}
      width={'100%'}
      textAlign={'center'}
      fontSize={'20px'}
      fontWeight={800}>
      Our billing cycles are flexible
      <Box mt={'16px'}>
        <SubscriptionDurationButtons
          availablePlans={availablePlans}
          selected={selectedPlanId}
          onSelect={handleSelect}
          variant={!isStandardPlan ? 'dark' : 'light'}
        />
      </Box>
    </Box>
  );
};

export default SubscriptionDurationContainer;
