import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Alert } from 'reactstrap';
import { PaymentMethod, loadStripe } from '@stripe/stripe-js';
import styles from './account-subscription.module.scss';
import { IAppState } from '../../../../store';
import Loader from '../../../../components/content/loader';
import UpdateSubscription from './update-subscription/update-subscription';
import {
  Customer,
  Plans,
  Product,
  SubscriptionSchedules,
  Price,
  Prices,
} from '../../../deprecated/payment/types';
import paymentAPI from '../../../deprecated/payment/api';
import UpdatePayment from './update-payment/update-payment';
import AllSubscriptions from 'src/models/AllSubscriptions';
import moment from 'moment';
import { REQUEST_STATUS_CODE } from '../../types';
import { stripeProductsApi } from 'src/services';
import { isUserFromNewZealand } from 'src/lib/utils';
// import { checkNZUser } from 'src/lib/utils';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const AccountSubscription = (): React.ReactElement => {
  const { user } = useSelector((state: IAppState) => state.auth);

  const [productId, setProductId] = useState<string>('');
  const [priceId, setPriceId] = useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
  const [customer, setCustomer] = useState<Customer>();
  const [plans, setPlans] = useState<Plans>();
  const [prices, setPrices] = useState<Prices>();
  const [showText, setShowText] = useState<boolean>(false);
  const [allSubscriptions, setAllSubscriptions] = useState<AllSubscriptions>();
  const [scheduledPrice, setScheduledPrice] = useState<Price>();
  const [subscriptionSchedules, setSubscriptionSchedules] =
    useState<SubscriptionSchedules[]>();

  const [planRequest, setPlanRequest] = useState('');
  const [subscriptionRequestStatus, setSubscriptionRequestStatus] = useState(0);
  const updateAllSubscriptions = (state: AllSubscriptions) => {
    setAllSubscriptions(state);
  };
  const isMounted = useRef(true);

  const isNewZealandUser =
    isUserFromNewZealand() || (user?.country ?? '') === 'New Zealand';

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const planRequestQueryParam = searchParams.get('planRequest');

    // If the search param contains planRequest=Standard
    // Set the planRequest in session storage
    if (planRequestQueryParam) {
      sessionStorage.setItem(
        'subscriptions',
        JSON.stringify({
          planRequestExpiresAt: moment().add(30, 'minute').unix(),
          planRequest: planRequestQueryParam,
        })
      );
      setPlanRequest(planRequestQueryParam);
    } else {
      const sessionObject = JSON.parse(
        sessionStorage.getItem('subscriptions') || '{}'
      );
      if (
        sessionObject.planRequestExpiresAt &&
        moment().isBefore(moment.unix(sessionObject.planRequestExpiresAt))
      ) {
        setPlanRequest(sessionObject.planRequest);
      }
    }
  }, []);

  useEffect(() => {
    if (isMounted.current) {
      (async () => {
        const subscriptions = await paymentAPI.get(
          'stripe/customer/subscriptions'
        );
        if (subscriptions?.data) {
          setAllSubscriptions(subscriptions?.data);
        }
        if (
          Number(subscriptions?.data?.currentSubscription?.totalPrice) === 0
        ) {
          setShowText(true);
        }
        setSubscriptionRequestStatus(subscriptions.status);
      })();
      return () => {
        isMounted.current = false;
      };
    }
  }, [allSubscriptions]);

  useEffect(() => {
    (async (): Promise<void> => {
      // Get customer
      const customerResponse = await paymentAPI.get(
        'stripe/customer-my-account',
        {
          params: {
            email: user?.email,
          },
        }
      );

      if (!customerResponse?.data?.customer) {
        setErrorMessage('Failed to retreive customer data');
      }
      // Get default payment method
      const defaultPaymentMethod: PaymentMethod =
        customerResponse?.data?.customer.invoice_settings
          .default_payment_method;
      setCustomer(customerResponse?.data?.customer);

      // Get customer schedule subscriptoons
      const scheduleResponse = await paymentAPI.get(
        'stripe/customer/schedules',
        {
          params: {
            customerId: customerResponse?.data?.customer?.id,
          },
        }
      );

      let customerPriceId;

      if (scheduleResponse?.data?.subscriptionSchedules?.data?.length > 0) {
        setSubscriptionSchedules(
          scheduleResponse?.data?.subscriptionSchedules?.data
        );
        customerPriceId =
          scheduleResponse?.data?.subscriptionSchedules?.data[0]?.phases[0]
            ?.items[0]?.price;
      } else {
        customerPriceId =
          customerResponse?.data?.customer?.subscriptions?.data[0]?.plan?.id;
      }

      if (customerPriceId) {
        const price = await paymentAPI.get('stripe/prices', {
          params: {
            priceId: customerPriceId,
          },
        });

        if (price) {
          setScheduledPrice(price?.data?.priceToReturn);
        } else {
          setErrorMessage('Failed to retreive customer subscription data');
        }
      }

      if (defaultPaymentMethod) {
        setPaymentMethod(defaultPaymentMethod);
      }
    })();
  }, [user]);

  // TODO Extract Function to Utils
  const extractPlans = (currentPlans: Plans, productData: Product): Plans => {
    return Object.values(productData.prices).reduce<Plans>(
      (accumulator, price) => {
        if (
          !price.active ||
          !price.recurring ||
          price.recurring.weeklyMealPlanQty === null
        ) {
          return accumulator;
        }

        const planName = `${price.metadata.planName}`;

        // If plan does not exist, create plan
        if (!accumulator[planName]) {
          accumulator[planName] = {
            id: planName,
            interval: price.recurring.interval,
            intervalCount: price.recurring.intervalCount,
            mealPlanVariants: {},
            active: price.active,
            metadata: {},
            currency_options: price.currency_options || [],
          };
        }

        // const unitAmount = checkNZUser()
        //   ? price?.currency_options?.nzd?.unit_amount / 100 ||
        //     price?.currency_options?.aud?.unit_amount / 100
        //   : price?.currency_options?.aud?.unit_amount / 100;

        // Add meal plan variant
        accumulator[planName].mealPlanVariants[
          price.recurring.weeklyMealPlanQty
        ] = {
          id: price.id,
          productId: productData.id,
          unitAmount: price.unitAmount,
          unitAmountCents: price.unitAmountCents,
          // unitAmount: unitAmount?.toString() || price.unitAmount,
          // unitAmountCents: unitAmount * 100 || price.unitAmountCents,
          unitAmountPerDay: price.unitAmountPerDay,
          unitAmountPerWeek: price.unitAmountPerWeek,
          weeklyMealPlanQty: price.recurring.weeklyMealPlanQty,
          weeksPurchased: price.weeksPurchased,
        };

        return accumulator;
      },
      currentPlans
    );
  };

  // Get Product Data on Mount
  useEffect(() => {
    let mounted = true;
    (async (): Promise<void> => {
      try {
        // Get Products Data
        const response = await stripeProductsApi({
          isNZUser: isNewZealandUser,
        });
        const productsData = response.data.products;

        if (!mounted) return;

        const tempPlans: Plans = {};

        const rawProducts: unknown[] = Object.values(productsData).filter(
          (x: unknown) => {
            const product = x as Product;
            if (
              product.metadata?.EQPlanID === '1' ||
              product.metadata?.EQPlanID === '4'
            ) {
              return x;
            }
            return false;
          }
        );

        if (rawProducts.length > 0) {
          const firstEntry = rawProducts[0] as Product;
          setPrices(firstEntry.prices);
        }

        // Find Product
        Object.values(productsData).forEach((productEntry: unknown) => {
          const tempProduct: Product = productEntry as Product;

          if (
            tempProduct?.metadata?.EQPlanID === '1' ||
            tempProduct?.metadata?.EQPlanID === '2' ||
            tempProduct?.metadata?.EQPlanID === '4'
          ) {
            extractPlans(tempPlans, tempProduct);
          }
        });

        setPlans(tempPlans);
      } catch (e) {
        console.log('getProducts error', e);
      }
    })();
    return (): void => {
      mounted = false;
    };
  }, [isNewZealandUser]);

  return (
    <div className={styles.accountSubscription}>
      <h3>Subscription</h3>
      {customer &&
      plans &&
      subscriptionRequestStatus === REQUEST_STATUS_CODE.OK ? (
        <>
          <UpdateSubscription
            allSubscriptions={allSubscriptions}
            setProductId={setProductId}
            setPriceId={setPriceId}
            plans={plans}
            prices={prices}
            completed={productId !== '' && priceId !== ''}
            user={user}
            customer={customer}
            subscriptionSchedules={subscriptionSchedules}
            scheduledPrice={scheduledPrice}
            planRequested={planRequest}
            setAllsubscriptions={updateAllSubscriptions}
            showText={showText}
          />

          <UpdatePayment
            user={user}
            stripePromise={stripePromise}
            paymentMethod={paymentMethod}
            setPaymentMethod={setPaymentMethod}
            completed={!!paymentMethod}
          />
        </>
      ) : (
        <Loader />
      )}

      {errorMessage !== '' && <Alert color="danger">{errorMessage}</Alert>}
    </div>
  );
};

export default AccountSubscription;
