import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { Button } from 'reactstrap';
import styles from './extras-counter.module.scss';

interface OwnProps {
  max?: number;
  min?: number;
  defaultQty: number;
  label: string;
  onQuantityChange(qty: number, type: string): void;
}

type Props = OwnProps;

const ExtrasCounter: FunctionComponent<Props> = ({
  label,
  max,
  min,
  onQuantityChange,
  defaultQty,
}: Props): JSX.Element => {
  const [quantity, setQuantity] = useState<number>(defaultQty);
  const [type, setType] = useState('');

  useEffect(() => {
    onQuantityChange(quantity, type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [quantity, type]);

  const increment = useCallback(() => {
    setType('increment');
    setQuantity(quantity + 1);
  }, [quantity]);

  const decrement = useCallback(() => {
    setType('decrement');
    if (quantity > 0) {
      setQuantity(quantity - 1);
    }
  }, [quantity]);

  return (
    <div className={styles.extrasCounter}>
      <p>{label}</p>
      <div className={styles.counter}>
        <Button
          disabled={quantity === min}
          type="button"
          variant="link"
          onClick={decrement}>
          -
        </Button>
        <div className={styles.counterValue}>{quantity}</div>
        <Button
          disabled={quantity === max}
          type="button"
          variant="link"
          onClick={increment}>
          +
        </Button>
      </div>
    </div>
  );
};

ExtrasCounter.defaultProps = {
  min: 0,
  max: 100,
};

export default ExtrasCounter;
