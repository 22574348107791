import React, { FunctionComponent, PropsWithChildren } from 'react';

type Props = {
  className?: string;
  collapseAt?: 'sm' | 'md' | 'lg' | 'xl';
};

const ContentSection: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  className,
  collapseAt,
  ...attributes
}: PropsWithChildren<Props>) => {
  return (
    <section
      className={`contentSection ${
        collapseAt ? `contentSection-${collapseAt}` : ''
      } ${className}`}
      {...attributes}>
      {children}
    </section>
  );
};

export default ContentSection;
