import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Layout } from 'src/components/layout-wrapper/Layout';
import { IAppState } from 'src/store';
import colors from 'src/styles/equalution/colors';

const NoActivePlan = () => {
  const history = useHistory();
  const { user } = useSelector((state: IAppState) => state.auth);
  return (
    <Layout>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          flex: 1,
          width: '100%',
          maxWidth: '320px',
          margin: '0 auto',
        }}>
        <Typography
          sx={{ fontSize: '24px', fontWeight: 700, marginTop: '20px' }}>
          Hi {user?.firstName || 'Guest'}!
        </Typography>

        <Typography
          sx={{ fontSize: '18px', fontWeight: 600, margin: '20px 0px' }}>
          No active program
        </Typography>
        <Box
          sx={{
            border: `1px solid ${colors.SALAD}4D`,
            borderRadius: '20px',
            padding: '20px',
            backgroundColor: colors.OFF_WHITE,
          }}>
          <Typography sx={{ fontSize: '16px', fontWeight: 400 }}>
            Help us capture your latest health goals, body data and lifestyle so
            we can personalise a program for your body’s needs.
          </Typography>
          <Button
            variant="contained"
            onClick={() => history.replace('/quiz')}
            size="large"
            sx={{
              textTransform: 'none',
              borderRadius: '100px',
              marginTop: 4,
              width: '330px',
              height: '55px',
              alignSelf: 'center',
              marginBottom: 2,
            }}>
            Create my personalised program
          </Button>
        </Box>
      </Box>
    </Layout>
  );
};

export default NoActivePlan;
