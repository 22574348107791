import React, { FC } from 'react';
import { Box, ButtonBase } from '@mui/material';
import styles from './option-button.module.scss';

interface OptionButtonProps {
  title: string;
  description?: string;
  isSelected: boolean;
  onPress?: (text: string) => void;
}

export const OptionButton: FC<OptionButtonProps> = ({
  title,
  description,
  isSelected,
  onPress,
}) => {
  return (
    <Box className={`${styles.container} ${isSelected && styles.selected}`}>
      <ButtonBase
        className={styles.buttonStyle}
        onClick={() => onPress?.(title)}
        disableRipple>
        <Box className={`${description ? styles.title : styles.onlyTitle}`}>
          {title}
        </Box>
        {description ? (
          <Box className={styles.subTitle}>{description}</Box>
        ) : null}
      </ButtonBase>
    </Box>
  );
};
