/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC, useEffect, useRef, useState } from 'react';
import { Box } from '@mui/material';
import { Alert, Button, Form } from 'reactstrap';
import { Field, Formik, FormikProps } from 'formik';
import { useSelector } from 'react-redux';
import { Coupon } from '../../../pages/deprecated/payment/types';
import styles from './promo-code.module.scss';
import warning from 'src/assets/icons/warning.svg';
import PromoInput from './promo-code-input';
import paymentAPI from '../../../pages/deprecated/payment/api';
import { IAppState } from '../../../store';
import { validateCoupons } from 'src/pages/signup/signup';
import GenerateCouponText from './promo-code-text';
import { EVENTS, track } from 'src/util/track';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { setCouponObj } from 'src/store/signup';
import { projectCouponCode } from 'src/pages/constants';

type PromoCodeProps = {
  priceId: string;
  coupon: Coupon | undefined;
  setCoupon: (coupon: Coupon | undefined) => void;
  finalPrice: number;
  productId?: string;
  futureCommencement?: boolean;
  setFutureCommencement?: (futureCommencement: boolean) => void;
  prefillCode?: string;
  isFromSignUp?: boolean;
};

interface FormInputs {
  couponCode: string;
}

const PromoCode: FC<PromoCodeProps> = ({
  priceId,
  productId = '',
  coupon,
  setCoupon,
  futureCommencement,
  setFutureCommencement = () => {},
  prefillCode,
  isFromSignUp,
  finalPrice,
}: PromoCodeProps) => {
  const user = useSelector((state: IAppState) => state.auth?.user);
  const { selectedPlanId, isFitherProjectUser } = useAppSelector(
    state => state.signUp
  );
  const formikRef: any = useRef<FormikProps<any>>(null);

  const [errorMessage, setErrorMessage] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (coupon) {
      resetCoupon();
    }
  }, [selectedPlanId]);
  useEffect(() => {
    if (coupon === undefined && !isFitherProjectUser) {
      resetCoupon();
    }
  }, [coupon, isFitherProjectUser]);

  useEffect(() => {
    const submitCoupon = async () => {
      if (isFitherProjectUser && user) {
        await submitForm({ couponCode: projectCouponCode }, null);
      }
    };
    setTimeout(() => submitCoupon(), 500); //wait till first initial payment-intent get completed
  }, [isFitherProjectUser, user]);

  const resetCoupon = () => {
    setCoupon(undefined);
    dispatch(setCouponObj({}));
    return formikRef?.current?.props?.onReset();
  };

  const submitForm = async (values: FormInputs, actions: any) => {
    setLoading(true);
    setErrorMessage('');
    if (!user) {
      return;
    }

    try {
      const response = await paymentAPI.get('stripe/coupons', {
        params: {
          id: values.couponCode.toLowerCase().trim(),
          email: user.email,
        },
      });

      const error = validateCoupons(
        priceId,
        response?.data?.metadata,
        productId,
        response?.data?.applies_to?.products,
        response.data.restrictions,
        finalPrice
      );
      if (error) {
        return setErrorMessage(error);
      }

      if (!response?.data?.id) {
        return setErrorMessage(response.data.message);
      }

      setCoupon(response.data);
      dispatch(setCouponObj(response.data));

      // set event for apply button
      track(EVENTS.promoCode, {
        email: user.email,
        couponId: response.data.id,
        couponName: response.data.name,
        couponPercentOff: response.data.percentOff,
        couponAmountOff: response.data.amountOff,
        couponDuration: response.data.duration,
      });
      if (futureCommencement) {
        setFutureCommencement(true);
      }
    } catch (e) {
      const error = (e as any)?.response.data.message;
      setErrorMessage(error || "Couldn't find that coupon");
    } finally {
      setLoading(false);
    }

    actions?.setSubmitting(false);
  };

  return (
    <div className={styles.promoCode}>
      <Formik
        onSubmit={submitForm}
        initialValues={{ couponCode: prefillCode || '' }}>
        {(formikProps): JSX.Element => (
          <>
            <Form
              noValidate
              onSubmit={formikProps.handleSubmit}
              onReset={formikProps.handleReset}
              ref={formikRef}
              style={{
                background: isFromSignUp ? 'white' : 'transparent',
                borderBottom: !isFromSignUp ? '2px solid #1E202E' : 'none',
                padding: isFromSignUp ? '0px 5%' : '0px',
                borderRadius: isFromSignUp ? '5px' : '0px',
                width: isFromSignUp ? '90%' : '100%',
              }}>
              <Field
                name="couponCode"
                placeholder="Enter promo code"
                component={PromoInput}
                className={styles.input}
                onKeyUp={(): void => {
                  setErrorMessage('');
                  setCoupon(undefined);
                  dispatch(setCouponObj({}));
                }}
              />

              {!isFitherProjectUser && (
                <Button
                  type="submit"
                  disabled={
                    formikProps.isSubmitting ||
                    !user ||
                    !formikProps.values.couponCode
                  }
                  className={styles.button}>
                  Apply
                </Button>
              )}
            </Form>
            {!!coupon &&
              errorMessage === '' &&
              !loading &&
              (!coupon.name.toLowerCase().includes('free week') ? (
                <Alert color="success">
                  <GenerateCouponText
                    coupon={coupon}
                    couponCode={formikProps.values.couponCode}
                  />
                </Alert>
              ) : (
                <Alert color="success">
                  <div>
                    Promo code FREE WEEK applied. FREE WEEK applied to package,
                    first payment in 7 days.
                  </div>
                </Alert>
              ))}
            {errorMessage && (
              <Box className={styles.warningContainer}>
                <img
                  src={warning}
                  alt="warning"
                  className={styles.warningIcon}
                />
                <span className={styles.errorText}>{errorMessage}</span>
              </Box>
            )}
          </>
        )}
      </Formik>
    </div>
  );
};

export default PromoCode;
