import React, { FunctionComponent, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import {
  PLANS,
  PLAN_ID,
  PLAN_NAME,
  PLAN_TAB,
  QUERY_PARAM_STANDARD_PLAN_ONLY,
} from 'src/pages/constants';
import { getShowStandardPlan } from 'src/pages/signup/steps/subscription/subscription-popup-tabs';
import { IAppState } from 'src/store';
import {
  MealPlanVariant,
  Plan,
  Plans,
} from '../../../pages/deprecated/payment/types';
import styles from './plan-selector.module.scss';
import { useDecision } from '@optimizely/react-sdk';
import { getCurrencySymbol, isUserFromNewZealand } from 'src/lib/utils';

interface OwnProps {
  selectedPlan: string;
  setSelectedPlan: (id: string) => void;
  weeklyMealPlanQty: number;
  plans: Plans | undefined;
  planRequested?: string | null;
  planTabSelected?: number;
}

type Props = OwnProps;

const PlanSelector: FunctionComponent<Props> = ({
  selectedPlan,
  setSelectedPlan,
  weeklyMealPlanQty,
  plans,
  planRequested,
  planTabSelected,
}: Props) => {
  const [sortedPlans, setSortedPlans] = useState<Plan[]>([]);
  const [isInitialRender, setIsInitialRender] = useState(true);

  // Lite plan is same as Standard plan
  const [decision] = useDecision('show_standard_plan_web', {
    autoUpdate: true,
  });

  const user = useSelector((state: IAppState) => state.auth?.user);
  let userCurrentWeek: number;
  if (user?.current_checkin) {
    userCurrentWeek = user?.current_checkin;
  }
  const AVG_WEEKS_PER_MONTH = 4.35;
  const WEEKS_PER_MONTH = 4;

  const isNewZealandUser =
    isUserFromNewZealand() || (user?.country ?? '') === 'New Zealand';

  useEffect(() => {
    if (plans && isInitialRender) {
      setIsInitialRender(false);
      setSortedPlans(
        Object.values(plans).sort((a, b): number => {
          if (
            a.id.includes(PLANS.STANDARD_PLAN) &&
            !b.id.includes(PLANS.STANDARD_PLAN)
          ) {
            return -1;
          } else if (
            !a.id.includes(PLANS.STANDARD_PLAN) &&
            b.id.includes(PLANS.STANDARD_PLAN)
          ) {
            return 1;
          } else if (
            a.id.includes(PLANS.STANDARD_PLAN) &&
            b.id.includes(PLANS.STANDARD_PLAN)
          ) {
            return a.intervalCount - b.intervalCount;
          } else if (
            !a.id.includes(PLANS.STANDARD_PLAN) &&
            !b.id.includes(PLANS.STANDARD_PLAN) &&
            a.interval === 'week' &&
            b.interval !== 'week'
          ) {
            return a.intervalCount - b.intervalCount;
          } else {
            return -1;
          }
        })
      );
    }
  }, [plans, sortedPlans, isInitialRender]);

  const [availablePlans, setAvailablePlans] = useState<Plan[]>([]);
  useEffect(() => {
    let filteredPlans;
    const showStandardPlan = getShowStandardPlan(
      selectedPlan,
      user?.plan?.plan_id || 0,
      user?.country ?? ''
    );

    const showStandardPlanOnly =
      planRequested === QUERY_PARAM_STANDARD_PLAN_ONLY ||
      selectedPlan.includes(PLANS.STANDARD_PLAN) ||
      (user?.plan?.plan_id || 0) === PLAN_ID.STANDARD;

    if (weeklyMealPlanQty >= 1) {
      if (
        (showStandardPlan || showStandardPlanOnly) &&
        planTabSelected === PLAN_TAB.STANDARD
      ) {
        filteredPlans = sortedPlans.filter(plan =>
          PLAN_NAME.STANDARD_PLAN.includes(plan.id)
        );
      } else {
        filteredPlans = sortedPlans.filter(
          plan =>
            PLAN_NAME.PREMIUM_PLAN.includes(plan.id) ||
            PLAN_NAME.DAILY_TARGETS_ONLY_PLAN.includes(plan.id)
        );
      }
    } else {
      filteredPlans = sortedPlans.filter(plan =>
        PLAN_NAME.DAILY_TARGETS_ONLY_PLAN.includes(plan.id)
      );
    }
    setAvailablePlans([...filteredPlans].reverse());
  }, [
    decision.enabled,
    planRequested,
    planTabSelected,
    selectedPlan,
    sortedPlans,
    user,
    weeklyMealPlanQty,
  ]);

  const getPlanPrice = (
    plan: Plan,
    displayedPlanAmount: string,
    variant: MealPlanVariant
  ) => {
    if (plan.interval === 'month' && plan.intervalCount > 1) {
      return `${displayedPlanAmount}s`;
    }
    if (plan.interval === 'month' && plan.intervalCount <= 1) {
      return `${displayedPlanAmount}`;
    }
    if (plan.interval === 'week') {
      return `${
        getCurrencySymbol(isNewZealandUser) + variant.unitAmountPerWeek
      }/${plan.interval} - ${plan.interval}ly`;
    }
  };

  return (
    <div className={styles.plans}>
      {availablePlans.map((plan, index) => {
        const variant = plan.mealPlanVariants[weeklyMealPlanQty];
        if (!variant) {
          return undefined;
        }

        let variantAmount;
        if (!PLAN_NAME.STANDARD_PLAN.includes(plan.id)) {
          variantAmount = parseFloat(
            (variant.unitAmountPerWeek * AVG_WEEKS_PER_MONTH).toFixed(2)
          );
        } else {
          variantAmount = variant.unitAmountPerWeek * WEEKS_PER_MONTH;
        }

        const displayedPlanAmount = `${
          getCurrencySymbol(isNewZealandUser) + Math.round(variantAmount)
        }/${plan.interval} - ${plan.intervalCount} ${plan.interval}`;

        const planPrice = getPlanPrice(plan, displayedPlanAmount, variant);

        return (
          <div
            key={plan.id}
            className={`${styles.plan} ${
              plan.id === selectedPlan && styles.selected
            }`}
            onClick={(): void => setSelectedPlan(plan.id)}>
            <div className={styles.left}>
              <div className={styles.priceName}>{planPrice}</div>
              <div className={styles.price}>
                <span className={styles.big}>
                  {getCurrencySymbol(false)}
                  {PLAN_NAME.STANDARD_PLAN.includes(plan.id)
                    ? Number(plan.mealPlanVariants[1]?.unitAmount).toFixed(2)
                    : Number(variant?.unitAmount).toFixed(2)}
                </span>
                <span className={styles.weeklyPrice}>
                  {' '}
                  Billed every
                  {plan?.intervalCount > 1 ? (
                    <span className={styles.weeklyPrice}>
                      {' '}
                      {plan?.intervalCount} {plan?.interval}s
                    </span>
                  ) : (
                    <span className={styles.weeklyPrice}>
                      {' '}
                      {plan?.interval}
                    </span>
                  )}
                </span>
              </div>
              {PLAN_NAME.STANDARD_PLAN.includes(plan.id) &&
                userCurrentWeek < 8 && (
                  <div className={styles.weeklyPrice}>
                    In effect after completing your minimum 8 weeks
                  </div>
                )}
              {index === 0 && (
                <div className={styles.weeklyPrice}>
                  <span className={styles.bestValue}>Best value</span>
                </div>
              )}
            </div>
            <div className={styles.tick} />
          </div>
        );
      })}
    </div>
  );
};

export default PlanSelector;
