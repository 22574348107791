import { Box, Checkbox, Typography } from '@mui/material';
import React, { FC } from 'react';
import {
  PLAN_NAME_OBJ,
  getCurrencySymbol,
  getFullPlanObj,
  perCycleAmountBasedOnDuration,
} from 'src/lib/utils';
import { Plan } from 'src/pages/deprecated/payment/types';
import { BillingText } from 'src/pages/subscription-new/components/plan-detail';
import { useAppSelector } from 'src/store/hooks';
import colors from 'src/styles/equalution/colors';
import Fonts from 'src/styles/equalution/fonts';

interface PlanOptionButtonProps {
  plan: Plan;
  onPlanSelect?: (id: string) => void;
  isSelected?: boolean;
}
const PlanOptionButton: FC<PlanOptionButtonProps> = ({
  plan,
  onPlanSelect = () => {},
  isSelected,
}) => {
  const { availablePlans, addonWeek } = useAppSelector(state => state.signUp);
  const finalPlanObj = getFullPlanObj(availablePlans, plan.id, addonWeek);

  if (!finalPlanObj) {
    return null;
  }

  const intervalString = `${finalPlanObj.intervalCount} ${
    finalPlanObj.interval
  }${finalPlanObj.intervalCount > 1 ? 's' : ''}`;

  return (
    <Box
      display="flex"
      alignItems={'center'}
      justifyContent={'space-between'}
      flex={1}
      padding="16px"
      borderRadius="20px"
      onClick={() => onPlanSelect(plan.id)}
      sx={{
        cursor: 'pointer',
        border: `1.5px solid ${isSelected ? colors.SALAD : 'transparent'}`,
        transitionDuration: '0.3s',
        backgroundColor: colors.OFF_WHITE,
      }}>
      <Box width={'150px'}>
        <Typography
          sx={{
            fontFamily: Fonts.FontType.regular,
            fontSize: '22px',
            color: colors.SALAD,
            fontWeight: 800,
          }}>
          {
            PLAN_NAME_OBJ[
              finalPlanObj.intervalCount + ' ' + finalPlanObj.interval
            ]
          }
        </Typography>
        <Typography
          sx={{
            fontFamily: Fonts.FontType.regular,
            fontSize: '12px',
            color: colors.OFF_BLACK,
            fontWeight: 800,
            textTransform: 'uppercase',
            letterSpacing: '2px',
            textAlign: 'left',
          }}>
          {intervalString}
        </Typography>
      </Box>
      <Box
        display="flex"
        flex={1}
        alignItems={'center'}
        justifyContent={'space-between'}>
        <div>
          <Typography
            sx={{
              fontFamily: Fonts.FontType.regular,
              fontSize: '24px',
              color: colors.OFF_BLACK,
              fontWeight: 800,
              lineHeight: 1,
            }}
            variant="caption">
            {getCurrencySymbol() +
              perCycleAmountBasedOnDuration(
                finalPlanObj.perCycleAmount,
                finalPlanObj.intervalCount + ' ' + finalPlanObj.interval
              )}
          </Typography>

          <Typography
            variant="caption"
            sx={{
              fontFamily: Fonts.FontType.regular,
              fontSize: '16px',
            }}>
            /{finalPlanObj.interval}
          </Typography>
          <Typography
            sx={{
              fontFamily: Fonts.FontType.regular,
              fontSize: '12px',
              color: colors.OFF_BLACK,
              textAlign: 'left',
            }}>
            <BillingText selectedPlanId={plan.id} finalPlanObj={finalPlanObj} />
          </Typography>
        </div>
        <Checkbox checked={isSelected} disableRipple />
      </Box>
    </Box>
  );
};

export default PlanOptionButton;
