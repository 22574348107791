import React, { FunctionComponent, PropsWithChildren } from 'react';

interface OwnProps {
  className?: string;
}

type Props = OwnProps;

const ContentCardLabel: FunctionComponent<PropsWithChildren<Props>> = ({
  className,
  children,
}: PropsWithChildren<Props>) => {
  return <div className={`cardLabel ${className || ''}`}>{children}</div>;
};

export default ContentCardLabel;
