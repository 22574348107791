import React, { FormEvent, useEffect, useMemo, useState } from 'react';
import {
  Alert,
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Label,
} from 'reactstrap';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {
  PaymentMethod,
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../../components/content/loader';
import styles from './card-component.module.scss';
import useResponsiveFontSize from '../../../../components/payments/stripe/useResponsiveFontSize';
import paymentAPI from '../../../deprecated/payment/api';
import { IAppState } from '../../../../store';
import { EVENTS, track } from 'src/util/track';
import { Coupon } from 'src/pages/deprecated/payment/types';
import { TagData } from 'src/store/signup-workflow/types';
import WalletComponent from './wallet-component';
import AfterPayComponent from './afterpay-component';
import { Box } from '@mui/material';
import { useAppSelector } from 'src/store/hooks';
import colors from 'src/styles/equalution/colors';
import { setPaymentProcessing } from 'src/store/payment';
import { hideIntercomElement, isUserFromNewZealand } from 'src/lib/utils';

interface OwnProps {
  selectedPlan: string;
  setPaymentMethod: (paymentMethod: PaymentMethod) => void;
  handleSubmit: () => void;
  paymentMethod: PaymentMethod | undefined;
  priceId: string;
  planName: string;
  finalPrice: number;
  coupon: Coupon | undefined;
  isChallengeAccepted: boolean;
  tagId: number;
  challengeObject: TagData | null;
  futureCommencement: boolean;
  productId: string;
  setErrorMessage: (error: string) => void;
  errorMessage: string;
  setWalletPaymentIntentId: (paypalIntent: string) => void;
  popupVisible: boolean;
  noIntentAvailable: boolean;
  setNoIntentAvailable: (noIntentAvailable: boolean) => void;
}

const useOptions = (): any => {
  useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: '16px',
          color: '#1E202E',
          fontFamily: 'Lexend, sans-serif',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#E8594D',
        },
      },
      showIcon: true,
    }),
    []
  );

  return options;
};

const CardComponent = ({
  selectedPlan,
  setPaymentMethod,
  handleSubmit,
  priceId,
  finalPrice,
  planName,
  coupon,
  paymentMethod,
  isChallengeAccepted,
  tagId,
  challengeObject,
  futureCommencement,
  productId,
  setErrorMessage,
  errorMessage,
  setWalletPaymentIntentId,
  popupVisible,
  noIntentAvailable,
  setNoIntentAvailable,
}: OwnProps) => {
  // Redux Selectors
  const user = useSelector((state: IAppState) => state.auth?.user);
  const isPaymentProcessing = useSelector(
    (state: IAppState) => state.payment.isPaymentProcessing
  );
  const dispatch = useDispatch();
  const { isFitherProjectUser } = useAppSelector(state => state.signUp);
  const cardErrorMessageTimeout = undefined;
  const cvcErrorMessageTimeout = undefined;
  const expiryErrorMessageTimeout = undefined;

  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const [stripeCustomer, setCustomerData] = useState<any>(null);
  const [activeTab, setActiveTab] = useState(0);
  const [hideWallet, toggleHideWallet] = useState(false);

  const [cardErrorMessage, setCardErrorMessage] = useState<undefined | string>(
    undefined
  );
  const [cvcErrorMessage, setCvcErrorMessage] = useState<undefined | string>(
    undefined
  );
  const [expiryErrorMessage, setExpiryErrorMessage] = useState<
    undefined | string
  >(undefined);

  const [hasCard, setHasCard] = useState<boolean>(false);
  const [hasExpiry, setHasExpiry] = useState<boolean>(false);
  const [hasCVC, setHasCVC] = useState<boolean>(false);

  useEffect(() => {
    //create customer on load of the step
    if (user) {
      const createCustomer = async () => {
        const { data } = await paymentAPI.post('stripe/customer/create', {
          email: user.email,
          userID: user.id,
        });
        setCustomerData(data.customer);
      };
      createCustomer();
    }
  }, [user]);

  useEffect(() => {
    if (selectedPlan.includes('Weekly')) {
      setActiveTab(0);
    }
  }, [selectedPlan]);

  useEffect(() => {
    if (user && paymentMethod && !errorMessage) {
      handleSubmit();
    }
  }, [user, paymentMethod, errorMessage, handleSubmit]);

  useEffect(() => {
    toggleHideWallet(true); //this code is used to reload
    setTimeout(() => {
      toggleHideWallet(false);
    }, 1000);
  }, [coupon, selectedPlan]);

  const handleCardSubmit = async (
    event: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();

    setErrorMessage('');
    dispatch(setPaymentProcessing(true));

    if (!stripe || !elements) {
      return;
    }

    const cardElement = elements.getElement(CardNumberElement);
    if (!cardElement) {
      return;
    }

    try {
      if (!user) {
        console.log('User not found');
        return;
      }

      const { data } = await paymentAPI.post('stripe/customer/create', {
        email: user.email,
        userID: user.id,
      });

      const { paymentMethod, error } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      const defaultPaymentMethod: PaymentMethod =
        data.customer.invoice_settings.default_payment_method;
      if (
        defaultPaymentMethod === null ||
        defaultPaymentMethod?.card?.last4 !== paymentMethod?.card?.last4 ||
        defaultPaymentMethod?.card?.brand !== paymentMethod?.card?.brand ||
        defaultPaymentMethod?.card?.exp_month !==
          paymentMethod?.card?.exp_month ||
        defaultPaymentMethod?.card?.exp_year !== paymentMethod?.card?.exp_year
      ) {
        if (error) {
          console.log(error);
        }

        if (paymentMethod) {
          setPaymentMethod(paymentMethod);
          track(EVENTS.paymentAdded, {
            email: user.email,
            paymentMode: 'Card',
          });
        } else {
        }
      } else {
        setPaymentMethod(defaultPaymentMethod);
      }
    } catch (e) {
      dispatch(setPaymentProcessing(false));
      if (typeof e === 'string') {
        setErrorMessage(e);
      } else if (e instanceof Error) {
        setErrorMessage(e.message);
      }
    }
  };

  const errorMessageTimeoutHandler = (
    timeoutVariable: unknown,
    event:
      | StripeCardCvcElementChangeEvent
      | StripeCardNumberElementChangeEvent
      | StripeCardExpiryElementChangeEvent,
    errorMessageSetFunction: any
  ): void => {
    if (timeoutVariable) {
      clearTimeout(timeoutVariable as NodeJS.Timeout);
    }

    setTimeout(() => {
      if (
        !event.complete ||
        (typeof event.error !== 'undefined' && event.error.message)
      ) {
        errorMessageSetFunction(event.error ? event.error.message : undefined);
      } else {
        errorMessageSetFunction(undefined);
      }
    }, 500);
  };

  //To hide intercom chat on payment page - to help mobile users.
  useEffect(() => {
    setTimeout(() => {
      hideIntercomElement();
    }, 1000);
  }, []);

  return (
    <div className={styles.cardContainer}>
      {stripeCustomer && !hideWallet && (
        <WalletComponent
          priceId={priceId}
          finalPrice={finalPrice}
          planName={planName}
          coupon={coupon}
          isChallengeAccepted={isChallengeAccepted}
          tagId={tagId}
          challengeObject={challengeObject}
          futureCommencement={futureCommencement}
          productId={productId}
          stripeCustomer={stripeCustomer}
          setWalletPaymentIntentId={setWalletPaymentIntentId}
          popupVisible={popupVisible}
          isNoIntentAvailable={noIntentAvailable}
          setIsNoIntentAvailable={setNoIntentAvailable}
        />
      )}

      <div className={styles.paymentMethodsContainer}>
        <div
          className={`${styles.cardTabs} ${
            activeTab === 0 && styles.activeTab
          }`}
          onClick={() => {
            setActiveTab(0);
          }}>
          <div className={`${styles.creditCardIcon}`} />
          <div className={styles.creditText}>CREDIT OR DEBIT CARD</div>
        </div>
        {!selectedPlan.includes('Weekly') &&
          !isUserFromNewZealand() && //disable for NZ users until we find solution for supporting with NZ users
          !noIntentAvailable &&
          !isFitherProjectUser && (
            <div
              className={`${styles.cardTabs} ${
                activeTab === 1 && styles.activeTab
              }`}
              onClick={() => {
                setActiveTab(1);
              }}>
              <div className={`${styles.afterPayIcon}`}></div>
            </div>
          )}
      </div>
      {activeTab === 0 && (
        <Form
          onSubmit={handleCardSubmit}
          style={{
            display: isPaymentProcessing ? 'none' : 'block',
            textAlign: 'center',
          }}>
          <FormGroup className={cardErrorMessage ? 'is-invalid' : ''}>
            <div className="label-feedback">
              <Label>Card number</Label>
              <FormFeedback valid>Success</FormFeedback>
              <FormFeedback valid={false}>{cardErrorMessage}</FormFeedback>
            </div>
            <CardNumberElement
              options={options}
              className={`${styles.input} ${
                cardErrorMessage && styles.invalid
              }`}
              onChange={(event: StripeCardNumberElementChangeEvent): void => {
                errorMessageTimeoutHandler(
                  cardErrorMessageTimeout,
                  event,
                  (message: string) => {
                    if (event.complete) {
                      setHasCard(true);
                    } else {
                      setHasCard(false);
                      setCardErrorMessage(message);
                    }
                  }
                );
              }}
            />
          </FormGroup>
          <div className={styles.cardMetaDetails}>
            <div className={styles.cardExpiryDateContainer}>
              <FormGroup className={expiryErrorMessage ? 'is-invalid' : ''}>
                <div className="label-feedback">
                  <Label>Expiry date</Label>
                  <FormFeedback valid>Success</FormFeedback>
                  <FormFeedback valid={false}>
                    {expiryErrorMessage}
                  </FormFeedback>
                </div>
                <CardExpiryElement
                  options={options}
                  className={`${styles.input} ${
                    expiryErrorMessage && styles.invalid
                  }`}
                  onChange={(
                    event: StripeCardExpiryElementChangeEvent
                  ): void => {
                    errorMessageTimeoutHandler(
                      expiryErrorMessageTimeout,
                      event,
                      (message: string) => {
                        if (event.complete) {
                          setHasExpiry(true);
                        } else {
                          setHasExpiry(false);
                          setExpiryErrorMessage(message);
                        }
                      }
                    );
                  }}
                />
              </FormGroup>
            </div>
            <div className={styles.cardSecurityCodeContainer}>
              <FormGroup className={cvcErrorMessage ? 'is-invalid' : ''}>
                <div className="label-feedback">
                  <Label>Security code</Label>
                  <FormFeedback valid>Success</FormFeedback>
                  <FormFeedback valid={false}>{cvcErrorMessage}</FormFeedback>
                </div>
                <CardCvcElement
                  options={options}
                  className={`${styles.input} ${
                    cvcErrorMessage && styles.invalid
                  }`}
                  onChange={(event: StripeCardCvcElementChangeEvent): void => {
                    errorMessageTimeoutHandler(
                      cvcErrorMessageTimeout,
                      event,
                      (message: string) => {
                        if (event.complete) {
                          setHasCVC(true);
                        } else {
                          setHasCVC(false);
                          setCvcErrorMessage(message);
                        }
                      }
                    );
                  }}
                />
              </FormGroup>
            </div>
          </div>

          {errorMessage && <Alert color="danger">{errorMessage}</Alert>}

          {isFitherProjectUser && (
            <Box fontSize={'12px'} fontWeight={400} color={colors.OFF_BLACK}>
              Auto-renewal billing. Cancel anytime.
            </Box>
          )}

          <Button
            type="submit"
            size="md"
            disabled={
              !stripe ||
              isPaymentProcessing ||
              !hasCard ||
              !hasCVC ||
              !hasExpiry
            }
            className={styles.button}
            style={{
              ...(isFitherProjectUser && {
                borderRadius: '28px',
                height: '70px',
              }),
            }}>
            {isFitherProjectUser ? (
              <Box mt={'-8px'}>
                <Box fontSize={'20px'}>Finalise your program</Box>
                <Box fontSize={'14px'}>Redeem free month</Box>
              </Box>
            ) : (
              <Box>Finalise your program</Box>
            )}
          </Button>
        </Form>
      )}

      {activeTab === 1 && (
        <AfterPayComponent
          priceId={priceId}
          finalPrice={finalPrice}
          planName={planName}
          coupon={coupon}
          isChallengeAccepted={isChallengeAccepted}
          tagId={tagId}
          challengeObject={challengeObject}
          futureCommencement={futureCommencement}
          productId={productId}
          stripeCustomer={stripeCustomer}
          setWalletPaymentIntentId={setWalletPaymentIntentId}
        />
      )}

      {isPaymentProcessing ? <Loader className="" /> : <></>}
    </div>
  );
};

export default CardComponent;
