import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Alert, Button } from 'reactstrap';
import { PaymentMethod } from '@stripe/stripe-js';
import history from '../../lib/history';
import { checkAuthentication } from '../../lib/auth/actions';
import paymentAPI from '../deprecated/payment/api';
import styles from './signup.module.scss';
import Header from '../../components/header/header';
import Loader from '../../components/content/loader';
import ContentWrapper from '../../components/content/content-wrapper';
import ContentSection from '../../components/content/content-section';
import { Coupon, Plans } from '../deprecated/payment/types';
import { IAppState } from '../../store';
import SubscriptionStep from './steps/subscription/subscription-step';
import AccountStep from './steps/account/account-step';
import PaymentStep from './steps/payment/payment-step';
import Checkbox from '../../components/checkbox/checkbox';
import User from 'src/models/User';
import moment from 'moment';
import { getRecommendedSubscription } from 'src/lib/utils';
import { TagData, TagResponse } from 'src/store/signup-workflow/types';
import {
  DefaultPlanDescription,
  RecommendedPlanDescription,
} from 'src/pages/signup/components/PlanDescription';
import { EVENTS, track } from 'src/util/track';
import { checkQuizStatus } from 'src/services/quiz-service';

export const validateCoupons = (
  priceId: string,
  couponMetadata: any = {},
  productId?: string,
  couponSupportingProducts?: Array<string> | undefined,
  restrictions: any = {},
  finalPrice = 0
): string | undefined => {
  if (
    restrictions?.minimum_amount &&
    finalPrice * 100 < restrictions?.minimum_amount
  ) {
    return `This coupon is invalid for this subscription`;
  }

  // check if coupon is restricted to any specific product
  if (
    productId &&
    !!couponSupportingProducts?.length &&
    !couponSupportingProducts.includes(productId)
  ) {
    return 'This coupon is invalid for this subscription';
  }

  if (!couponMetadata.priceId) {
    return;
  }

  const priceIds = (couponMetadata?.priceId || '')
    .split(',')
    .map((c: string) => c.trim());
  // If the priceId is not in the metadata.priceIds list show error
  if (priceIds.includes(priceId) === false) {
    return (
      couponMetadata.priceIdError ||
      'This coupon is invalid for this subscription'
    );
  }
};

interface SubscriptionType {
  user: User;
  paymentMethod: PaymentMethod;
  priceId: string;
  coupon: Coupon | undefined;
  isChallengeAccepted: boolean;
  totalPrice: string;
  futureCommencement: boolean;
  tagId: number;
  productId: string;
  challengeObject: TagData | null;
  walletPaymentIntentId: string;
  noIntentAvailable: boolean;
}
const StartSubscription = async (props: SubscriptionType) => {
  const {
    user,
    paymentMethod,
    priceId,
    coupon,
    isChallengeAccepted,
    totalPrice,
    futureCommencement,
    tagId,
    productId,
    challengeObject,
    walletPaymentIntentId,
    noIntentAvailable,
  } = props;
  const errorMsg = validateCoupons(priceId, coupon?.metadata);
  if (errorMsg) {
    return { errorMsg };
  }

  // Create Customer
  const { data } = await paymentAPI.post('stripe/customer/create', {
    email: user.email,
    userID: user.id,
  });

  let quizFinalStatus = false;

  //checking quiz status before final payment
  const quizStatusRes = await checkQuizStatus({ email: user.email });
  if (quizStatusRes.status === 200) {
    if (quizStatusRes?.data?.data) {
      quizFinalStatus = true;
    }
  }

  let purchaseInvoicePayload: any = {
    paymentMethodID: paymentMethod.id,
    customerID: data.customer?.id,
    productId: productId,
    priceIDs: [priceId],
    promoID: coupon?.id || null,
    userID: user.id,
    isChallenge: !!isChallengeAccepted,
    paymentIntentId: walletPaymentIntentId,
    isCardPayment: true, //make it true only if is card payment
    futureCommencement,
    isQuiz: quizFinalStatus, // from web, this will be true
  };
  if (!!isChallengeAccepted && challengeObject?.id) {
    purchaseInvoicePayload.challenge_ids = [challengeObject.id];
  }

  // Process Payment
  try {
    if (noIntentAvailable) {
      await paymentAPI.post('stripe/invoice/purchase', purchaseInvoicePayload);
    } else {
      await paymentAPI.post(
        'stripe/invoice/purchase/wallets',
        purchaseInvoicePayload
      );
    }
  } catch (error) {
    throw error;
  }

  // Update Google Analytics
  if (user.status !== 'PROSPECT') {
    history.push('/confirmation?ref=TransactionComplete', {
      tagId,
      futureCommencement,
      priceId,
      coupon,
      totalPrice,
    });

    track(EVENTS.purchase, {
      currency: 'AUD',
      value: totalPrice,
      purchaseComplete: 'ClientRenewal',
      priceIDs: [priceId],
    });
  } else {
    history.push('/confirmation?ref=TransactionComplete', {
      tagId,
      futureCommencement,
      priceId,
      coupon,
      totalPrice,
    });
    track(EVENTS.purchase, {
      currency: 'AUD',
      value: totalPrice,
      purchaseComplete: 'ClientNewPurchase',
      priceIDs: [priceId],
    });
  }
};

const SignUp = (): JSX.Element => {
  const dispatch = useDispatch();
  // Redux Selectors
  const user = useSelector((state: IAppState) => state.auth?.user);
  const isAuthenticated = useSelector(
    (state: IAppState) => state.auth?.isAuthenticated
  );

  // IsCollapsed
  const [collapsed, setCollapsed] = useState<boolean>(false);

  // Subscription ContentCard
  const [productId, setProductId] = useState<string>('');
  const [selectedPlan, setSelectedPlan] = useState<string>('');
  const [priceId, setPriceId] = useState<string>('');
  const [previouslySelectedPlan, setPreviouslySelectedPlan] =
    useState<string>('');
  const [errorMessage, setErrorMessage] = useState<string>('');

  // Payment ContentCard
  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>();
  const [totalPrice, setTotalPrice] = useState<string>('0');

  // Discount Code ContentCard
  const [coupon, setCoupon] = useState<Coupon | undefined>();

  // Terms and Conditions
  const [agreed, setAgreed] = useState<boolean>(false);

  // 8 week challenge
  const [accept8WeekChallenge, setAccept8WeekChallenge] = useState(false);

  // step number
  const [stepNumber, setStepNumber] = useState<number>(0);

  const [showRecommendedPlan, toggleRecommendedPlan] = useState(false);
  const [futureCommencement, setFutureCommencement] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [isInitialRender, setIsInitialRender] = useState(true);
  const [weeklyMealPlanQty, setWeeklyMealPlanQty] = useState<number>(1);
  const [walletPaymentIntentId, setWalletPaymentIntentId] =
    useState<string>('');

  const [hideAccount, setHideAccount] = useState(false);
  const [availablePlans, setAvailablePlans] = useState<Plans>();
  const [challengeOption, setChallengeOption] = useState<TagData | null>(null);

  const [planRequest, setPlanRequest] = useState('');

  const tagId = parseInt(coupon?.metadata?.tagId || '');

  const searchParams = new URLSearchParams(window.location.search);
  const planRequestQueryParam = searchParams.get('planRequest');
  const scale = searchParams.get('scale');
  const challengeParam = searchParams.get('challenge');
  const feeling = searchParams.get('feeling');
  const support = searchParams.get('support');
  const country1 = searchParams.get('country');
  const country2 = searchParams.get('coutntry'); // this should be removed once we update the typeform field
  const country = country2 ?? country1;
  const [noIntentAvailable, setNoIntentAvailable] = useState(false);

  useEffect(() => {
    (async () => {
      const { data } = await paymentAPI.post<TagResponse>('/challengeOptIn');
      if (!data.data || !data.data.length) {
        return null;
      }
      setChallengeOption(data.data[0]);
    })();
  }, []);

  useEffect(() => {
    if (planRequestQueryParam) {
      sessionStorage.setItem(
        'subscriptions',
        JSON.stringify({
          planRequestExpiresAt: moment().add(30, 'minute').unix(),
          planRequest: planRequestQueryParam,
        })
      );
      setPlanRequest(planRequestQueryParam);
    } else {
      const sessionObject = JSON.parse(
        sessionStorage.getItem('subscriptions') || '{}'
      );
      if (
        sessionObject.planRequestExpiresAt &&
        moment().isBefore(moment.unix(sessionObject.planRequestExpiresAt))
      ) {
        setPlanRequest(sessionObject.planRequest);
      }
    }
  }, [planRequestQueryParam]);

  useEffect(() => {
    //logic to handle query params for quiz logic and select subscription
    if (!!support && !!scale && !!challengeParam && !!feeling && !!country) {
      const recommendedSubscription = getRecommendedSubscription(
        scale,
        challengeParam,
        feeling,
        support,
        country,
        availablePlans
      );
      if (!!recommendedSubscription) {
        setSelectedPlan(recommendedSubscription);
        toggleRecommendedPlan(true);
      }
    }
  }, [support, scale, challengeParam, feeling, availablePlans, country]);

  useEffect(() => {
    const location = {
      pathname: '/account/profile',
      search: `planRequest=${planRequest}`,
    };

    if (
      isAuthenticated &&
      isInitialRender &&
      user?.status !== 'PROSPECT' &&
      user?.status !== 'INACTIVE' &&
      user?.status !== 'FINAL' &&
      user?.payment_provider === 'Stripe'
    ) {
      setIsInitialRender(false);
      history.push(location);
    } else {
      setHideAccount(true);
    }
  }, [isAuthenticated, user, isInitialRender, planRequest]);

  useEffect(() => {
    if (!selectedPlan.includes('Premium')) {
      setFutureCommencement(false); //reset future commencement if selected plan is not premium
    }
  }, [selectedPlan]);

  useEffect(() => {
    if (!user) setWeeklyMealPlanQty(1);
  }, [user]);

  const handleSubmit = async (): Promise<void> => {
    setIsSubmitting(true);
    try {
      if (user && paymentMethod) {
        const result = await StartSubscription({
          user,
          paymentMethod,
          priceId,
          coupon,
          isChallengeAccepted: accept8WeekChallenge,
          totalPrice,
          futureCommencement,
          tagId,
          productId,
          challengeObject: challengeOption,
          walletPaymentIntentId,
          noIntentAvailable,
        });
        if (result?.errorMsg) {
          setErrorMessage(result.errorMsg);
        }
      }
      dispatch(checkAuthentication());
    } catch (e) {
      console.error('EXCEPTION', e);
      setErrorMessage(
        'There was an error processing your payment, please try again or contact us'
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderLeftSideDescription = () => {
    return (
      <ContentSection
        className={`${styles.subscriptionIncludes} ${
          collapsed && styles.closed
        }`}
        collapseAt="md">
        {showRecommendedPlan ? (
          <>
            <div className={styles.planContainer}>
              <h3 className={styles.recommendedPlanText}>
                Your recommended program is <br />
                <span className={styles.recommendedPlanTextSpan}>
                  {selectedPlan}
                </span>
              </h3>
              <p className={styles.descriptionText}>
                Based on the information you provided in the quiz, we would
                recommend this plan to support your needs and goals.
              </p>
            </div>
            <div className={styles.leftContentCloseButton}>
              <h5 className={styles.subTitleStandardText}>
                What you’ll receive:
              </h5>
              <Button
                className={styles.close}
                onClick={(): void => setCollapsed(!collapsed)}
              />
            </div>
          </>
        ) : (
          <div className={styles.leftContentCloseButton}>
            <h3>Our Program Includes</h3>
            <Button
              className={styles.close}
              onClick={(): void => setCollapsed(!collapsed)}
            />
          </div>
        )}

        {showRecommendedPlan ? (
          <RecommendedPlanDescription
            selectedPlan={selectedPlan}
            styles={styles}
          />
        ) : (
          <DefaultPlanDescription planRequest={planRequest} styles={styles} />
        )}
      </ContentSection>
    );
  };

  const OptionsList = () => {
    return (
      <>
        <div className={styles.signUpStartDate}>
          JOIN THE{' '}
          <a
            className={styles.inlineLink}
            href={`https://joinequ.com/take-off-and-transform-challenge/`}
            target="_blank"
            rel="noreferrer">
            TAKE OFF & TRANSFORM CHALLENGE
          </a>{' '}
          starting 5 June
        </div>

        <Checkbox
          checked={accept8WeekChallenge}
          setChecked={() => {
            setAccept8WeekChallenge(!accept8WeekChallenge);
            if (accept8WeekChallenge) {
              setFutureCommencement(false);
            }
          }}
          styleStr="off-black">
          Opt in to challenge
        </Checkbox>
        {!!accept8WeekChallenge && !!selectedPlan.includes('Premium') && (
          <>
            {' '}
            <div className={styles.startSubscriptionText}>
              Want to only start your subscription at the challenge?
            </div>
            <div className={styles.signUpStartCheckboxes}>
              <Checkbox
                checked={futureCommencement}
                setChecked={setFutureCommencement}
                styleStr="off-black">
                Pay now, start on the challenge
              </Checkbox>
            </div>
          </>
        )}
        <div className={styles.horizontalRow}></div>
      </>
    );
  };

  return (
    <>
      <Header module="login" hideAccount={hideAccount} />

      <div className={styles.signup}>
        <ContentWrapper
          className={styles.wrapper}
          innerClassName={styles.innerWrapper}
          collapseAt="lg">
          {!isSubmitting ? (
            <div className={styles.rowWrapper}>
              {stepNumber === 0 ? (
                <>
                  {renderLeftSideDescription()}
                  <ContentSection collapseAt="lg">
                    <SubscriptionStep
                      selectedPlan={selectedPlan}
                      setSelectedPlan={setSelectedPlan}
                      setAvailablePlans={setAvailablePlans}
                      productId={productId}
                      previouslySelectedPlan={previouslySelectedPlan}
                      setPreviouslySelectedPlan={setPreviouslySelectedPlan}
                      setProductId={setProductId}
                      setPriceId={setPriceId}
                      completed={!!productId && !!priceId}
                      setTotalPrice={setTotalPrice}
                      setIntervals={() => undefined}
                      coupon={coupon}
                      setCoupon={setCoupon}
                      planRequest={planRequest}
                      setWeeklyMealPlanQty={setWeeklyMealPlanQty}
                      weeklyMealPlanQty={weeklyMealPlanQty}
                    />

                    <AccountStep completed={!!user} />

                    {challengeOption && <OptionsList />}

                    <Checkbox
                      checked={agreed}
                      setChecked={setAgreed}
                      styleStr="off-black">
                      By continuing you accept our{' '}
                      <a
                        href="https://joinequ.com/pages/privacy-policy"
                        target="_blank"
                        rel="noopener noreferrer">
                        privacy policy,{' '}
                      </a>
                      <a
                        href="https://joinequ.com/pages/terms-and-conditions"
                        target="_blank"
                        rel="noopener noreferrer">
                        terms and conditions
                      </a>
                      .
                    </Checkbox>

                    {errorMessage !== '' && (
                      <Alert color="danger">{errorMessage}</Alert>
                    )}

                    <Button
                      type="submit"
                      size="lg"
                      block
                      disabled={
                        !productId ||
                        !priceId ||
                        !user ||
                        !agreed ||
                        isSubmitting
                      }
                      onClick={() => setStepNumber(1)}
                      className={styles.button}>
                      Continue to payment
                    </Button>
                  </ContentSection>
                </>
              ) : (
                availablePlans && (
                  <PaymentStep
                    paymentMethod={paymentMethod}
                    setPaymentMethod={setPaymentMethod}
                    plans={availablePlans}
                    selectedPlan={selectedPlan}
                    planRequest={planRequest}
                    coupon={coupon}
                    priceId={priceId}
                    setPriceId={setPriceId}
                    productId={productId}
                    setCoupon={setCoupon}
                    futureCommencement={futureCommencement}
                    setFutureCommencement={setFutureCommencement}
                    setNoIntentAvailable={setNoIntentAvailable}
                    setSelectedPlan={setSelectedPlan}
                    handleSubmit={handleSubmit}
                    isChallengeAccepted={accept8WeekChallenge}
                    tagId={tagId}
                    challengeObject={challengeOption}
                    setErrorMessage={setErrorMessage}
                    errorMessage={errorMessage}
                    setWeeklyMealPlanQty={setWeeklyMealPlanQty}
                    weeklyMealPlanQty={weeklyMealPlanQty}
                    noIntentAvailable={noIntentAvailable}
                    setWalletPaymentIntentId={setWalletPaymentIntentId}
                  />
                )
              )}
            </div>
          ) : (
            <Loader />
          )}
        </ContentWrapper>
      </div>
    </>
  );
};

export default SignUp;
