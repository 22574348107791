import colors from './colors';

const theme = {
  ACC_LIGHT: colors.SALAD, // main ACC, call to action buttons
  ACC_DARK: colors.CUCUMBER, // ACC buttons

  ACC_ALT: colors.ORANGE_HALF, // non-call to action buttons (close to background color)

  BG_DARK: colors.CREAM, // rare use Dark (cream)
  OFF_WHITE: colors.CREAM_HALF, // common use for most backgrounds Medium Cream
  BG_LIGHT: colors.OFF_WHITE, // sections, etc (Light Creme)
  BG_WHITE: colors.WHITE,

  TEXT_PRIMARY: colors.OFF_BLACK, // Grey 80
  TEXT_SECONDARY: colors.BLUEBERRY, // Grey 60
  TEXT_DESCRIPTION: colors.BLUEBERRY_HALF, // Grey 60
  TEXT_DISABLED: colors.BLUEBERRY_HALF, // Grey 40 use with 30% opacity
  TEXT_LINK: colors.SALAD, // Red
  TEXT_WHITE: colors.WHITE, // White

  POSITIVE: colors.SALAD,
  NEGATIVE: colors.SALAD,
  WARNING: colors.ORANGE_HALF,
  INACTIVE: colors.BLUEBERRY_HALF,

  DIVIDER: colors.CREAM,
  SHEET_TOP_ICON: colors.BLUEBERRY,
};

export default theme;
