import React, { FC, ReactElement } from 'react';
import { Box, Button, Typography } from '@mui/material';

interface ReinforcerProps {
  backgroundImage: string;
  backgroundImageSmall: string;
  buttonClick: () => void;
  buttonText: string;
  titleText: string;
  subtitleText?: string;
  chatComponent?: ReactElement<any>;
}

export const Reinforcer: FC<ReinforcerProps> = props => {
  const {
    backgroundImage,
    backgroundImageSmall,
    buttonClick,
    buttonText,
    titleText,
    subtitleText,
    chatComponent = <></>,
  } = props;
  return (
    <Box
      sx={{
        height: 'calc(100vh - 100px)',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
      }}>
      <Box
        sx={{
          display: 'flex',
          flex: { xs: 2, md: 1 },
          backgroundImage: {
            xs: `${backgroundImageSmall}`,
            md: `${backgroundImage}`,
          },
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          marginTop: '-100px',
        }}
      />
      <Box
        sx={{
          display: 'flex',
          flex: { xs: 3, md: 1 },
          flexDirection: 'column',
          alignItems: { xs: 'flex-start', md: 'center' },
          padding: '5vw',
          maxWidth: '420px',
          alignSelf: 'center',
        }}>
        <Typography sx={{ fontSize: '26px', fontWeight: '700' }}>
          {titleText}
        </Typography>
        {subtitleText && (
          <Typography
            sx={{
              fontSize: '20px',
              lineHeight: '24px',
              marginTop: 2,
            }}>
            {subtitleText}
          </Typography>
        )}
        {chatComponent}
        <Button
          variant="contained"
          onClick={buttonClick}
          size="large"
          sx={{
            textTransform: 'none',
            borderRadius: '100px',
            marginTop: 4,
            width: '330px',
            height: '55px',
            alignSelf: 'center',
            marginBottom: 2,
          }}>
          {buttonText}
        </Button>
      </Box>
    </Box>
  );
};
