import React from 'react';
import { PLANS, QUERY_PARAM_STANDARD_PLAN_ONLY } from 'src/pages/constants';
import { Plans, Coupon } from '../../../deprecated/payment/types';
import styles from './subscription-preview.module.scss';
import { getCurrencySymbol } from 'src/lib/utils';

interface OwnProps {
  selectedPlan: string;
  weeklyMealPlanQty: number;
  plans: Plans | undefined;
  coupon: Coupon | undefined;
  planRequest: string;
}

const SubscriptionPreview = ({
  selectedPlan,
  weeklyMealPlanQty,
  plans,
  coupon,
  planRequest,
}: OwnProps) => {
  if (selectedPlan === '' || !plans) {
    return <div className={styles.noneSelected}>No plan selected yet.</div>;
  }

  const plan = plans[selectedPlan];
  const variant = plan?.mealPlanVariants[weeklyMealPlanQty];

  let discountAmount = 0;

  if (coupon) {
    if (coupon.amountOff > 0) {
      discountAmount = coupon.amountOff / 100;
    } else if (coupon.percentOff > 0) {
      discountAmount =
        parseFloat(variant?.unitAmount) * (coupon.percentOff / 100);
    }
  }

  const finalPrice =
    parseFloat(variant?.unitAmount) - discountAmount > 0
      ? parseFloat(variant?.unitAmount) - discountAmount
      : 0;

  const findPlanName = (planId: string) => {
    if (planId.includes('Standard')) {
      return PLANS.STANDARD_PLAN;
    }
    if (planId.includes('Premium')) {
      return PLANS.PREMIUM_PLAN;
    }
    if (planId.includes('Daily')) {
      return PLANS.DAILY_TARGETS_ONLY_PLAN;
    }
  };

  const showStandardPlanOnly = planRequest === QUERY_PARAM_STANDARD_PLAN_ONLY;

  return (
    <div className={styles.subscriptionPreview}>
      {coupon && coupon.duration.toLowerCase() === 'once' ? (
        <>
          <div className={styles.price}>
            <span className={styles.big}>
              {getCurrencySymbol() +
                (finalPrice < 0 ? '0.00' : finalPrice.toFixed(2))}
            </span>{' '}
            first{' '}
            {plan?.intervalCount > 1
              ? `${plan?.intervalCount} ${plan?.interval}s`
              : `${plan?.interval}`}
          </div>
          <div className={styles.weeklyPrice}>
            Normally{' '}
            <span className={styles.red}>
              {getCurrencySymbol() + parseFloat(variant?.unitAmount).toFixed(2)}
            </span>
          </div>
        </>
      ) : coupon && coupon.duration.toLowerCase() !== 'once' ? (
        <>
          <div className={styles.price}>
            <span className={styles.big}>
              {findPlanName(plan.id)} - {getCurrencySymbol()}
              {finalPrice < 0 ? '0.00' : finalPrice.toFixed(2)}
            </span>{' '}
            {' every '}
            {plan?.intervalCount > 1
              ? `${plan?.intervalCount} ${plan?.interval}s`
              : plan?.interval}
          </div>
          <div className={styles.weeklyPrice}>
            Normally{' '}
            <span className={styles.red}>
              {getCurrencySymbol() + parseFloat(variant?.unitAmount).toFixed(2)}
            </span>
            {' every '}
            {plan?.intervalCount > 1
              ? `${plan?.intervalCount} ${plan?.interval}s`
              : plan?.interval}
          </div>
        </>
      ) : (
        <>
          <div className={styles.price}>
            <span className={styles.big}>
              {showStandardPlanOnly
                ? `${getCurrencySymbol() + variant?.unitAmount}`
                : `${findPlanName(plan.id)} - ${
                    getCurrencySymbol() + variant?.unitAmount
                  }`}
            </span>{' '}
            {' every '}
            {plan?.intervalCount > 1
              ? `${plan?.intervalCount} ${plan?.interval}s`
              : plan?.interval}
          </div>
          <div className={styles.weeklyPrice}>
            Billed{' '}
            <span className={styles.red}>
              {getCurrencySymbol() +
                (finalPrice < 0 ? '0.00' : finalPrice.toFixed(2))}
            </span>
            {' every '}
            {plan?.intervalCount > 1
              ? `${plan?.intervalCount} ${plan?.interval}s`
              : plan?.interval}
          </div>
        </>
      )}
      {coupon ? (
        <div className={styles.weeklyPrice}>
          Promo code applied to your{' '}
          {coupon.duration.toLowerCase() !== 'once'
            ? `subscription`
            : `first payment`}
          .
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default SubscriptionPreview;
