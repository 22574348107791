import { AxiosResponse } from 'axios';
import paymentAPI from 'src/pages/deprecated/payment/api';
import { QuizQuestionersRequest } from 'src/pages/quiz/types';

export const submitOnboardingQuiz = async (
  data: QuizQuestionersRequest
): Promise<AxiosResponse> => {
  try {
    return await paymentAPI.post('/onboarding/save-quiz', data);
  } catch (error) {
    console.error('Could not apply promo code', error);
    return Promise.reject(error);
  }
};

export const checkQuizStatus = async (data: {
  email: string;
}): Promise<AxiosResponse> => {
  try {
    return await paymentAPI.post('/onboarding/check-quiz', data);
  } catch (error) {
    console.error('Could not apply promo code', error);
    return Promise.reject(error);
  }
};

export const trackCheckoutService = async (data: {
  email: string;
}): Promise<AxiosResponse> => {
  try {
    return await paymentAPI.post('/onboarding/track-checkout', data);
  } catch (error) {
    console.error('Error while tracking checkout', error);
    return Promise.reject(error);
  }
};
