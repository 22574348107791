import React, { FC, useEffect, useState } from 'react';
import { Box, Stack } from '@mui/material';
import { Button } from 'reactstrap';

// components
import ContentSection from 'src/components/content/content-section';
import ContentWrapper from 'src/components/content/content-wrapper';
import Popup from 'src/components/content/popup/popup';
import PlanOptionButton from 'src/pages/payment-new/components/plan-option-button';
import CoachQuote from 'src/components/quote-box';
import MealAddons from '../subscription-new/components/meal-addons/MealAddons';
import SubscriptionTabs from './components/subscription-tabs';

// types & utils
import { isUserFromAustralia, useViewport } from 'src/lib/utils';
import User from 'src/models/User';
import { PLAN_NAME, PlanType, USER_STATUS } from 'src/pages/constants';

// styles & assets
import styles from './subscription-plan-popup.module.scss';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import {
  setAddonWeek,
  setAvailablePlansWithoutDefaultCheck,
  setSelectedPlanId,
} from 'src/store/signup';
import { EVENTS, track } from 'src/util/track';

interface SubscriptionPopupProps {
  setPopupVisible: (val: boolean) => void;
  user: User | undefined;
  planRequest: string | null;
}

const SubscriptionPlanPopup: FC<SubscriptionPopupProps> = ({
  setPopupVisible,
  user,
  planRequest,
}) => {
  const { isMobile } = useViewport();
  const { availablePlans, selectedPlanId, addonWeek, allPlansSorted } =
    useAppSelector(state => state.signUp);
  const [planId, setPlanId] = useState(selectedPlanId);
  const dispatch = useAppDispatch();

  const planType = selectedPlanId.toLowerCase().includes('standard')
    ? 'standard'
    : 'premium';
  const [planTabSelected, setPlanTabSelected] = useState<PlanType>(planType);

  useEffect(() => {
    let filteredPlans;
    if (planTabSelected && planTabSelected === 'standard') {
      filteredPlans = allPlansSorted.filter(plan =>
        PLAN_NAME.STANDARD_PLAN.includes(plan.id)
      );
    } else {
      filteredPlans = allPlansSorted.filter(
        plan =>
          PLAN_NAME.PREMIUM_PLAN.includes(plan.id) ||
          PLAN_NAME.DAILY_TARGETS_ONLY_PLAN.includes(plan.id)
      );
    }
    dispatch(
      setAvailablePlansWithoutDefaultCheck([...filteredPlans].reverse())
    );
  }, [allPlansSorted, planTabSelected, dispatch]);

  const onConfirm = () => {
    setPopupVisible(false);
    dispatch(setSelectedPlanId(planId));
    track(EVENTS.changePlan, {
      currentPlan: selectedPlanId,
      newPlan: planId,
      page: 'payment-page',
    });
  };

  useEffect(() => {
    if (
      user?.status !== USER_STATUS.INACTIVE &&
      (planRequest?.toLowerCase().includes('lite') ||
        planRequest?.toLowerCase().includes('standard'))
    ) {
      setPlanTabSelected('premium');
    }
  }, [planRequest, user]);

  return (
    <Popup>
      <ContentWrapper className={styles.background} collapseAt="sm">
        <ContentSection className={styles.subscriptionPopup} collapseAt="sm">
          <Stack gap={'16px'}>
            {!isMobile && (
              <Button
                className={styles.close}
                onClick={(): void => {
                  setPopupVisible(false);
                }}
              />
            )}

            <Box
              fontSize={isMobile ? '20px' : '29px'}
              my={'16px'}
              textAlign={'center'}
              fontWeight={800}>
              Choose your program
            </Box>

            {isUserFromAustralia() && (
              <SubscriptionTabs
                selectedTab={planTabSelected}
                onTabClick={setPlanTabSelected}
              />
            )}
            <Box />
            {planTabSelected === 'standard' && (
              <CoachQuote position="top-right" />
            )}

            {availablePlans?.map(plan => (
              <PlanOptionButton
                key={plan.id}
                plan={plan}
                onPlanSelect={setPlanId}
                isSelected={plan.id === planId}
              />
            ))}

            {planTabSelected !== 'standard' && (
              <MealAddons
                addonWeek={addonWeek}
                setAddonWeek={val => dispatch(setAddonWeek(val))}
              />
            )}

            <Box />
            <Button block disabled={!selectedPlanId} onClick={onConfirm}>
              Change your program
            </Button>
          </Stack>
        </ContentSection>
      </ContentWrapper>
    </Popup>
  );
};

export default SubscriptionPlanPopup;
