import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider as ReduxProvider } from 'react-redux';
import Axios, { AxiosRequestConfig } from 'axios';
import TagManager from 'react-gtm-module';
import { decodeCookie } from './lib/cookie-parser';
import './assets/scss/global.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import configureStore from './store/configureStore';

const windowUrl = window.location.search;
const params = new URLSearchParams(windowUrl);
const keyValues = Array.from(params.entries());
let mappedQueries = {};

for (const [key, value] of keyValues) {
  mappedQueries = {
    [key]: value,
    ...mappedQueries,
  };
}

const store = configureStore();
export type AppDispatch = typeof store.dispatch;

const apiDomain = process.env.REACT_APP_PAYMENT_API;
Axios.defaults.baseURL = apiDomain;
Axios.defaults.headers.common.Authorization = '';
Axios.interceptors.request.use(
  (config: AxiosRequestConfig): AxiosRequestConfig => {
    const cookie = decodeCookie();
    const interceptedConfig = config;
    interceptedConfig.headers = {
      Authorization: cookie?.token || '',
    };
    return interceptedConfig;
  }
);

TagManager.initialize({
  gtmId: process.env.REACT_APP_GA_TRACKING_ID ?? 'GTM-KPLMV6N',
  dataLayer: {},
});

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <ReduxProvider store={store}>
    <App />
  </ReduxProvider>
);

// If you want your app to work offline and load faster, you can update-subscription
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
