import React, { FunctionComponent, PropsWithChildren } from 'react';
import styles from './step-label.module.scss';

interface OwnProps {
  className?: string;
  completed?: boolean;
}

const StepLabel: FunctionComponent<PropsWithChildren<OwnProps>> = ({
  className,
  completed,
  children,
}) => {
  return (
    <div
      className={`${styles.stepLabel} ${
        completed && styles.completed
      } ${className}`}>
      {children}
    </div>
  );
};

export default StepLabel;
