import React, { FunctionComponent, PropsWithChildren } from 'react';
import { Button } from 'reactstrap';
import styles from './checkbox.module.scss';

interface OwnProps {
  checked: boolean;
  setChecked: (val: boolean) => void;
  disabled?: boolean;
  styleStr?: string;
  greenColored?: boolean;
}

const Checkbox: FunctionComponent<PropsWithChildren<OwnProps>> = ({
  checked,
  setChecked,
  children,
  disabled,
  styleStr,
  greenColored,
}) => {
  const toggleChecked = (): void => setChecked(!checked);
  let newStyle = styles.checkbox;
  if (styleStr) {
    newStyle = styles.checkbox_with_grey_text;
  }
  return (
    <div className={newStyle}>
      <Button
        onClick={toggleChecked}
        className={`${styles.input} ${checked && styles.checked} ${
          greenColored && styles.greenColor
        }`}
        disabled={disabled}
      />
      <div className={styles.text}>{children}</div>
    </div>
  );
};

export default Checkbox;
