import React, { FC, useCallback, useContext, useEffect, useMemo } from 'react';
import { Box, Stack, Typography } from '@mui/material';
import { TextInput } from 'src/components/text-input/TextInput';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { updateQuizData } from 'src/store/quiz';
import { useViewport } from 'src/lib/utils';
import { IAppState } from 'src/store';
import { submitOnboardingQuiz } from 'src/services/quiz-service';
import { ToastContext } from 'src/components/toast/toast';
import { useQuizTracking } from '../../hooks';
import { EVENTS, track } from 'src/util/track';
import { QuizQuestionersRequest } from '../../types';
import { Button } from 'reactstrap';

interface FormFieldTypes {
  firstName: string;
  lastName: string;
  email: string;
}

const EmailStep: FC = () => {
  const { isMobile } = useViewport();
  const user = useSelector((state: IAppState) => state.auth?.user);
  const { quizData, quizType } = useSelector((state: IAppState) => state.quiz);
  const { addToast } = useContext(ToastContext);
  const dispatch = useDispatch();
  const trackEvent = useQuizTracking();

  useEffect(() => {
    track(EVENTS.quizComplete, { quiz: quizType });
  }, [quizType]);

  const validationSchema = Yup.object({
    firstName: Yup.string().required('First Name is required'),
    lastName: Yup.string().required('Last Name is required'),
    email: Yup.string().email('Invalid email').required('Email is required'),
  });

  const initialValues: FormFieldTypes = {
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    email: user?.email || '',
  };

  // formik
  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit: async values => {
      await submitForm(values);
    },
  });

  const buttonDisabled = useMemo((): boolean => {
    if (
      Object.keys(formik.errors).length > 0 ||
      Object.values(formik.values).some(value => value === '')
    ) {
      return true;
    }
    return false;
  }, [formik]);

  const getErrors = (key: keyof FormFieldTypes): string | undefined => {
    if (formik.touched[key] && formik.errors[key]) {
      return formik.errors[key];
    }
  };

  const submitForm = useCallback(
    async (formValues: FormFieldTypes): Promise<void> => {
      const { firstName, lastName, email } = formValues;
      trackEvent('AccessResult', { firstName, lastName, email });
      try {
        let payloadObj: QuizQuestionersRequest = {
          FName: firstName,
          LName: lastName,
          email: email,
          goal_id: quizData.goal_id,
          target_weight: quizData?.target_weight || 0,
          problem_hitting_goal: quizData.problem_hitting_goal,
          low_intensity_days: quizData.low_intensity_days,
          high_intensity_days: quizData.high_intensity_days,
          activity_level: quizData.activity_level,
          sleep_hours: quizData.sleep_hours,
          water_intake: quizData.water_intake,
          stress_level: quizData.stress_level,
          interests: quizData.interests,
          goal_adherence_challenges: quizData.goal_adherence_challenges,
          meal_adherence_challenges: quizData.meal_adherence_challenges, // meal challenges
          height: parseInt(quizData.height.toString()) || 0,
          weight: quizData.weight,
          is_breastfeeding: quizData?.is_breastfeeding ? 1 : 0,
          breastfeeding_frequency: quizData.breastfeeding_frequency,
          dob: quizData.dob,
          located: quizData.located,
          heard_about_eq: quizData.heard_about_eq,
        };
        if (quizData?.gender) {
          payloadObj['gender'] = quizData.gender.toUpperCase(); // uppercase gender;
        }
        const submitResponse = await submitOnboardingQuiz(payloadObj);
        if (submitResponse.status === 200) {
          dispatch(
            updateQuizData({
              FName: firstName,
              LName: lastName,
              email: email,
              currentStep: 30,
            })
          );
        } else {
          addToast('Something went wrong. Please try again.', 'danger', 5000);
        }
      } catch (error) {
        addToast('Something went wrong. Please try again.', 'danger', 5000);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  return (
    <Box
      sx={{ display: 'flex', alignItems: 'center', flexDirection: 'column' }}>
      <Box sx={{ width: isMobile ? 300 : 380, textAlign: 'start' }}>
        <Typography
          sx={{
            fontSize: '22px',
            fontWeight: 600,
            lineHeight: '28px',
            marginTop: 4,
          }}>
          Almost there!
        </Typography>
        <Typography
          sx={{
            fontSize: '20px',
            fontWeight: 400,
            lineHeight: '28px',
            marginBottom: 3,
            marginTop: 2,
          }}>
          Enter your email to access your results and personalised plan.
        </Typography>
      </Box>

      <Stack
        gap={isMobile ? '32px' : '16px'}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <Stack sx={{ width: isMobile ? 300 : 380 }} gap={'20px'}>
          <TextInput
            id="email"
            label="Email"
            placeholder="john.smith@gmail.com"
            {...formik.getFieldProps('email')}
            errorText={getErrors('email')}
          />

          <TextInput
            id="firstName"
            label="First Name"
            placeholder="John"
            {...formik.getFieldProps('firstName')}
            errorText={getErrors('firstName')}
          />

          <TextInput
            id="lastName"
            label="Last Name"
            placeholder="Smith"
            {...formik.getFieldProps('lastName')}
            errorText={getErrors('lastName')}
          />
        </Stack>

        <Box
          sx={{
            paddingInline: isMobile ? 'unset' : '17px',
            width: '300px',
            marginTop: 3,
          }}>
          <Button
            type="submit"
            disabled={buttonDisabled || formik.isSubmitting}
            block
            onClick={() => formik.handleSubmit()}>
            Access my results!
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default EmailStep;
