import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React from 'react';
import AfterPay from './Afterpay';

const ConfirmAfterPay = () => {
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );

  return (
    <Elements stripe={stripePromise}>
      <AfterPay />
    </Elements>
  );
};

export default ConfirmAfterPay;
