import React, { useEffect } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import { BackButton } from 'src/components/back-button/BackButton';
import { Layout } from 'src/components/layout-wrapper/Layout';
import { LinearProgress } from 'src/components/linear-progress/LinearProgress';
import { Reinforcer } from 'src/components/reinforcer/Reinforcer';
// import reinforcer_bg from 'src/assets/images/reinforcer_bg.jpg';
// import reinforcer_bg_small from 'src/assets/images/reinforcer_bg_small.jpg';
import reinforcer_bg_2 from 'src/assets/images/reinforcer_bg_2.jpg';
import reinforcer_bg_small_2 from 'src/assets/images/reinforcer_bg_small_2.jpg';
import reinforcer_bg_4 from 'src/assets/images/reinforcer_bg_4.jpg';
import reinforcer_bg_small_4 from 'src/assets/images/reinforcer_bg_small_4.jpg';
import ProfileSection from './components/quize-steps/ProfileSection';
import { ReinforcerWeightGoal } from 'src/components/reinforcer/ReinforcerWeightGoal';
import { useDispatch, useSelector } from 'react-redux';
import { IAppState } from 'src/store';
import { updateQuizData } from 'src/store/quiz';
import LifestyleSection from './components/quize-steps/LifestyleSection';
import colors from 'src/styles/equalution/colors';
import GeneralSection from './components/quize-steps/GeneralSection';
import EmailStep from './components/quize-steps/EmailStep';
import PlanSummary from './components/quize-steps/PlanSummary';
import { handleEncodingURL } from 'src/lib/utils';
import { USER_STATUS } from '../constants';
import { useHistory } from 'react-router-dom';

const Quiz = () => {
  const { quizData } = useSelector((state: IAppState) => state.quiz);
  const user = useSelector((state: IAppState) => state.auth?.user);
  const [progressBar, setProgressBar] = React.useState(2); // initialised with 2 to show some starting progress bar status
  const isAuthenticated = useSelector(
    (state: IAppState) => state.auth?.isAuthenticated
  );
  const history = useHistory();
  const dispatch = useDispatch();

  // for checking quiz status
  if (window.location.href.indexOf('+') !== -1) {
    handleEncodingURL(); //if plus sign is present in the url meaning missed encoding it
  }

  useEffect(() => {
    const confirmMessage =
      'Are you sure you want to leave? Your data may be lost.';

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.returnValue = confirmMessage; // This message will be shown in the confirmation popup
      return confirmMessage;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);
  // navigate to account page is user is ACTIVE
  useEffect(() => {
    if (isAuthenticated && user?.status === USER_STATUS.ACTIVE) {
      history.replace('/account');
    } else {
      if (user) {
        dispatch(
          updateQuizData({
            FName: user?.firstName,
            LName: user?.lastName,
            email: user?.email,
          })
        );
      }
    }
  }, [dispatch, history, isAuthenticated, user, user?.status]);

  //to calculate the progress bar value based on total number of steps per section and current step
  useEffect(() => {
    if (quizData.currentStep >= 2 && quizData.currentStep <= 11) {
      setProgressBar(((quizData.currentStep - 1) * 33) / 11);
    } else if (quizData.currentStep >= 14 && quizData.currentStep <= 24) {
      setProgressBar(((quizData.currentStep - 13) * 33) / 12 + 33);
    } else if (quizData.currentStep >= 26 && quizData.currentStep <= 28) {
      setProgressBar(((quizData.currentStep - 25) * 33) / 2 + 66);
    }
  }, [quizData.currentStep]);
  return (
    <Layout>
      {/* Removed step1 kept here in case we want to revert it back */}
      {/* {quizData.currentStep === 1 && (
        <Reinforcer
          buttonClick={() => dispatch(updateQuizData({ currentStep: 2 }))}
          buttonText="I'm ready!"
          titleText="Let's create your personalised plan!"
          subtitleText="We want to learn about you, so we can tailor a plan to your goals and your body’s needs."
          backgroundImage={`url(${reinforcer_bg})`}
          backgroundImageSmall={`url(${reinforcer_bg_small})`}
        />
      )} */}
      {quizData.currentStep >= 2 && quizData.currentStep <= 11 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 4,
          }}>
          <Box sx={{ maxWidth: '420px', width: '100%' }}>
            <LinearProgress progress={progressBar} screenName={'Profile'} />
          </Box>
          <ProfileSection />
        </Box>
      )}
      {quizData.currentStep === 12 && (
        <ReinforcerWeightGoal
          buttonClick={() => dispatch(updateQuizData({ currentStep: 13 }))}
          buttonText="I’m ready to kick some goals!"
        />
      )}
      {quizData.currentStep === 13 && (
        <Reinforcer
          buttonClick={() => dispatch(updateQuizData({ currentStep: 14 }))}
          buttonText="Count me in!"
          titleText="We tailor our approach to your lifestyle needs."
          subtitleText="We help you create healthy habits that seamlessly integrate into your daily routine."
          backgroundImage={`url(${reinforcer_bg_2})`}
          backgroundImageSmall={`url(${reinforcer_bg_small_2})`}
        />
      )}
      {quizData.currentStep >= 14 &&
        quizData.currentStep <= 24 &&
        (quizData.currentStep === 16 ||
        quizData.currentStep === 17 ||
        quizData.currentStep === 18 ||
        quizData.currentStep === 19 ? (
          <LifestyleSection />
        ) : (
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              padding: quizData.currentStep !== 16 ? 4 : 0,
            }}>
            {quizData.currentStep !== 16 && (
              <Box sx={{ maxWidth: '420px', width: '100%' }}>
                <LinearProgress
                  progress={progressBar}
                  screenName={'Lifestyle'}
                />
              </Box>
            )}
            <LifestyleSection />
          </Box>
        ))}
      {quizData.currentStep === 25 && (
        <Reinforcer
          buttonClick={() => dispatch(updateQuizData({ currentStep: 26 }))}
          buttonText="Bring it on!"
          titleText="We’ve supported thousands of clients with their body and mind transformation!"
          backgroundImage={`url(${reinforcer_bg_4})`}
          backgroundImageSmall={`url(${reinforcer_bg_small_4})`}
          chatComponent={
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                margin: '20px 0px',
              }}>
              <Box
                sx={{
                  maxWidth: '80%',
                  alignSelf: 'flex-end',
                  background: colors.OFF_WHITE,
                  borderRadius: '12px',
                  padding: '8px 12px',
                }}>
                <Typography
                  sx={{
                    fontSize: '15px',
                    fontWeight: 600,
                    lineHeight: '22px',
                  }}>
                  “Equalution was life changing. I don't see my nutrition as a
                  diet, but as a lifestyle.”
                </Typography>
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 400,
                    lineHeight: '22px',
                    textAlign: 'right',
                  }}>
                  - Ana
                </Typography>
              </Box>
              <Box
                sx={{
                  maxWidth: '80%',
                  background: colors.CREAM_HALF,
                  borderRadius: '12px',
                  padding: '8px 12px',
                  marginTop: '20px',
                }}>
                <Typography
                  sx={{
                    fontSize: '15px',
                    fontWeight: 600,
                    lineHeight: '22px',
                  }}>
                  “You can really transform your mind, body and soul.”
                </Typography>
                <Typography
                  sx={{
                    fontSize: '12px',
                    fontWeight: 400,
                    lineHeight: '22px',
                    textAlign: 'right',
                  }}>
                  - Madeleine
                </Typography>
              </Box>
            </Box>
          }
        />
      )}

      {quizData.currentStep >= 26 && quizData.currentStep <= 28 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 4,
          }}>
          {quizData.currentStep !== 16 && (
            <Box sx={{ maxWidth: '420px', width: '100%' }}>
              <LinearProgress progress={progressBar} screenName={'General'} />
            </Box>
          )}
          <GeneralSection />
        </Box>
      )}
      {quizData.currentStep === 29 && <EmailStep />}
      {quizData.currentStep === 30 && (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: 4,
          }}>
          <PlanSummary />
        </Box>
      )}

      {quizData.currentStep > 2 && (
        <IconButton
          sx={{
            position: 'absolute',
            top: '-10px',
            left: '10px',
            zIndex: 111,
          }}>
          <BackButton
            onPress={() =>
              dispatch(
                updateQuizData({
                  currentStep:
                    quizData.currentStep > 14 && quizData.currentStep <= 20
                      ? 14
                      : quizData.currentStep - 1,
                })
              )
            }
            isLight={quizData.currentStep === 12}
          />
        </IconButton>
      )}
    </Layout>
  );
};

export default Quiz;
