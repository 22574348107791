import React, { FC } from 'react';
import { Box } from '@mui/material';

import styles from './linear-progress.module.scss';

interface LinearProgressProps {
  progress: number;
  screenName: string;
}

export const LinearProgress: FC<LinearProgressProps> = ({
  progress,
  screenName,
}) => {
  return (
    <Box>
      <Box className={styles.title}>{screenName}</Box>
      <Box className={styles.container}>
        <Box className={styles.progress} sx={{ width: `${progress + 0.5}%` }} />
        <Box className={styles.indicator} sx={{ left: `${100 / 3}%` }} />
        <Box className={styles.indicator} sx={{ left: `${2 * (100 / 3)}%` }} />
      </Box>
    </Box>
  );
};
