/* eslint-disable no-unreachable */
import React, { FC, useCallback, useState } from 'react';
import { Box, ButtonBase, Stack } from '@mui/material';

import CounterButton from 'src/components/counter-button/CounterButton';

import colors from 'src/styles/equalution/colors';
import InfoIcon from 'src/assets/icons/Info1.svg';
import fonts from 'src/styles/equalution/fonts';
import { getEcommerceDataProperties } from 'src/lib/utils';
import { track, EVENTS } from 'src/util/track';
import { useAppSelector } from 'src/store/hooks';

interface MealAddonProps {
  addonWeek: number;
  setAddonWeek: (value: number) => void;
}

const tooltipStyle = {
  display: 'none',
  position: 'absolute',
  bottom: '200%',
  left: '50%',
};

const innerStyle = {
  position: 'relative',
  left: '-50%',
  width: '100vw',
  maxWidth: '270px',
  padding: '1.5rem',
  borderRadius: '0.5rem',
  backgroundColor: colors.OFF_BLACK,
  color: colors.WHITE,
  fontWeight: 600,
  fontFamily: fonts.FontType.regular,
  fontSize: '14px',
  textAlign: 'left',
  zIndex: 1,
};

const arrowStyle = {
  zIndex: 0,
  content: '""',
  position: 'absolute',
  bottom: '-0.4rem',
  left: 'calc(50% - 0.5rem)',
  width: '1rem',
  height: '1rem',
  borderRadius: '0 0 0.2rem 0',
  backgroundColor: colors.OFF_BLACK,
  transform: 'rotate(45deg)',
};

const MealAddons: FC<MealAddonProps> = ({ addonWeek, setAddonWeek }) => {
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);

  const { selectedPlanId, allPlansSorted } = useAppSelector(
    state => state.signUp
  );
  const user = useAppSelector(state => state.auth?.user);

  if (isTooltipVisible) {
    tooltipStyle.display = 'block';
  }

  const trackEvents = useCallback(
    (value: number) => {
      const trackProperties = {
        logged_in_status: Boolean(user),
        ecommerce: getEcommerceDataProperties(
          selectedPlanId,
          allPlansSorted,
          {},
          value
        ),
      };
      track(EVENTS.viewItem, { ecommerce: null });
      track(EVENTS.viewItem, trackProperties);
    },
    [allPlansSorted, selectedPlanId, user]
  );

  const handleCounter = useCallback(
    (value: number) => {
      setAddonWeek(value);
      trackEvents(value);
    },
    [setAddonWeek, trackEvents]
  );

  // NOTE: (DEL-1937) We are removing Additional Meal Plan Component for new users
  return null;

  return (
    <Stack width="100%">
      <Box textAlign={'center'} mb={'12px'} fontWeight={800} fontSize={'20px'}>
        Add-ons
      </Box>
      <Stack
        p={'16px'}
        borderRadius={'20px'}
        bgcolor={colors.OFF_WHITE}
        direction={'row'}
        justifyContent={'space-between'}
        alignItems={'center'}>
        <Stack>
          <Stack
            width={'150%'}
            mb={'8px'}
            alignItems={'center'}
            direction={'row'}>
            <Box fontSize={'20px'} fontWeight={800} mr={'8px'}>
              Full-day meal plans
            </Box>
            <ButtonBase
              disableRipple
              className="tooltip"
              onMouseEnter={() => setIsTooltipVisible(true)}
              onMouseLeave={() => setIsTooltipVisible(false)}>
              <img src={InfoIcon} alt="info" />
              {isTooltipVisible && (
                <Box sx={tooltipStyle}>
                  <Box className="inner" sx={innerStyle}>
                    You receive 1 full-day meal plan as part of your
                    subscription. This covers an entire day of eating, and is
                    customised to your daily targets and food preferences. You
                    can add extra full-day meal plans on top of this for $7 each
                    <Box sx={arrowStyle} />
                  </Box>
                </Box>
              )}
            </ButtonBase>
          </Stack>
          <Box fontSize={'16px'} width={'80%'} fontWeight={400}>
            Each additional meal plan is{' '}
            <span
              style={{
                fontWeight: 600,
              }}>
              $7
            </span>
            /week
          </Box>
        </Stack>
        <CounterButton value={addonWeek} setCounter={handleCounter} />
      </Stack>
    </Stack>
  );
};

export default MealAddons;
