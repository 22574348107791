import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Plan } from 'src/pages/deprecated/payment/types';
import { TagData } from './signup-workflow/types';

export type User = {
  firstName: string;
  lastName: string;
  email: string;
};
export interface InitialStateType {
  currentStep: number;
  selectedPlanId: string;
  allPlansSorted: Plan[];
  availablePlans: Plan[];
  coupon: string;
  couponObj: any;
  addonWeek: number;
  registeredUser: User | {};
  isChallengeAccepted: boolean;
  futureCommencement: boolean;
  challengeData: TagData | null;
  isFitherProjectUser: boolean;
  noIntentAvailable: boolean;
}

const initialState: InitialStateType = {
  currentStep: 1,
  selectedPlanId: '',
  allPlansSorted: [],
  availablePlans: [],
  coupon: '',
  couponObj: {},
  addonWeek: 1,
  registeredUser: {},
  isChallengeAccepted: false,
  futureCommencement: false,
  challengeData: null,
  isFitherProjectUser: false,
  noIntentAvailable: false,
};
export const signUpSlice = createSlice({
  name: 'signUp',
  initialState,
  reducers: {
    setSignupStep: (state, action: PayloadAction<number>) => {
      state.currentStep = action.payload;
    },
    setIsChallengeAccepted: (state, action: PayloadAction<boolean>) => {
      state.isChallengeAccepted = action.payload;
    },
    setIsFutureCommencement: (state, action: PayloadAction<boolean>) => {
      state.futureCommencement = action.payload;
    },
    setChallengeData: (state, action: PayloadAction<TagData | null>) => {
      state.challengeData = action.payload;
    },
    setIsFitherProjectUser: (state, action: PayloadAction<boolean>) => {
      state.isFitherProjectUser = action.payload;
    },
    setNoPaymentIntentAvailable: (state, action: PayloadAction<boolean>) => {
      state.noIntentAvailable = action.payload;
    },
    setSelectedPlanId: (state, action: PayloadAction<string>) => {
      state.selectedPlanId = action.payload;
      state.couponObj = {};
    },
    setAvailablePlans: (state, action: PayloadAction<Plan[]>) => {
      state.availablePlans = action.payload;
      if (action.payload && action.payload[0]) {
        state.selectedPlanId = action.payload[0].id; //default to first plan because of design, we need to select a plan
      }
      state.couponObj = {};
    },
    setAvailablePlansWithoutDefaultCheck: (
      state,
      action: PayloadAction<Plan[]>
    ) => {
      state.availablePlans = action.payload;
      state.couponObj = {};
    },
    setAddonWeek: (state, action: PayloadAction<number>) => {
      state.addonWeek = action.payload;
    },
    setCouponObj: (state, action: PayloadAction<any>) => {
      state.couponObj = action.payload;
    },
    setAllPlansSorted: (state, action: PayloadAction<Plan[]>) => {
      state.allPlansSorted = action.payload;
    },
    handleProspectUserRedirect: state => {
      state.availablePlans = [];
      state.couponObj = {};
    },
    setSignUpUser: (
      state,
      action: PayloadAction<{
        firstName: string;
        lastName: string;
        email: string;
      }>
    ) => {
      state.registeredUser = action.payload;
    },
  },
});

export const {
  setSignupStep,
  setIsChallengeAccepted,
  setIsFutureCommencement,
  setChallengeData,
  setIsFitherProjectUser,
  setNoPaymentIntentAvailable,
  setSelectedPlanId,
  setAllPlansSorted,
  setAvailablePlans,
  setAvailablePlansWithoutDefaultCheck,
  setAddonWeek,
  setSignUpUser,
  setCouponObj,
  handleProspectUserRedirect,
} = signUpSlice.actions;

export default signUpSlice.reducer;
