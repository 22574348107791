import ISteps from './types';

const steps: ISteps = {
  stepone: {
    url: '/step-one',
    requiresAuth: false,
    requiresStep: null,
    complete: false,
    nextStep: 'stepone',
  },
  steptwo: {
    url: '/step-two',
    requiresAuth: true,
    requiresStep: 'stepone',
    complete: false,
    nextStep: 'confirmation',
  },
  confirmation: {
    url: '/confirmation',
    requiresAuth: true,
    requiresStep: 'steptwo',
    complete: false,
    nextStep: null,
  },
};

export default steps;
