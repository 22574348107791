import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface InitialStateType {
  isReturning: boolean;
  currentStep: number;
  goal_id: number;
  gender: string;
  is_breastfeeding: number | null; // 0 OR 1
  breastfeeding_frequency: number;
  dob: string;
  height: number | string;
  weight: number;
  target_weight: number;
  has_target_weight: boolean | null;
  low_intensity_days: number;
  high_intensity_days: number;
  activity_level: number;
  sleep_hours: string;
  water_intake: string;
  problem_hitting_goal: number;
  stress_level: number;
  located: string;
  heard_about_eq: number;
  FName: string;
  LName: string;
  email: string;
  interests: [number] | [];
  goal_adherence_challenges: [number] | []; //motivation
  meal_adherence_challenges: [number] | [];
  isQuiz: boolean;
}

export type QuizType = 'typeform' | 'equalution' | null;

export interface QuizStateType {
  quizData: InitialStateType;
  quizType: QuizType;
}

const initialState: QuizStateType = {
  quizData: {
    isReturning: false,
    currentStep: 2,
    goal_id: 0,
    gender: '',
    breastfeeding_frequency: 0,
    dob: '',
    height: '',
    weight: 0,
    target_weight: 0,
    has_target_weight: null,
    low_intensity_days: 0,
    high_intensity_days: 0,
    activity_level: 0,
    sleep_hours: '',
    water_intake: '',
    problem_hitting_goal: 0,
    stress_level: 0,
    located: '',
    heard_about_eq: 0,
    FName: '',
    LName: '',
    email: '',
    interests: [],
    goal_adherence_challenges: [],
    meal_adherence_challenges: [],
    is_breastfeeding: null, //Optional
    isQuiz: false,
  },
  quizType: null,
};

export const quizSlice = createSlice({
  name: 'quiz',
  initialState,
  reducers: {
    updateQuizData: (state, action: PayloadAction<any>) => {
      state.quizData = { ...state.quizData, ...action.payload };
    },
    updateQuizType: (state, action: PayloadAction<QuizType>) => {
      state.quizType = action.payload;
    },
  },
});

export const { updateQuizData, updateQuizType } = quizSlice.actions;

export default quizSlice.reducer;
