import React, { ComponentType } from 'react';

interface Props {
  currentStep: number;
}

export function signupStepWrapper<T extends Props>(
  WrappedComponent: ComponentType<T>,
  targetStep: number
) {
  return (props: T) => {
    if (props.currentStep === targetStep) {
      return <WrappedComponent {...props} />;
    }
    return null;
  };
}
