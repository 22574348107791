import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import colors from 'src/styles/equalution/colors';
import Fonts from 'src/styles/equalution/fonts';
import { useAppDispatch } from 'src/store/hooks';
import { setAddonWeek } from 'src/store/signup';

interface SubscriptionTabsProps {
  selectedTab: 'standard' | 'premium';
  onTabClick: (plan: 'standard' | 'premium') => void;
}
const SubscriptionTabs: FC<SubscriptionTabsProps> = props => {
  const { selectedTab, onTabClick } = props;
  const dispatch = useAppDispatch();

  return (
    <Box
      sx={{
        border: '2px solid',
        borderColor: colors.ORANGE_HALF,
        borderRadius: '32px',
        alignItems: 'center',
        justifyContent: 'space-between',
        textAlign: 'center',
        display: 'flex',
      }}>
      <Typography
        variant="button"
        sx={{
          fontFamily: Fonts.FontType.regular,
          fontSize: '18px',
          fontWeight: '600',
          color:
            selectedTab === 'standard'
              ? colors.OFF_BLACK
              : colors.BLUEBERRY_HALF,
          cursor: 'pointer',
          backgroundColor:
            selectedTab === 'standard' ? colors.ORANGE_HALF : 'transparent',
          padding: '12px 20px',
          borderRadius: '27px',
          flex: 1,
          textTransform: 'none',
          transition: '0.3s',
        }}
        onClick={() => {
          dispatch(setAddonWeek(1));
          onTabClick('standard');
        }}>
        Standard
      </Typography>
      <Typography
        variant="button"
        sx={{
          fontFamily: Fonts.FontType.regular,
          fontSize: '18px',
          fontWeight: '600',
          color:
            selectedTab === 'premium' ? colors.WHITE : colors.BLUEBERRY_HALF,
          cursor: 'pointer',
          backgroundColor:
            selectedTab === 'premium' ? colors.SALAD : 'transparent',
          padding: '12px 20px',
          borderRadius: '27px',
          flex: 1,
          textTransform: 'none',
          transition: '0.3s',
        }}
        onClick={() => onTabClick('premium')}>
        Premium
      </Typography>
    </Box>
  );
};

export default SubscriptionTabs;
