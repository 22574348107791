import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import styles from './account-preview.module.scss';
import { IAppState } from '../../../../store';

const AccountPreview: FunctionComponent = () => {
  // Redux Selectors
  const user = useSelector((state: IAppState) => state.auth?.user);

  if (!user) {
    return (
      <div className={styles.noneSelected}>
        No account yet. Sign up with your email.
      </div>
    );
  }

  return (
    <div className={styles.accountPreview}>
      <div className={styles.accountIcon} />
      <div className={styles.account}>
        <div className={styles.method}>Email</div>
        <div className={styles.email}>{user.email}</div>
      </div>
    </div>
  );
};

export default AccountPreview;
