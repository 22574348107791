/* eslint-disable no-unreachable */
import React from 'react';
import styles from './plan-quantity-counter.module.scss';
import CounterButton from 'src/components/counter-button/CounterButton';

interface OwnProps {
  MIN: number;
  MAX: number;
  weeklyMealPlanQty: number;
  onChange: (mealPlanQty: number) => void;
}

const PlanQuantityCounter = ({
  weeklyMealPlanQty,
  onChange,
  MIN,
  MAX,
}: OwnProps) => {
  // NOTE: (DEL-1937) We are removing Additional Meal Plan Component for all users
  return null;

  return (
    <div className={styles.planQuantityCounter}>
      <div className={styles.left}>
        <div className={styles.upper}>
          Full-day meal plans
          <span className={styles.infoButton}>
            <div className={styles.tooltip}>
              <div className={styles.inner}>
                One meal plan covers an entire day detailing what to eat from
                start to finish and is customised to your daily targets and food
                preferences. Meal plans added to your subscription will be
                provided on a weekly basis.
              </div>
            </div>
          </span>
        </div>
        <div className={styles.price}>
          Each additional meal plan is{' '}
          <span className={styles.red}>$7 per week</span>
        </div>
      </div>
      <CounterButton value={weeklyMealPlanQty} setCounter={onChange} />
    </div>
  );
};

export default PlanQuantityCounter;
