import React from 'react';
import './loader.scss';

const Loader = (): JSX.Element => {
  return (
    <div className="loading-component">
      <i className="fas fa-spinner fa-3x fa-spin" />
    </div>
  );
};

export default Loader;
