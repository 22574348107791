import React, { FunctionComponent } from 'react';
import { useSelector } from 'react-redux';
import styles from './account-profile.module.scss';
import { IAppState } from '../../../../store';

const AccountProfile: FunctionComponent = () => {
  // Redux Selectors
  const user = useSelector((state: IAppState) => state.auth?.user);

  if (!user) return <></>;

  return (
    <div className={styles.accountProfile}>
      <h3>Profile</h3>

      <div className={styles.field}>
        <div className={styles.label}>Email</div>
        <div className={styles.inner}>
          <div className={styles.text}>{user.email}</div>
        </div>
      </div>

      <div className={styles.field}>
        <div className={styles.label}>First Name</div>
        <div className={styles.inner}>
          <div className={styles.text}>{user.firstName}</div>
        </div>
      </div>

      <div className={styles.field}>
        <div className={styles.label}>Last Name</div>
        <div className={styles.inner}>
          <div className={styles.text}>{user.lastName}</div>
        </div>
      </div>
    </div>
  );
};

export default AccountProfile;
