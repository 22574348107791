import React, { FC } from 'react';
import { Box, ButtonBase, Typography } from '@mui/material';
import styles from './goal-selection-button.module.scss';

interface GoalSelectionButtonProps {
  title: string;
  isSelected: boolean;
  showReverseOrder?: boolean;
  addExtraPaddingLeft?: boolean;
  addExtraPaddingRight?: boolean;
  onPress?: (text: string) => void;
}

export const GoalSelectionButton: FC<GoalSelectionButtonProps> = ({
  title,
  isSelected,
  onPress,
  showReverseOrder = false,
  addExtraPaddingLeft = false,
  addExtraPaddingRight = false,
}) => {
  return (
    <Box
      className={`${styles.container} ${
        showReverseOrder && styles.reverseOrderStyle
      } ${addExtraPaddingLeft && styles.extraPaddingLeftStyle}
      ${addExtraPaddingRight && styles.extraPaddingRightStyle}`}>
      <ButtonBase
        className={`${styles.buttonStyle} ${isSelected && styles.selected}`}
        onClick={() => onPress?.(title)}
        disableRipple>
        <Typography
          className={`${styles.title} ${isSelected && styles.selectedTitle}`}>
          {title}
        </Typography>
      </ButtonBase>
    </Box>
  );
};
