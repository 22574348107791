import { useAppSelector } from 'src/store/hooks';
import { EVENTS, track } from 'src/util/track';

export const QUIZ_SCREENS = {
  PrimaryGoal: 'Primary Goal Select',
  Motivation: 'Select Motivation',
  Gender: 'Select Gender',
  BreastfeedingOption: 'Is Breastfeeding',
  BreastfeedingFrequency: 'Breastfeeding Frequency',
  DOB: 'DOB',
  Height: 'Height (in cm)',
  Weight: 'Weight (in kg)',
  TargetWeightOption: 'Has Target Weight',
  TargetWeight: 'Target Weight (in kg)',
  GoalChallenge: 'Select Goal Challenge',
  MealChallenge: 'Select Meal Challenge',
  ExerciseDay: 'Exercise Day',
  ActivityLevel: 'Select Activty Level',
  Sleep: 'Sleep Hours',
  WaterIntake: 'Water Intake (in lit)',
  StressLevel: 'Select Stress Level',
  Country: 'Select Country',
  Interest: 'Interested in any of the following',
  HearAboutUs: 'Select How you Hear About Us',
  AccessResult: 'Access Quiz Results',
  StartTransformation: 'Start Transformation from Summary Page',
};

const QUIZ_QUESTION_SCREENS = Object.values(QUIZ_SCREENS);

export const useQuizTracking = () => {
  const quizType = useAppSelector(state => state.quiz.quizType);

  const trackEvent = (questionName: keyof typeof QUIZ_SCREENS, value: any) => {
    const step = QUIZ_SCREENS[questionName];
    const quizStep = QUIZ_QUESTION_SCREENS.indexOf(step) + 1;

    track(`${EVENTS.quizStep}_${quizStep}`, {
      Label: value,
      'Step Name': step,
      'Quiz Type': quizType,
    });
  };
  return trackEvent;
};
