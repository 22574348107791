import React from 'react';
import { Stack } from '@mui/material';
import { Layout } from 'src/components/layout-wrapper/Layout';
import { Button } from 'reactstrap';

import history from 'src/lib/history';

const AllPages = () => {
  return (
    <Layout>
      <Stack direction={'row'} gap={'60px'}>
        <Stack sx={{ padding: '58px', width: 300 }} gap={'20px'}>
          <Button onClick={() => history.push('/login-account')}>
            Login Page
          </Button>
          <Button onClick={() => history.push('/create-account')}>
            Create Account Page
          </Button>
          <Button
            onClick={() => history.push('/subscription?planType=premium')}>
            Subscription Page (Premium)
          </Button>
          <Button
            onClick={() => history.push('/subscription?planType=standard')}>
            Subscription Page (Standard)
          </Button>
          <Button onClick={() => history.push('/payment')}>Payment Page</Button>
          <Button onClick={() => history.push('/success')}>
            Success Page (Thankyou)
          </Button>
          <Button onClick={() => history.push('/signup-new')}>
            New Flow - Signup
          </Button>
        </Stack>

        <Stack sx={{ padding: '58px', width: 300 }} gap={'20px'}>
          <Button onClick={() => history.push('/quiz')}>Quiz Components</Button>
          <Button onClick={() => history.push('/new-signup')}>
            Signup Components
          </Button>
        </Stack>
      </Stack>
    </Layout>
  );
};

export default AllPages;
