import React, { useContext, useEffect, useState } from 'react';
import {
  PaymentMethodMessagingElement,
  useStripe,
} from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import paymentAPI from '../../../deprecated/payment/api';
import { IAppState } from '../../../../store';
import { Coupon } from 'src/pages/deprecated/payment/types';
import { TagData } from 'src/store/signup-workflow/types';
import styles from './afterpay-component.module.scss';
import { Button } from 'reactstrap';
import { ToastContext } from 'src/components/toast/toast';
import {
  CUSTOMER_ACCEPTANCE,
  CUSTOMER_BILLING_ADDRESS,
  CUSTOMER_SHIPPING_ADDRESS,
} from 'src/pages/constants';

interface OwnProps {
  priceId: string;
  planName: string;
  finalPrice: number;
  coupon: Coupon | undefined;
  isChallengeAccepted: boolean;
  tagId: number;
  challengeObject: TagData | null;
  futureCommencement: boolean;
  productId: string;
  stripeCustomer: any;
  setWalletPaymentIntentId: (paymentIntent: string) => void;
}

const AfterPayComponent = ({
  priceId,
  finalPrice,
  coupon,
  isChallengeAccepted,
  tagId,
  challengeObject,
  futureCommencement,
  productId,
  stripeCustomer,
  setWalletPaymentIntentId,
}: OwnProps) => {
  // Redux Selectors
  const user = useSelector((state: IAppState) => state.auth?.user);
  const { addToast } = useContext(ToastContext);

  const stripe = useStripe();

  const [subscriptionClientSecret, setSubscriptionClientSecret] = useState<
    string | null
  >(null);
  const [checkAfterPayStatus, setCheckAfterPayStatus] = useState(false);
  const [subscriptionPaymentDetail, setSubscriptionPaymentDetail] =
    useState<any>(null);
  const [isLoading, toggleIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const handleAfterPayPayment = async (clientSecret: string) => {
      if (!stripe) {
        return;
      }
      const payObjToStore = {
        priceId,
        coupon,
        isChallengeAccepted,
        customerID: stripeCustomer?.id,
        paymentIntentId: subscriptionPaymentDetail.payment_intent.id,
        totalPrice: finalPrice.toString(),
        futureCommencement,
        tagId,
        productId,
        challengeObject,
      };
      localStorage.setItem('payObj', JSON.stringify(payObjToStore));

      const result = await stripe.confirmAfterpayClearpayPayment(clientSecret, {
        //`Elements` instance that was used to create the Payment Element
        return_url: window.location.origin + '/confirmAfterPay',
        mandate_data: CUSTOMER_ACCEPTANCE,
        payment_method: CUSTOMER_BILLING_ADDRESS,
        shipping: CUSTOMER_SHIPPING_ADDRESS,
      });

      if (result.error) {
        addToast('Something went wrong. Please try again.', 'danger', 5000);
        localStorage.setItem('payObj', '{}');
      } else {
        // Payment success and subscription created
      }
    };

    if (
      user &&
      checkAfterPayStatus &&
      subscriptionClientSecret &&
      subscriptionPaymentDetail
    ) {
      handleAfterPayPayment(subscriptionClientSecret);
    }
  }, [
    checkAfterPayStatus,
    stripe,
    stripeCustomer,
    subscriptionPaymentDetail,
    subscriptionClientSecret,
    user,
    priceId,
    coupon,
    isChallengeAccepted,
    finalPrice,
    futureCommencement,
    tagId,
    productId,
    challengeObject,
    addToast,
  ]);

  if (typeof user === 'undefined') {
    return <></>;
  }

  const createSubscriptionIntent = async () => {
    toggleIsLoading(true);
    const result = await paymentAPI.post('afterpay/create-subscription', {
      priceId: priceId,
      customerId: stripeCustomer.id,
      coupon: coupon?.id,
    });
    setSubscriptionClientSecret(result?.data.clientSecret);

    setSubscriptionPaymentDetail(result?.data?.subscription?.latest_invoice);
    setWalletPaymentIntentId(
      result?.data?.subscription?.latest_invoice?.payment_intent?.id
    );
    setTimeout(() => {
      setCheckAfterPayStatus(true);
      toggleIsLoading(false);
    }, 500);
  };

  return (
    <div className={`${styles.mainContainer}`}>
      <div className={`${styles.mainDiv}`}>
        {stripe && (
          <div style={{ margin: '10px' }}>
            <PaymentMethodMessagingElement
              options={{
                amount: parseInt(finalPrice.toFixed(2)) * 100,
                currency: 'AUD',
                paymentMethodTypes: ['afterpay_clearpay'],
                countryCode: 'AU',
              }}
            />
          </div>
        )}
      </div>
      <Button
        onClick={() => createSubscriptionIntent()}
        style={{ marginBottom: '10px' }}
        disabled={isLoading || !stripeCustomer?.id}>
        {isLoading ? 'Processing' : 'Finalise with Afterpay'}
      </Button>
    </div>
  );
};

export default AfterPayComponent;
