import React, { CSSProperties } from 'react';
import { useSelector } from 'react-redux';

import styles from './header.module.scss';
import logo from '../../assets/images/logo-new.svg';
import TextButton from './modules/text-button';
import UserButton from './modules/user-button';
import { IAppState } from '../../store';
import colors from 'src/styles/equalution/colors';

interface Props {
  module?: 'login' | 'signup';

  hideAccount?: boolean;
  style?: CSSProperties;
}

/**
 * Displays a Header with a logo and sign-in button
 * @param props: any
 */
const Header = ({ module, hideAccount, ...attributes }: Props): JSX.Element => {
  // Redux Selectors
  const user = useSelector((state: IAppState) => state.auth?.user);
  const isAuthenticated = useSelector(
    (state: IAppState) => state.auth?.isAuthenticated
  );

  let component;

  if (isAuthenticated && user) {
    component = <UserButton hideAccount={hideAccount} />;
  } else if (module === 'login') {
    component = (
      <TextButton
        text="Already have an account?"
        buttonText="Login"
        path="/login"
      />
    );
  } else if (module === 'signup') {
    component = (
      <TextButton
        text="Don't have an account yet?"
        buttonText="Sign up"
        path="/signup"
        textColor={colors.OFF_BLACK}
      />
    );
  } else {
    component = <></>;
  }

  return (
    <header className={styles.header} {...attributes}>
      <div className={styles.inner}>
        <a href="https://joinequ.com/">
          <img src={logo} alt="logo" className={styles.logo} />
        </a>
        {component}
      </div>
    </header>
  );
};

Header.defaultProps = {
  module: undefined,
  style: {},
};

export default Header;
