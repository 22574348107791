import React, { FC } from 'react';
import { Box, Typography } from '@mui/material';
import colors from 'src/styles/equalution/colors';
import Fonts from 'src/styles/equalution/fonts';

interface PlanDetailInfoBoxProps {
  title: string;
  description: string;
  onButtonClick: () => void;
}
const PlanDetailInfoBox: FC<PlanDetailInfoBoxProps> = props => {
  const { title, description, onButtonClick } = props;

  return (
    <Box
      sx={{
        border: '1px solid rgba(229, 70, 57, 0.30)',
        borderColor: colors.SALAD,
        borderRadius: '20px',
        padding: '32px 16px',
        alignItems: 'center',
        color: colors.OFF_BLACK,
        justifyContent: 'center',
        textAlign: 'center',
      }}>
      <Typography
        sx={{
          fontSize: '20px',
          fontWeight: 800,
          fontFamily: Fonts.FontType.regular,
        }}>
        {title}
      </Typography>
      <Typography
        sx={{
          fontSize: '16px',
          fontWeight: 400,
          color: colors.BLUEBERRY,
          margin: '10px 0px',
          fontFamily: Fonts.FontType.regular,
        }}>
        {description}
      </Typography>
      <Typography
        variant="button"
        sx={{
          fontSize: '14px',
          textTransform: 'unset',
          textDecoration: 'underline',
          fontFamily: Fonts.FontType.regular,
          cursor: 'pointer',
          padding: '16px',
        }}
        onClick={onButtonClick}>
        View Details
      </Typography>
    </Box>
  );
};

export default PlanDetailInfoBox;
