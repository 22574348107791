import IAuthState, {
  AUTHENTICATE,
  IAuthenticate,
  IUnauthenticate,
  UNAUTHENTICATE,
} from './types';

// export interface AuthState {
//     uuid?: string;
//     isAuthenticated?: boolean;
// }

const initialState: IAuthState = {
  user: undefined,
  isAuthenticated: undefined,
};

export default function authReducer(
  state: IAuthState = initialState,
  action: IAuthenticate | IUnauthenticate
): IAuthState | undefined {
  switch (action.type) {
    case AUTHENTICATE:
      return {
        ...state,
        user: action.user,
        isAuthenticated: true,
      };
    case UNAUTHENTICATE:
      return {
        user: undefined,
        isAuthenticated: false,
      };
    default:
      return state;
  }
}
