import React, { FunctionComponent, PropsWithChildren } from 'react';

interface OwnProps {
  className?: string;
  lighter?: boolean;
}

type Props = OwnProps;

const ContentCard: FunctionComponent<PropsWithChildren<Props>> = ({
  className,
  lighter,
  children,
}: PropsWithChildren<Props>) => {
  return (
    <div
      className={`contentCard ${lighter ? 'lighter' : ''} ${className || ''}`}>
      {children}
    </div>
  );
};

export default ContentCard;
