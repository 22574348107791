import { Box, Typography } from '@mui/material';
import React, { FC } from 'react';
import colors from 'src/styles/equalution/colors';
import Triangle from 'src/assets/images/triangle.svg';
import Coaching from 'src/assets/images/coaching.svg';
import Fonts from 'src/styles/equalution/fonts';

interface CoachQuoteProps {
  position?: 'bottom' | 'top-right' | 'left';
}
const CoachQuote: FC<CoachQuoteProps> = props => {
  const { position } = props;

  return (
    <Box
      sx={{
        position: 'relative',
        backgroundColor: colors.WHITE,
        borderRadius: '20px',
        display: 'flex',
        padding: '16px',
      }}>
      <img
        src={Coaching}
        alt="Coaching"
        style={{ height: '72px', width: '72px', marginRight: '16px' }}
      />
      <Box>
        <Typography
          variant="h6"
          sx={{
            color: colors.SALAD,
            fontWeight: 800,
            fontSize: '20px',
            fontFamily: Fonts.FontType.regular,
          }}>
          Get a personal coach
        </Typography>
        <Typography
          variant="subtitle1"
          sx={{
            color: colors.OFF_BLACK,
            fontWeight: 400,
            fontSize: '14px',
            lineHeight: '20px',
            fontFamily: Fonts.FontType.regular,
          }}>
          1:1 coaching with an expert <br />
          nutritionist to optimise your plan
        </Typography>
        {position === 'top-right' && (
          <img
            src={Triangle}
            alt="Triangle"
            style={{
              height: '26px',
              width: '52px',
              marginRight: '16px',
              position: 'absolute',
              top: '-18px',
              right: '15%',
            }}
          />
        )}
        {position === 'left' && (
          <img
            src={Triangle}
            alt="Triangle"
            style={{
              height: '26px',
              width: '52px',
              marginRight: '16px',
              position: 'absolute',
              top: '45px',
              left: '-32px',
              rotate: '-90deg',
            }}
          />
        )}
        {position === 'bottom' && (
          <img
            src={Triangle}
            alt="Triangle"
            style={{
              height: '26px',
              width: '52px',
              marginRight: '16px',
              position: 'absolute',
              bottom: '-18px',
              right: '40%',
              rotate: '180deg',
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default CoachQuote;
