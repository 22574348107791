import Themes from './theme';

export type FontStyleVariation =
  | 'largeTitle'
  | 'title1'
  | 'title1Red'
  | 'title2'
  | 'title3'
  | 'headline'
  | 'body'
  | 'lightBody'
  | 'descriptionText'
  | 'boldBody'
  | 'callout'
  | 'subhead'
  | 'footNote1'
  | 'footNote2'
  | 'caption1'
  | 'caption2'
  | 'inputLabel';

export type FontSize = {
  [key: string]: number;
};

export enum FontType {
  regular = 'Lexend, sans-serif',
  bold = 'Lexend-Bold, sans-serif',
}

const style = {
  // NEW FONTS
  largeTitle: {
    fontSize: 32,
    // lineHeight: 40,
    fontFamily: FontType.regular,
    letterSpacing: -0.6,
    color: Themes.TEXT_PRIMARY,
  },
  title1: {
    fontSize: 26,
    // lineHeight: 34,
    fontFamily: FontType.regular,
    letterSpacing: -0.6,
    color: Themes.TEXT_PRIMARY,
  },
  title1Red: {
    fontSize: 26,
    // lineHeight: 34,
    fontFamily: FontType.regular,
    letterSpacing: -0.6,
    color: Themes.TEXT_LINK,
  },
  title2: {
    fontSize: 22,
    // lineHeight: 30,
    fontFamily: FontType.regular,
    letterSpacing: -0.6,
    color: Themes.TEXT_PRIMARY,
  },
  title3: {
    fontSize: 20,
    // lineHeight: 28,
    fontFamily: FontType.regular,
    letterSpacing: -0.6,
    // color: Themes.TEXT_PRIMARY,
  },
  headline: {
    fontSize: 16,
    // lineHeight: 22,
    fontFamily: FontType.regular,
    letterSpacing: -0.2,
    color: Themes.TEXT_PRIMARY,
  },
  body: {
    fontSize: 17,
    // lineHeight: 24,
    fontFamily: FontType.regular,
    letterSpacing: 0,
    color: Themes.TEXT_PRIMARY,
  },
  lightBody: {
    fontSize: 16,
    // lineHeight: 22,
    fontFamily: FontType.regular,
    color: Themes.TEXT_SECONDARY,
  },
  descriptionText: {
    fontSize: 14,
    // lineHeight: 20,
    fontFamily: FontType.regular,
    color: Themes.TEXT_DESCRIPTION,
  },
  boldBody: {
    fontSize: 16,
    // lineHeight: 22,
    fontFamily: FontType.regular,
    color: Themes.TEXT_SECONDARY,
  },
  callout: {
    fontSize: 16,
    // lineHeight: 22,
    fontFamily: FontType.regular,
    letterSpacing: -0.2,
    color: Themes.TEXT_PRIMARY,
  },
  subhead: {
    fontSize: 15,
    // lineHeight: 20,
    fontFamily: FontType.regular,
    letterSpacing: 0,
    color: Themes.TEXT_PRIMARY,
  },
  footNote1: {
    fontSize: 15,
    // lineHeight: 20,
    fontFamily: FontType.regular,
    letterSpacing: 0,
    color: Themes.TEXT_PRIMARY,
  },
  footNote2: {
    fontSize: 15,
    // lineHeight: 20,
    fontFamily: FontType.regular,
    letterSpacing: 0,
    color: Themes.TEXT_PRIMARY,
  },
  caption1: {
    fontSize: 14,
    // lineHeight: 20,
    fontFamily: FontType.regular,
    letterSpacing: 0,
    color: Themes.TEXT_PRIMARY,
  },
  caption2: {
    fontSize: 12,
    fontFamily: FontType.regular,
    letterSpacing: 0.6,
    color: Themes.TEXT_PRIMARY,
  },
  inputLabel: {
    fontSize: 12,
    fontFamily: FontType.regular,
    color: Themes.TEXT_PRIMARY,
  },
};

const fonts = { FontType, style };

export default fonts;
