import { FunctionComponent } from 'react';

export interface AccountRoute {
  name: string;
  path: string;
  statusesGranted?: string[];
  component: FunctionComponent | undefined;
}

export enum REQUEST_STATUS_CODE {
  OK = 200,
}

export interface UserTags {
  id: number;
  tag: {
    challenge_start_date: string;
    entity_type: string;
    id: number;
    name: string;
  };
  tag_id: number;
  user_id: number;
}
