import React, { useEffect, useState } from 'react';
import { Button } from 'reactstrap';
import { Plans } from '../../../deprecated/payment/types';
import User from '../../../../models/User';
import Popup from '../../../../components/content/popup/popup';
import Loader from '../../../../components/content/loader';
import ContentSection from '../../../../components/content/content-section';
import ContentWrapper from '../../../../components/content/content-wrapper';
import styles from './subscription-popup.module.scss';
import PlanSelector from '../../../../components/subscriptions/plan-selector/plan-selector';
import PlanQuantityCounter from '../../../../components/subscriptions/plan-quantity-counter/plan-quantity-counter';
import SubscriptionPopupTabs from './subscription-popup-tabs';
import { PLANS, PLAN_TAB, USER_STATUS } from 'src/pages/constants';
import { EVENTS, track } from 'src/util/track';

interface OwnProps {
  selectedPlan: string;
  setSelectedPlan: (id: string) => void;
  weeklyMealPlanQty: number;
  setWeeklyMealPlanQty: (val: number) => void;
  plans: Plans | undefined;
  setPopupVisible: (val: boolean) => void;
  user: User | undefined;
  planRequest: string | null;
  isFromSignUp?: boolean;
}

type Props = OwnProps;

const SubscriptionPopup = ({
  selectedPlan,
  setSelectedPlan,
  weeklyMealPlanQty,
  setWeeklyMealPlanQty,
  plans,
  setPopupVisible,
  user,
  planRequest,
  isFromSignUp,
}: Props): JSX.Element => {
  const [planTabSelected, setPlanTabSelected] = useState(1);

  useEffect(() => {
    if (
      user?.status !== USER_STATUS.INACTIVE &&
      (planRequest?.toLowerCase().includes('lite') ||
        planRequest?.toLowerCase().includes('standard'))
    ) {
      setPlanTabSelected(PLAN_TAB.STANDARD);
    }
  }, [planRequest, user]);

  return (
    <Popup>
      <ContentWrapper className={styles.background} collapseAt="sm">
        <ContentSection className={styles.subscriptionPopup} collapseAt="sm">
          <Button
            className={styles.close}
            onClick={(): void => {
              setPopupVisible(false);
            }}
          />

          <h3>Choose subscription</h3>

          <SubscriptionPopupTabs
            currentPlan=""
            planTabSelected={planTabSelected}
            planRequested={planRequest}
            weeklyMealPlanQty={weeklyMealPlanQty}
            isNewUser
            onClickTab={planTab => {
              setPlanTabSelected(planTab);
              if (planTab === PLAN_TAB.STANDARD) {
                setWeeklyMealPlanQty(1);
                !isFromSignUp && setSelectedPlan('');
              }
            }}
          />

          {plans ? (
            <>
              <PlanSelector
                selectedPlan={selectedPlan}
                setSelectedPlan={setSelectedPlan}
                weeklyMealPlanQty={weeklyMealPlanQty}
                plans={plans}
                planRequested={planRequest}
                planTabSelected={planTabSelected}
              />
              {!selectedPlan.includes(PLANS.STANDARD_PLAN) &&
                planTabSelected !== PLAN_TAB.STANDARD && (
                  <PlanQuantityCounter
                    MAX={7}
                    MIN={
                      user &&
                      user.current_checkin &&
                      planTabSelected !== PLAN_TAB.STANDARD &&
                      user.current_checkin > 8
                        ? 0
                        : 1
                    }
                    weeklyMealPlanQty={weeklyMealPlanQty}
                    onChange={mealPlanQty => {
                      setWeeklyMealPlanQty(mealPlanQty);
                    }}
                  />
                )}

              <Button
                block
                disabled={selectedPlan === ''}
                onClick={(): void => {
                  setPopupVisible(false);
                  track(EVENTS.planSelected, {
                    selectedPlan: selectedPlan,
                  });
                }}>
                Done
              </Button>
            </>
          ) : (
            <Loader />
          )}
        </ContentSection>
      </ContentWrapper>
    </Popup>
  );
};

export default SubscriptionPopup;
