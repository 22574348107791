import React, { FC } from 'react';
import { Step, Stepper, Typography } from '@mui/material';
import colors from 'src/styles/equalution/colors';

type StepLabelProps = {
  completed: boolean;
  label: string;
};

function StepLabelComponent({ completed, label }: StepLabelProps) {
  return (
    <Typography
      sx={{
        fontSize: '12px',
        display: 'flex',
        height: '16px',
        width: '16px',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        color: colors.WHITE,
        backgroundColor: completed ? colors.SALAD : colors.BLUEBERRY_HALF,
      }}>
      {completed && label}
    </Typography>
  );
}

type StepperProps = {
  activeStep: number;
  steps: string[];
};

const SignUpStepper: FC<StepperProps> = ({
  activeStep = 0,
  steps = ['1', '2', '3'],
}) => {
  return (
    <Stepper
      sx={{
        '.MuiStep-root': {
          padding: 0,
        },
      }}
      activeStep={activeStep}>
      {steps.map((label, index) => (
        <Step
          key={label}
          active={activeStep === index}
          completed={activeStep > index}>
          <StepLabelComponent completed={activeStep > index} label={label} />
        </Step>
      ))}
    </Stepper>
  );
};

export default SignUpStepper;
