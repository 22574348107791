import React, {
  FunctionComponent,
  PropsWithChildren,
  useContext,
  useEffect,
} from 'react';
import { ScrollContext } from '../scroll-locker/scroll-locker';
import styles from './popup.module.scss';

type Props = any;

const Popup: FunctionComponent<Props> = ({
  children,
}: PropsWithChildren<Props>) => {
  const { setParentAllowScroll } = useContext(ScrollContext);

  useEffect(() => {
    setParentAllowScroll(false);
    return (): void => {
      setParentAllowScroll(true);
    };
  }, [setParentAllowScroll]);

  return <div className={styles.popup}>{children}</div>;
};

export default Popup;
