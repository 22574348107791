import React, { ReactNode } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { useSelector } from 'react-redux';
import Confirmation from './signup/confirmation/confirmation';
import ForgotPassword from './forgot-password/forgot-password';
import ResetPasswordContainer from './forgot-password/reset-password-container';
import { IAppState } from '../store';
import SignUp from './signup/signup';
import Account from './account/account';
import ConfirmAfterPay from './confirmAfterpay';
import AllPages from './all-pages/AllPages';
import CreateAccount from './create-account/CreateAccount';
import LoginAccount from './login-account/LoginAccount';
import SubscriptionPage from './subscription-new/SubscriptionPage';
import PaymentPage from './payment-new/PaymentPage';
import SuccessPage from './success-page/SuccessPage';
import SignupSteps from './signup-new/Signup';
import NoActivePlan from './account/noActivePlan';
import QuizPage from './quiz/QuizPage';

type RequireAuthProps = {
  children: ReactNode;
  path: string;
};

export const ProtectedRoute = ({ children, ...rest }: RequireAuthProps) => {
  const isAuthenticated = useSelector(
    (state: IAppState) => state.auth?.isAuthenticated
  );
  return (
    <Route
      {...rest}
      render={({ location }) => {
        return isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
};

const Pages = (): React.JSX.Element => {
  return (
    <Switch>
      <Route path="/" exact component={LoginAccount} />
      <Route path="/login" component={LoginAccount} />
      <Route path="/signup" component={SignupSteps} />
      <Route path="/quiz" component={QuizPage} />
      <Route path="/create-account" component={CreateAccount} />
      <Route path="/login-account" component={LoginAccount} />
      <Route path="/subscription" component={SubscriptionPage} />
      <Route path="/payment" component={PaymentPage} />
      <Route path="/success" component={SuccessPage} />
      <Route path="/all" component={AllPages} />
      <Route path="/signup-old" component={SignUp} />
      <Route path="/forgot-password">
        <Switch>
          <Route path="/forgot-password" exact component={ForgotPassword} />
          <Route
            path="/forgot-password/:token"
            exact
            component={ResetPasswordContainer}
          />
          <Route component={ForgotPassword} />
        </Switch>
      </Route>
      <ProtectedRoute path="/noActivePlan">
        <NoActivePlan />
      </ProtectedRoute>
      <ProtectedRoute path="/account">
        <Account />
      </ProtectedRoute>
      <ProtectedRoute path="/confirmAfterPay">
        <ConfirmAfterPay />
      </ProtectedRoute>
      <ProtectedRoute path="/confirmation">
        <Confirmation />
      </ProtectedRoute>
    </Switch>
  );
};

export default Pages;
