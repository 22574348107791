// Cookie'max age: 365 days
const COOKIE_MAX_AGE = 60 * 60 * 24 * 365;

interface Cookie {
  key: string;
  value: string;
  domain?: string;
  path?: string;
}

export function decodeCookie(): any {
  return document.cookie.split(';').reduce((res, c) => {
    const [key, val] = c.trim().split('=').map(decodeURIComponent);
    const allNumbers = (str: string): boolean => /^\d+$/.test(str);
    try {
      return Object.assign(res, {
        [key]: allNumbers(val) ? val : JSON.parse(val),
      });
    } catch (e) {
      return Object.assign(res, { [key]: val });
    }
  }, {});
}

function isHttps(): boolean {
  return window.location.protocol === 'https:';
}

export function encodeCookie({ key, value, domain, path = '/' }: Cookie): void {
  let finalCookieValue = `${key}=${encodeURIComponent(
    value
  )};max-age=${COOKIE_MAX_AGE}`;
  if (isHttps()) finalCookieValue += ';secure';
  if (domain) finalCookieValue += `;domain=${domain}`;
  if (path) finalCookieValue += `;path=${path}`;
  document.cookie = finalCookieValue;
}
