import React, { FunctionComponent } from 'react';
import styles from './extras-preview.module.scss';

interface OwnProps {
  label: string;
  qty: number;
  setPopupVisible: (val: boolean) => void;
}

type Props = OwnProps;

const ExtrasPreview: FunctionComponent<Props> = ({
  label,
  qty,
  setPopupVisible,
}: Props) => {
  return (
    <div className={styles.extrasPreview}>
      <div className={styles.price}>
        <span className={styles.big}>{qty}</span>{' '}
        {qty === 1 ? label : `${label}s`}
      </div>
    </div>
  );
};

export default ExtrasPreview;
