import React, { useState } from 'react';
import { Button } from 'reactstrap';
import ContentCard from '../../../../components/content/content-card';
import AccountPreview from './account-preview';
import AccountPopup from './account-popup';
import ContentCardLabel from '../../../../components/content/content-card-label';
import StepLabel from '../step-label';

interface OwnProps {
  completed: boolean;
}

type Props = OwnProps;

const AccountStep = ({ completed }: Props): JSX.Element => {
  const [popupVisible, setPopupVisible] = useState<boolean>(false);

  return (
    <>
      <div>
        <ContentCardLabel>
          <StepLabel completed={completed}>Account</StepLabel>
        </ContentCardLabel>

        <ContentCard>
          <AccountPreview />
          <Button
            size="sm"
            outline={completed}
            onClick={(): void => setPopupVisible(true)}>
            {completed ? 'Change' : 'Create account'}
          </Button>
        </ContentCard>
      </div>
      {popupVisible && <AccountPopup setPopupVisible={setPopupVisible} />}
    </>
  );
};

export default AccountStep;
