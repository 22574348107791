import { AxiosResponse } from 'axios';
import { checkNZUser } from 'src/lib/utils';
import paymentAPI from 'src/pages/deprecated/payment/api';

type Params = {
  isNZUser?: boolean;
};

export const stripeProductsApi = async (
  params?: Params
): Promise<AxiosResponse> => {
  const { isNZUser } = params ?? { isNZUser: checkNZUser() };

  const currencyParam = isNZUser ? 'nzd' : 'aud';
  try {
    return await paymentAPI.get(`stripe/products/${currencyParam}`);
  } catch (error) {
    console.error('Error fetching stripe products', error);
    return Promise.reject(error);
  }
};
