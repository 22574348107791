import React, { useEffect, useState, useContext, Fragment } from 'react';
import { PaymentMethod } from '@stripe/stripe-js';
import { Button } from 'reactstrap';
import { Product, Products } from '../../../deprecated/payment/types';
import styles from './extras-step.module.scss';
import ExtrasPreview from './extras-preview';
import ExtrasPopup from './extras-popup';
import { pushToArray } from '../../../../lib/utils';
import ContentCardLabel from '../../../../components/content/content-card-label';
import ContentCard from '../../../../components/content/content-card';
import { ToastContext } from '../../../../components/toast/toast';
import { IAppState } from 'src/store';
import { useSelector } from 'react-redux';
import { PLAN_ID } from 'src/pages/constants';

const additionalMealPlanOptionNominal = 'One-off meal plan';

interface OwnProps {
  paymentMethod: PaymentMethod | undefined;
  products: Products | undefined;
  setRegetProducts: (reget: boolean) => void;
}
type Props = OwnProps;

const ExtrasStep = ({
  paymentMethod,
  products,
  setRegetProducts,
}: Props): JSX.Element => {
  // Redux Selectors
  const [consumableProducts] = useState<Product[]>([]);
  const [selectedProduct, setSelectedProduct] = useState<Product>();
  const [selectedPriceID, setSelectedPriceID] = useState<string>('');

  const { addToast } = useContext(ToastContext);

  // Form State
  const [selectedPriceIDs, setSelectedPriceIDs] = useState<string[]>([]);
  const [totalPrice, setTotalPrice] = useState<number>(0);

  // Modal Visibility
  const [popupVisible, setPopupVisible] = useState<boolean>(false);
  const [successMessage, setSuccessMessage] = useState<string>('');

  // Upon Plan Selection and Plan Loading, add to outer scope
  useEffect(() => {
    if (successMessage) {
      addToast(successMessage, 'success', 5000);
      setSuccessMessage('');
      setSelectedPriceIDs([]);
      setSelectedProduct(undefined);
      setSelectedPriceID('');
      setRegetProducts(true);
    }
  }, [addToast, setRegetProducts, successMessage]);
  const user = useSelector((state: IAppState) => state.auth?.user);
  return (
    <>
      {products &&
        Object.entries(products)
          .sort()
          .map(([id, product]: [string, Product]) => {
            const isConsumable = Object.prototype.hasOwnProperty.call(
              product.metadata,
              'consumable'
            );
            if (isConsumable) {
              pushToArray(consumableProducts, product);
            }
            if (
              product.name === 'Social Amendment' &&
              (user?.plan?.plan_id || 0) === PLAN_ID.STANDARD
            ) {
              return <Fragment key={id} />;
            }
            return Object.values(product.prices).map((price): JSX.Element => {
              return isConsumable && price.active ? (
                <Fragment key={price.id}>
                  <ContentCardLabel>
                    <div>
                      {product.name.toUpperCase() ===
                      'Additional Meal Plan Option'.toUpperCase()
                        ? additionalMealPlanOptionNominal
                        : product.name}
                    </div>
                  </ContentCardLabel>
                  <ContentCard lighter className={styles.card}>
                    <ExtrasPreview
                      label={product.name}
                      qty={product?.quantity || 0}
                      setPopupVisible={setPopupVisible}
                    />
                    <Button
                      size="sm"
                      outline
                      onClick={(): void => {
                        setPopupVisible(true);
                        setSelectedProduct(product);
                        setSelectedPriceID(price.id);
                      }}>
                      Add
                    </Button>
                  </ContentCard>
                </Fragment>
              ) : (
                <Fragment key={price.id} />
              );
            });
          })}
      {popupVisible && selectedProduct && (
        <ExtrasPopup
          setSuccessMessage={setSuccessMessage}
          selectedProduct={selectedProduct}
          consumableProducts={consumableProducts}
          paymentMethod={paymentMethod}
          totalPrice={totalPrice}
          selectedPriceID={selectedPriceID}
          selectedPriceIDs={selectedPriceIDs}
          setSelectedPriceIDs={setSelectedPriceIDs}
          setPopupVisible={setPopupVisible}
          setTotalPrice={setTotalPrice}
          setSelectedProduct={setSelectedProduct}
        />
      )}
    </>
  );
};

export default ExtrasStep;
