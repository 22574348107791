import React, { useEffect } from 'react';
import { Box, ButtonBase, Stack } from '@mui/material';
import { Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';

// components
import HeaderNew from '../header-new/HeaderNew';
import {
  AppQRCode,
  handleStoreButtonClick,
} from 'src/components/app-qr-code/AppQRCode';

// styles and assets
import colors from 'src/styles/equalution/colors';
import SuccessImage from 'src/assets/images/success-page.png';
import SuccessImage2 from 'src/assets/images/success_mobile.png';
import PlaystoreLogo from 'src/assets/images/playstore-logo.png';
import AppstoreLogo from 'src/assets/images/appstore-logo.png';
import logo from 'src/assets/images/logo-new.svg';
import crossIcon from 'src/assets/icons/cross.svg';

// utils and redux
import { IAppState } from 'src/store';
import history from 'src/lib/history';
import { Coupon } from '../deprecated/payment/types';
import {
  setIsFitherProjectUser,
  setNoPaymentIntentAvailable,
} from 'src/store/signup';
import { getEcommerceDataProperties, useViewport } from 'src/lib/utils';
import { track, EVENTS } from 'src/util/track';
import { useAppSelector } from 'src/store/hooks';
import { optimizely } from 'src/setupOptimizely';

const title = `Congratulations! You're one step closer to your best self!`;
const subTitle = `Download our App to start your transformation now.`;

declare global {
  interface Window {
    ire: any;
  }
}

const SuccessPage = () => {
  const { isMobile } = useViewport();
  const dispatch = useDispatch();

  const dateTime = new Date().toISOString();

  // Redux Selectors
  const user = useSelector((state: IAppState) => state.auth?.user);
  const { selectedPlanId, allPlansSorted } = useAppSelector(
    state => state.signUp
  );
  const priceId: number = (history.location as any).state?.priceId;
  const totalPrice = (history.location as any).state.totalPrice;
  const coupon: Coupon = (history.location as any).state?.coupon;

  useEffect(() => {
    (async () => {
      if (!user || !priceId || !totalPrice) {
        return;
      }
      const orderId = `${priceId}_${user?.id}_${dateTime}`;
      const trackProperties =
        getEcommerceDataProperties(selectedPlanId, allPlansSorted, {
          transaction_id: orderId,
          coupon_id: coupon?.id,
          coupon_name: coupon?.name,
        }) ?? {};

      track(EVENTS.purchase, { ecommerce: null });
      track(EVENTS.purchase, trackProperties);

      window.ire(
        'trackConversion',
        33152,
        {
          orderId,
          customerId: user?.id,
          customerEmail: user?.email,
          customerStatus: user.status,
          currencyCode: 'AUD',
          orderPromoCode: coupon?.id || '',
          orderDiscount: ((coupon?.amountOff || 0) / 100).toFixed(2) || '0.00',
          items: [
            {
              sku: priceId,
              name: priceId,
              category: '',
              quantity: 1,
              subTotal: totalPrice || '0.00',
            },
          ],
        },
        {
          verifySiteDefinitionMatch: true,
        }
      );
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // cleanup redux states
  useEffect(() => {
    dispatch(setNoPaymentIntentAvailable(false));
    dispatch(setIsFitherProjectUser(false));
  }, [dispatch]);

  const handleButtonClick = () => {
    const url = window.location.origin;

    window.location.href = url;
  };

  useEffect(() => {
    optimizely.track('success_page_view'); // event to track for quiz flow conversion
  }, []);

  return isMobile ? (
    <Stack
      height={'100vh'}
      alignItems={'center'}
      sx={{
        background: colors.OFF_WHITE,
        position: 'relative',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        flex: 1,
      }}>
      <Box
        position={'absolute'}
        bottom={'-60px'}
        sx={{
          width: '100%',
          background: `url(${SuccessImage2})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          height: '435px',
        }}
      />
      <Stack
        zIndex={10}
        width={'90%'}
        mt={'12px'}
        marginX={'16px'}
        direction={'row'}
        alignItems={'center'}
        justifyContent={'space-between'}>
        <a href="https://joinequ.com/" target="_blank" rel="noreferrer">
          <img src={logo} alt="logo" style={{ height: 32, paddingTop: 10 }} />
        </a>
        <ButtonBase
          onClick={handleButtonClick}
          sx={{ height: '40px', width: '40px' }}>
          <img src={crossIcon} alt="cross" />
        </ButtonBase>
      </Stack>
      <Stack>
        <Stack paddingX={'50px'} mt={'10px'} gap={'24px'}>
          <Box
            fontWeight={700}
            lineHeight={'30px'}
            textAlign={'center'}
            fontSize={'22px'}>
            {title}
          </Box>
          <Box
            fontWeight={700}
            textAlign={'center'}
            lineHeight={'24px'}
            fontSize={'18px'}>
            {subTitle}
          </Box>

          <Stack gap={'24px'} mt={'30px'}>
            <ButtonBase
              disableRipple
              onClick={() => handleStoreButtonClick('appstore')}>
              <img src={AppstoreLogo} alt="appstore" height={'50px'} />
            </ButtonBase>
            <ButtonBase
              disableRipple
              onClick={() => handleStoreButtonClick('playstore')}>
              <img src={PlaystoreLogo} alt="playstore" height={'50px'} />
            </ButtonBase>
            <Stack alignItems={'center'}>
              <Button
                size="sm"
                style={{
                  width: '200px',
                  marginTop: '30px',
                  position: 'relative',
                }}
                onClick={handleButtonClick}>
                Back to homepage
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  ) : (
    <Stack height={'100vh'} direction={'row'}>
      <Stack
        flex={1}
        sx={{
          display: 'flex',
          flex: 1,
          overflow: 'hidden',
          background: `url(${SuccessImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}></Stack>
      <Stack
        flex={1}
        zIndex={10}
        sx={{ background: colors.CREAM_HALF, display: 'flex', flex: 1 }}>
        <Box ml={'32px'}>
          <HeaderNew module="login" />
        </Box>
        <Stack width={'530px'} paddingLeft={'80px'} gap={'32px'} mt={'40px'}>
          <Box fontWeight={800} lineHeight={'50px'} fontSize={'36px'}>
            {title}
          </Box>
          <Box fontWeight={600} lineHeight={'32px'} fontSize={'24px'}>
            {subTitle}
          </Box>
          <Stack alignItems={'center'}>
            <AppQRCode />

            <Button
              block
              style={{
                width: '260px',
                position: 'relative',
                marginBlock: '30px',
              }}
              onClick={handleButtonClick}>
              Back to homepage
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SuccessPage;
