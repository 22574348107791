import React, { FC } from 'react';
import { Box } from '@mui/material';
import { Button } from 'reactstrap';
import styles from './circle-button.module.scss';

interface CircleButtonProps {
  disabled?: boolean;
  onPress?: () => void;
}

export const CircleButton: FC<CircleButtonProps> = ({ disabled, onPress }) => {
  return (
    <Box>
      <Button
        size="sm"
        disabled={disabled}
        className={styles.button}
        onClick={onPress}>
        <Box className={styles.icon} />
      </Button>
    </Box>
  );
};
