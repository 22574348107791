import React, { FunctionComponent, PropsWithChildren } from 'react';

type Props = {
  className?: string;
  innerClassName?: string;
  collapseAt?: 'sm' | 'md' | 'lg' | 'xl';
};

const ContentWrapper: FunctionComponent<PropsWithChildren<Props>> = ({
  children,
  className,
  innerClassName,
  collapseAt,
}: PropsWithChildren<Props>) => {
  return (
    <div
      className={`contentWrapper ${
        collapseAt ? `contentWrapper-${collapseAt}` : ''
      } ${className}`}>
      <div className={`innerWrapper ${innerClassName}`}>{children}</div>
    </div>
  );
};

export default ContentWrapper;
