import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { IAppState } from 'src/store';
import { Box, Button, Typography } from '@mui/material';

import colors from 'src/styles/equalution/colors';
import BoostImg from 'src/assets/images/boost.png';
import GoalsImg from 'src/assets/images/goals.png';
import SleepImg from 'src/assets/images/sleep.png';
import Testimonial1 from 'src/assets/images/testimonials/testimonial1.png';
import Testimonial2 from 'src/assets/images/testimonials/testimonial2.png';
import Testimonial3 from 'src/assets/images/testimonials/testimonial3.png';
import Testimonial4 from 'src/assets/images/testimonials/testimonial4.png';
import Testimonial5 from 'src/assets/images/testimonials/testimonial5.png';
import { GoalSelectionButton } from '../goal-selection-button/GoalSelectionButton';
import { goalDetails, motivationDetails } from 'src/pages/constants';
import tooltipArrow from 'src/assets/images/tooltip_arrow.svg';
import { useHistory } from 'react-router-dom';
import SwiperTestimonial from './SwiperTestimonial';
import { useQuizTracking } from '../../hooks';

interface PlanObj {
  id: number;
  name: string;
  description: string;
}

const PlanSummary = () => {
  const { quizData } = useSelector((state: IAppState) => state.quiz);
  const [selectedProgram, setSelectedProgram] = React.useState<PlanObj>();
  const [programsList, setProgramsList] = React.useState<PlanObj[]>([]);
  const history = useHistory();

  const trackEvent = useQuizTracking();

  useEffect(() => {
    createProgramsList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleNavigate = () => {
    let planName = 'premium';
    let planDuration = 3;
    if (quizData.weight && quizData.target_weight) {
      if (quizData.weight - quizData.target_weight >= 7) {
        planDuration = 6;
      } else if (quizData.weight - quizData.target_weight >= 2) {
        planDuration = 3;
      } else {
        planDuration = 1;
      }
    }

    if (quizData.located === 'Australia') {
      planName = 'standard';
    }
    if (quizData.problem_hitting_goal === 1) {
      planName = 'premium';
    }
    if (quizData.located === 'New Zealand') {
      planName = 'standard';
    }
    trackEvent('StartTransformation', {
      program: planName,
      duration: planDuration,
      email: quizData.email,
      country: quizData.located,
    });

    const params = {
      planType: planName,
      duration: planDuration,
      country: quizData.located,
      email: quizData.email,
      firstName: quizData.FName,
      lastName: quizData.LName,
    };

    const queryString = Object.entries(params)
      .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
      .join('&');

    const url = `/signup?${queryString}`;

    history.replace(url);
  };
  const createProgramsList = () => {
    const programsArr: PlanObj[] = [];
    let index = 0;
    //goal program as first item
    goalDetails.forEach((item: PlanObj) => {
      if (item.id === quizData.goal_id) {
        programsArr.push({ ...item, id: index });
        index++;
      }
    });
    quizData.goal_adherence_challenges?.forEach(goalVal => {
      motivationDetails.forEach(item => {
        if (item.id === goalVal) {
          programsArr.push({ ...item, id: index });
          index++;
        }
      });
    });
    setProgramsList(programsArr);
    if (programsArr.length) {
      setSelectedProgram(programsArr[0]);
    }
  };

  const getLeftPosition = () => {
    if (programsList.length === 4) {
      if (selectedProgram?.id === 0) {
        return '63px';
      } else if (selectedProgram?.id === 1) {
        return '250px';
      } else if (selectedProgram?.id === 2) {
        return '342px';
      } else {
        return '153px';
      }
    } else {
      if (selectedProgram?.id === 0 || selectedProgram?.id === 3) {
        return '63px';
      } else if (selectedProgram?.id === 1) {
        return '184px';
      } else {
        return '302.5px';
      }
    }
  };

  const TwoRowSelectionBoxes = () => {
    return (
      <>
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          {programsList.map((item, index) => {
            if (index > 1) {
              return <></>;
            } else {
              return (
                <GoalSelectionButton
                  key={index}
                  title={item?.name}
                  isSelected={selectedProgram?.id === item.id}
                  onPress={() => setSelectedProgram(item)}
                  addExtraPaddingRight={index === 0}
                />
              );
            }
          })}
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
          {programsList.map((item, index) => {
            if (index < 2) {
              return <></>;
            } else {
              return (
                <GoalSelectionButton
                  key={index}
                  title={item?.name}
                  showReverseOrder={true}
                  isSelected={selectedProgram?.id === item.id}
                  onPress={() => setSelectedProgram(item)}
                  addExtraPaddingLeft={index === 2}
                />
              );
            }
          })}
        </Box>
      </>
    );
  };
  const SingleRowSelectionBoxes = () => {
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        {programsList.map((item, index) => {
          return (
            <GoalSelectionButton
              key={index}
              title={item?.name}
              isSelected={selectedProgram?.id === item.id}
              onPress={() => setSelectedProgram(item)}
            />
          );
        })}
      </Box>
    );
  };

  return (
    <Box sx={{ maxWidth: '420px' }}>
      {quizData.FName && (
        <Typography sx={{ fontSize: '26px', fontWeight: 600 }}>
          {quizData.FName}'s Program
        </Typography>
      )}
      <Typography
        sx={{ fontSize: '18px', fontWeight: 500, marginBottom: '15px' }}>
        What success looks like for you:
      </Typography>
      {programsList?.length <= 3 ? (
        <SingleRowSelectionBoxes />
      ) : (
        <TwoRowSelectionBoxes />
      )}
      <Box
        sx={{
          position: 'relative',
          border: `1px solid ${colors.SALAD}AA`,
          borderRadius: '20px',
          padding: '12px 16px',
          backgroundColor: '#fdecd2',
        }}>
        <Typography
          sx={{
            fontSize: '16px',
            fontWeight: 400,
            color: colors.OFF_BLACK,
          }}>
          {selectedProgram?.description}
        </Typography>
        <img
          src={tooltipArrow}
          alt="cross"
          style={{
            position: 'absolute',
            top: -12,
            left: getLeftPosition(),
            color: colors.SALAD,
          }}
        />
      </Box>
      <Button
        variant="contained"
        onClick={handleNavigate}
        size="large"
        sx={{
          textTransform: 'none',
          borderRadius: '100px',
          marginTop: 4,
          width: '100%',
          height: '55px',
          alignSelf: 'center',
          marginBottom: 2,
        }}>
        Start my transformation!
      </Button>
      <Typography
        sx={{
          fontSize: '20px',
          fontWeight: 400,
          color: colors.OFF_BLACK,
          margin: '20px 0px',
        }}>
        Balanced nutrition to support all aspects of your wellbeing
      </Typography>
      <Box
        sx={{
          padding: '25px 36px',
          borderRadius: '20px',
          background: colors.CREAM,
          display: 'flex',
          flexDirection: 'column',
          width: '280px',
          margin: '20px auto',
        }}>
        <Box sx={{ display: 'flex', alignItems: 'center', margin: '5px 0px' }}>
          <img src={GoalsImg} height={40} alt="icon" />
          <Typography
            sx={{ fontSize: '14px', fontWeight: 500, marginLeft: '20px' }}>
            Supports weight goals
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', margin: '5px 0px' }}>
          <img src={BoostImg} height={40} alt="icon" />
          <Typography
            sx={{ fontSize: '14px', fontWeight: 500, marginLeft: '20px' }}>
            Boosts mood and energy
          </Typography>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', margin: '5px 0px' }}>
          <img src={SleepImg} height={40} alt="icon" />
          <Typography
            sx={{ fontSize: '14px', fontWeight: 500, marginLeft: '20px' }}>
            Improves sleep quality
          </Typography>
        </Box>
      </Box>
      <Typography
        sx={{
          fontSize: '20px',
          fontWeight: 400,
          color: colors.OFF_BLACK,
          margin: '20px 0px',
        }}>
        Expert advice at your fingertips
      </Typography>
      <Typography
        sx={{
          fontSize: '18px',
          fontWeight: 400,
          color: colors.OFF_BLACK,
          marginTop: '20px',
          padding: '20px',
          borderRadius: '20px',
          border: `1px solid ${colors.SALAD}33`,
          backgroundColor: colors.OFF_WHITE,
        }}>
        We’ve helped thousands of people hit their transformation goals!
      </Typography>
      <Button
        variant="contained"
        onClick={handleNavigate}
        size="large"
        sx={{
          textTransform: 'none',
          borderRadius: '100px',
          marginTop: 4,
          width: '100%',
          height: '55px',
          alignSelf: 'center',
          marginBottom: 2,
        }}>
        Start my transformation!
      </Button>
      <Typography
        sx={{
          fontSize: '20px',
          fontWeight: 400,
          color: colors.OFF_BLACK,
          marginTop: '20px',
        }}>
        What our clients say
      </Typography>

      <SwiperTestimonial
        data={[
          {
            comment:
              'Equ not only changed my body they also helped me shift my entire mindset, they did for me what I tried to do for myself for so many years in months and no amount of words could explain how grateful I am. This is a game changer.',
            by: '-Ebony',
            image: Testimonial1,
          },
          {
            comment:
              "“I wasn't able to stick with a food program (not calling it diet) for so long and I have been with Equ for 45 weeks now. I'm able to enjoy food. I don't see my nutrition as a diet, but as a lifestyle. I can socialise with my family and friends without feeling guilty.”",
            by: '-Ana',
            image: Testimonial2,
          },
          {
            comment:
              '“It’s been a total game changer for me, as someone who is usually a yo yo dieter to have found something I find totally sustainable is amazing. I haven’t gone without anything, or ever felt restricted in my whole time with Equ.”',
            by: '-Sarah',
            image: Testimonial3,
          },
          {
            comment:
              '“Never in my wildest dreams did I expect to achieve the results I have, but most importantly build a healthier and more balanced lifestyle to implement into my every day routine.”',
            by: '-Peta',
            image: Testimonial4,
          },
          {
            comment:
              '“I have realised there are no ‘good or bad’ foods; it’s all about finding a balance and knowing that you can still dine out and your hard work hasn’t been ‘undone’. I have tried many FAD diets, but nothing compares to this. I wouldn’t even call this a diet.”',
            by: '-Emily',
            image: Testimonial5,
          },
        ]}
      />
      <Button
        variant="contained"
        onClick={handleNavigate}
        size="large"
        sx={{
          textTransform: 'none',
          borderRadius: '100px',
          marginTop: 4,
          width: '100%',
          height: '55px',
          alignSelf: 'center',
          marginBottom: 2,
        }}>
        Start my transformation!
      </Button>
    </Box>
  );
};
export default PlanSummary;
