import React, { FC } from 'react';
import { ButtonBase } from '@mui/material';
import backIcon from 'src/assets/icons/arrow-left.svg';
import backIconLight from 'src/assets/icons/arrow-left-light.svg';
import styles from './back-button.module.scss';

interface BackButtonProps {
  disabled?: boolean;
  onPress?: () => void;
  isLight?: boolean;
}

export const BackButton: FC<BackButtonProps> = ({
  disabled,
  onPress,
  isLight = false,
}) => {
  return (
    <ButtonBase
      disabled={disabled}
      onClick={onPress}
      centerRipple
      className={styles.backButton}>
      <img
        src={isLight ? backIconLight : backIcon}
        alt="back icon"
        className={styles.icon}
      />
    </ButtonBase>
  );
};
