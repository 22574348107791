import TagManager from 'react-gtm-module';

export const EVENTS = {
  purchase: 'purchase',
  subscriptionPlan: 'subscription_plan_selection',
  createAccount: 'account_created',
  loginAccount: 'login_account',
  promoCode: 'promo_code_applied',
  programPurchase: 'program_purchase',
  paymentAdded: 'payment_added',
  updateSubscription: 'update_subscription',
  changePlan: 'change_plan',
  challengeOptIn: 'challenge_opt_in',
  futureCommencement: 'future_commencement_opted',
  appstoreButtonClick: 'application_store_click',
  planSelected: 'plan_selected',
  fitHerProjectUser: 'fither_project_user_onboarded',
  fitHerUserAccountCreated: 'fit_her_user_account_created',
  fitHerUserSubscribed: 'fither_user_subscribed',
  viewItem: 'view_item',
  beginCheckout: 'begin_checkout',
  addPaymentInfo: 'add_payment_info',
  typeformSubmit: 'typeform_submit',
  quizStart: 'quiz_start',
  quizStep: 'quiz_step', // QUIZ_STEP_1 -> QUIZ_STEP_21
  quizComplete: 'quiz_complete',
};

/**
 * Track function for tracking
 *
 * @param eventName
 * @param properties
 * @returns
 */
export function track(
  eventName: string,
  properties?: { [key: string]: any } | undefined
) {
  if (process.env.CONSOLE_LOG_EVENTS === 'true') {
    console.log(eventName, properties);
  }

  TagManager.dataLayer({
    dataLayer: {
      event: eventName,
      ...properties,
    },
  });
}
