import React, { useState } from 'react';
import { Button } from 'reactstrap';
import { PaymentMethod, Stripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import ContentCard from '../../../../../components/content/content-card';
import PaymentPreview from '../../../../signup/steps/payment/payment-preview';
import PaymentPopup from './update-payment-popup';
import User from '../../../../../models/User';
import ContentCardLabel from '../../../../../components/content/content-card-label';
import styles from './update-payment.module.scss';

interface OwnProps {
  user: User | undefined;
  stripePromise: Promise<Stripe | null>;
  paymentMethod: PaymentMethod | undefined;
  setPaymentMethod: (paymentMethod: PaymentMethod) => void;
  completed: boolean;
}

type Props = OwnProps;

const UpdatePayment = ({
  user,
  stripePromise,
  paymentMethod,
  setPaymentMethod,
  completed,
}: Props): JSX.Element => {
  const [popupVisible, setPopupVisible] = useState<boolean>(false);

  return (
    <>
      <div>
        <ContentCardLabel className={styles.cardLabel}>
          <div>Payment details</div>
        </ContentCardLabel>

        <ContentCard lighter className={styles.card}>
          <PaymentPreview paymentMethod={paymentMethod} />
          <Button
            size="sm"
            outline={completed}
            onClick={(): void => setPopupVisible(true)}
            disabled={!user}>
            {completed ? 'Change' : 'Enter payment details'}
          </Button>
        </ContentCard>
      </div>

      {popupVisible && !!user && (
        // TODO Add Custom Fonts when deployed with absolute font urls
        <Elements stripe={stripePromise}>
          <PaymentPopup
            user={user}
            setPaymentMethod={setPaymentMethod}
            setPopupVisible={setPopupVisible}
          />
        </Elements>
      )}
    </>
  );
};

export default UpdatePayment;
