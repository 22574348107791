import React, { useEffect, useState } from 'react';
import { PaymentMethod, loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { useSelector } from 'react-redux';
import { IAppState } from '../../../../store';
import styles from './payment-step.module.scss';
import CardComponent from './card-component';
import { useViewport } from 'src/lib/utils';
import { Coupon, Plans } from 'src/pages/deprecated/payment/types';
import { PLANS } from 'src/pages/constants';
import { TagData } from 'src/store/signup-workflow/types';
import OrderComponent from '../../components/order-component/OrderComponent';

interface OwnProps {
  paymentMethod: PaymentMethod | undefined;
  setPaymentMethod: (paymentMethod: PaymentMethod) => void;
  selectedPlan: string;
  coupon?: Coupon;
  planRequest: string | null;
  plans: Plans;
  priceId: string;
  setPriceId: (id: string) => void;
  productId: string;
  setCoupon: (coupon: Coupon | undefined) => void;
  futureCommencement: boolean;
  setFutureCommencement?: (futureCommencement: boolean) => void;
  prefillCode?: string;
  setSelectedPlan: (id: string) => void;
  handleSubmit: () => void;
  isChallengeAccepted: boolean;
  tagId: number;
  challengeObject: TagData | null;
  setErrorMessage: (error: string) => void;
  errorMessage: string;
  weeklyMealPlanQty: number;
  setWeeklyMealPlanQty: (qty: number) => void;
  setWalletPaymentIntentId: (paymentIntentId: string) => void;
  noIntentAvailable: boolean;
  setNoIntentAvailable: (noIntentAvailable: boolean) => void;
}

export const validateCoupons = (
  priceId: string,
  couponMetadata: any = {},
  productId?: string,
  couponSupportingProducts?: Array<string> | undefined
): string | undefined => {
  // check if coupon is restricted to any specific product
  if (
    productId &&
    !!couponSupportingProducts?.length &&
    !couponSupportingProducts.includes(productId)
  ) {
    return 'This coupon is invalid for this subscription';
  }

  if (!couponMetadata.priceId) {
    return;
  }

  const priceIds = (couponMetadata?.priceId || '')
    .split(',')
    .map((c: string) => c.trim());
  // If the priceId is not in the metadata.priceIds list show error
  if (priceIds.includes(priceId) === false) {
    return (
      couponMetadata.priceIdError ||
      'This coupon is invalid for this subscription'
    );
  }
};

const PaymentStep = ({
  setPaymentMethod,
  selectedPlan,
  plans,
  coupon,
  planRequest,
  priceId,
  setPriceId,
  productId,
  setCoupon,
  futureCommencement,
  setFutureCommencement,
  setSelectedPlan,
  handleSubmit,
  paymentMethod,
  isChallengeAccepted,
  tagId,
  challengeObject,
  setErrorMessage,
  errorMessage,
  setWeeklyMealPlanQty,
  weeklyMealPlanQty,
  setWalletPaymentIntentId,
  noIntentAvailable,
  setNoIntentAvailable,
}: OwnProps) => {
  // Redux Selectors
  const stripePromise = loadStripe(
    process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
  );
  const { isMobile } = useViewport();

  const user = useSelector((state: IAppState) => state.auth?.user);

  const [isInitialRender, setIsInitialRender] = useState(true);

  const [popupVisible, setPopupVisible] = useState<boolean>(false);

  const isAuthenticated = useSelector(
    (state: IAppState) => state.auth?.isAuthenticated
  );

  useEffect(() => {
    if (
      isAuthenticated &&
      isInitialRender &&
      user?.status !== 'PROSPECT' &&
      user?.status !== 'INACTIVE' &&
      user?.status !== 'FINAL' &&
      user?.payment_provider === 'Stripe'
    ) {
      setIsInitialRender(false);
    }
  }, [isAuthenticated, user, isInitialRender]);

  const plan = plans && plans[selectedPlan];
  const variant = plan?.mealPlanVariants[weeklyMealPlanQty];

  useEffect(() => {
    setPriceId(variant.id);
  }, [setPriceId, variant]);

  let discountAmount = 0;

  if (coupon) {
    if (coupon.amountOff > 0) {
      discountAmount = coupon.amountOff / 100;
    } else if (coupon.percentOff > 0) {
      discountAmount =
        parseFloat(variant?.unitAmount) * (coupon.percentOff / 100);
    }
  }

  const finalPrice =
    parseFloat(variant?.unitAmount) - discountAmount > 0
      ? parseFloat(variant?.unitAmount) - discountAmount
      : 0;

  const findPlanName = (planId: string): string => {
    if (planId.includes('Standard')) {
      return PLANS.STANDARD_PLAN;
    }
    if (planId.includes('Premium')) {
      return PLANS.PREMIUM_PLAN;
    }
    if (planId.includes('Daily')) {
      return PLANS.DAILY_TARGETS_ONLY_PLAN;
    }
    return '';
  };

  return (
    <>
      <div className={styles.mainRow}>
        <div className={styles.cardColumn}>
          <p className={styles.pageTitle}>
            {isMobile ? 'Select your payment' : 'Payment Summary'}
          </p>
          <Elements stripe={stripePromise}>
            <CardComponent
              selectedPlan={selectedPlan}
              setPaymentMethod={setPaymentMethod}
              handleSubmit={handleSubmit}
              paymentMethod={paymentMethod}
              priceId={variant.id}
              finalPrice={finalPrice}
              planName={findPlanName(selectedPlan)}
              coupon={coupon}
              isChallengeAccepted={isChallengeAccepted}
              futureCommencement={futureCommencement}
              tagId={tagId}
              challengeObject={challengeObject}
              productId={productId}
              setErrorMessage={setErrorMessage}
              errorMessage={errorMessage}
              setWalletPaymentIntentId={setWalletPaymentIntentId}
              popupVisible={popupVisible}
              noIntentAvailable={noIntentAvailable}
              setNoIntentAvailable={setNoIntentAvailable}
            />
          </Elements>
        </div>
        <OrderComponent
          selectedPlan={selectedPlan}
          weeklyMealPlanQty={weeklyMealPlanQty}
          plans={plans}
          coupon={coupon}
          planRequest={planRequest ?? ''}
          priceId={priceId}
          setCoupon={setCoupon}
          futureCommencement={futureCommencement}
          setFutureCommencement={setFutureCommencement}
          setWeeklyMealPlanQty={setWeeklyMealPlanQty}
          setSelectedPlan={setSelectedPlan}
          plan={plan}
          finalPrice={finalPrice}
          planName={findPlanName(plan?.id)}
          popupVisible={popupVisible}
          setPopupVisible={setPopupVisible}
        />
      </div>
    </>
  );
};

export default PaymentStep;
