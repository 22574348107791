import React, { FC, useMemo } from 'react';
import { Box, Stack } from '@mui/material';
import { Button } from 'reactstrap';

import PlanDetail from 'src/pages/subscription-new/components/plan-detail';
import SignUpStepper from 'src/components/stepper';
import PlanDetailInfoBox from 'src/pages/subscription-new/components/plan-detail-info';
import SubscriptionDurationContainer from '../components/subscription-duration-container/SubscriptionDurationContainer';
import MealAddons from '../components/meal-addons/MealAddons';
import { Layout } from 'src/components/layout-wrapper/Layout';

import {
  isUserFromAustralia,
  returnFilteredPlans,
  useViewport,
} from 'src/lib/utils';
import colors from 'src/styles/equalution/colors';
import { SubscriptionPageProps } from '../SubscriptionPage';
import { useAppDispatch, useAppSelector } from 'src/store/hooks';
import { setAddonWeek, setAvailablePlans } from 'src/store/signup';
import { PlanType } from 'src/pages/constants';

const PremiumPlanSubscriptionPage: FC<SubscriptionPageProps> = ({
  planType,
  setPlanType,
  isStandardPlan,
  onCheckoutClick,
  showTwoStepProcess,
  country,
}) => {
  const dispatch = useAppDispatch();

  const { addonWeek, selectedPlanId, allPlansSorted } = useAppSelector(
    state => state.signUp
  );

  const { isMobile, isDesktop } = useViewport();

  const isNewZealandUser = country === 'New Zealand';

  const headerTitle = (): string => {
    if (isStandardPlan) {
      if (isMobile) {
        return 'A flexible option for you';
      } else {
        return 'Update your program';
      }
    } else {
      return 'Your personalised program';
    }
  };

  const changePlanType = (planType: PlanType) => {
    setPlanType(planType);
    dispatch(setAddonWeek(1));
    const filteredPlans = returnFilteredPlans(
      allPlansSorted,
      planType,
      isNewZealandUser
    );
    dispatch(setAvailablePlans([...filteredPlans].reverse()));
  };

  const MealPlanAddon = useMemo(
    () => (
      <>
        {!isStandardPlan && (
          <MealAddons
            addonWeek={addonWeek}
            setAddonWeek={(count: number) => dispatch(setAddonWeek(count))}
          />
        )}
      </>
    ),
    [addonWeek, dispatch, isStandardPlan]
  );

  return (
    <Layout module="login">
      <Stack
        alignItems={'center'}
        sx={{
          padding: '1.5rem',
          marginX: 'auto',
          ...(isDesktop && {
            width: '1000px',
          }),
          ...(isMobile && {
            width: '320px',
          }),
        }}>
        <Stack mb={isMobile ? '24px' : '64px'} alignItems={'center'}>
          <Box width={showTwoStepProcess ? 70 : 100}>
            <SignUpStepper
              activeStep={1}
              steps={showTwoStepProcess ? ['1', '2'] : ['1', '2', '3']}
            />
          </Box>
          <Stack alignItems={'center'}>
            <Box
              textAlign={'center'}
              sx={{
                fontSize: isMobile ? '20px' : '29px',
                fontWeight: 800,
                marginTop: '20px',
              }}>
              {headerTitle()}
            </Box>
            {isStandardPlan && !isMobile && (
              <Box
                width={'65%'}
                textAlign={'center'}
                sx={{
                  fontSize: '16px',
                  fontWeight: 300,
                  marginTop: '16px',
                }}>
                Benefit from personalised guidance to accelerate your path
                towards reaching your goals.
              </Box>
            )}
          </Stack>
        </Stack>

        <Stack
          alignItems={'flex-start'}
          justifyContent={'center'}
          direction={'row'}
          flexWrap={'wrap'}
          gap={'40px'}>
          <Stack
            {...(isMobile ? {} : { flex: 1 })}
            maxWidth={isMobile ? '320px' : '470px'}
            alignItems={'center'}
            gap={'32px'}>
            <PlanDetail />
            {isMobile && <SubscriptionDurationContainer planType={planType} />}
            {MealPlanAddon}
          </Stack>

          <Stack
            {...(isMobile ? {} : { flex: 1 })}
            alignItems={'flex-start'}
            gap={'32px'}>
            {!isMobile && <SubscriptionDurationContainer planType={planType} />}

            {!isStandardPlan && isUserFromAustralia() && (
              <PlanDetailInfoBox
                title="Not ready to commit to a full program?"
                description="Our Standard plan unlocks access to all the essential tools of the program, offering the complete suite of features, excluding 1:1 coaching."
                onButtonClick={() => {
                  changePlanType('standard');
                }}
              />
            )}

            {!isStandardPlan && (
              <Button
                style={{
                  width: '100%',
                }}
                size="md"
                disabled={!selectedPlanId}
                onClick={onCheckoutClick}>
                Continue to Checkout
              </Button>
            )}
          </Stack>
        </Stack>
        {isStandardPlan && (
          <Stack
            mt={isMobile ? '24px' : '64px'}
            direction={'row'}
            gap={isMobile ? '20px' : '40px'}
            alignItems={'center'}
            justifyContent={'center'}
            flexWrap={'wrap-reverse'}>
            <Button
              style={{
                color: colors.BLUEBERRY_HALF,
                background: colors.ORANGE_HALF,
                width: 300,
                border: 'none',
              }}
              size="md"
              onClick={() => {
                changePlanType('premium');
              }}>
              Back
            </Button>

            <Button
              style={{
                width: 300,
              }}
              size="md"
              disabled={!selectedPlanId}
              onClick={onCheckoutClick}>
              Continue to Checkout
            </Button>
          </Stack>
        )}
        <Box height={'100px'} />
      </Stack>
    </Layout>
  );
};

export default PremiumPlanSubscriptionPage;
