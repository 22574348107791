import React, { ReactNode } from 'react';
import { FormFeedback, FormGroup, Input, Label } from 'reactstrap';
import { FieldProps } from 'formik';

interface OwnProps {
  label: string;
  fieldOnly: boolean;
}

type Props = OwnProps;

const TextInput = ({
  label,
  fieldOnly,
  field: { name, onChange, onBlur },
  form: { touched, errors },
  ...attributes
}: Props & FieldProps): JSX.Element => {
  return (
    <FormGroup
      className={!!touched[name] && !!errors[name] ? 'is-invalid' : ''}>
      {!fieldOnly && (
        <div className="label-feedback">
          <Label for={name}>{label}</Label>
          <FormFeedback valid>Success</FormFeedback>
          <FormFeedback valid={false}>{errors[name] as ReactNode}</FormFeedback>
        </div>
      )}
      <Input
        type="text"
        name={name}
        onChange={onChange}
        onBlur={onBlur}
        {...attributes}
      />
    </FormGroup>
  );
};

export default TextInput;
