import React, { FormEvent, useMemo, useState } from 'react';
import {
  Alert,
  Button,
  Form,
  FormFeedback,
  FormGroup,
  Label,
} from 'reactstrap';

// stripe imports
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import {
  PaymentMethod,
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js';

// components & utils
import Popup from '../../../../../components/content/popup/popup';
import ContentSection from '../../../../../components/content/content-section';
import ContentWrapper from '../../../../../components/content/content-wrapper';
import styles from './update-payment-popup.module.scss';
import useResponsiveFontSize from '../../../../../components/payments/stripe/useResponsiveFontSize';
import User from '../../../../../models/User';
import paymentAPI from '../../../../deprecated/payment/api';

interface OwnProps {
  user: User;
  setPaymentMethod: (paymentMethod: PaymentMethod) => void;
  setPopupVisible: (val: boolean) => void;
}

type Props = OwnProps;

const useOptions = (): any => {
  useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: '16px',
          color: '#1E202E',
          fontFamily: 'Lexend, sans-serif',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#E8594D',
        },
      },
    }),
    []
  );

  return options;
};

const UpdatePaymentPopup = ({
  user,
  setPaymentMethod,
  setPopupVisible,
}: Props): JSX.Element => {
  const cardErrorMessageTimeout = undefined;
  const cvcErrorMessageTimeout = undefined;
  const expiryErrorMessageTimeout = undefined;

  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const [errorMessage, setErrorMessage] = useState<undefined | string>(
    undefined
  );
  const [cardErrorMessage, setCardErrorMessage] = useState<undefined | string>(
    undefined
  );
  const [cvcErrorMessage, setCvcErrorMessage] = useState<undefined | string>(
    undefined
  );
  const [expiryErrorMessage, setExpiryErrorMessage] = useState<
    undefined | string
  >(undefined);

  const [hasCard, setHasCard] = useState<boolean>(false);
  const [hasExpiry, setHasExpiry] = useState<boolean>(false);
  const [hasCVC, setHasCVC] = useState<boolean>(false);

  const [cardBrand, setCardBrand] = useState<string>('');

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async (
    event: FormEvent<HTMLFormElement>
  ): Promise<void> => {
    event.preventDefault();

    setErrorMessage(undefined);
    setIsLoading(true);

    if (stripe && elements) {
      const cardElement = elements.getElement(CardNumberElement);

      if (cardElement) {
        try {
          const { data } = await paymentAPI.post('stripe/customer/create', {
            email: user.email,
            userID: user.id,
          });
          const { paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: cardElement,
          });
          if (paymentMethod) {
            await paymentAPI.post('stripe/paymentMethods/update', {
              paymentMethodID: paymentMethod.id,
              customerID: data.customer.id,
            });
            setPaymentMethod(paymentMethod);
          }
          setIsLoading(false);
          setPopupVisible(false);
        } catch (e) {
          console.error('EXCEPTION', e);
          setIsLoading(false);
          setErrorMessage(
            'There was an error processing your payment details. Please try again or contact us.'
          );
        }
      }
    }
  };

  const errorMessageTimeoutHandler = (
    timeoutVariable: unknown,
    event:
      | StripeCardCvcElementChangeEvent
      | StripeCardNumberElementChangeEvent
      | StripeCardExpiryElementChangeEvent,

    errorMessageSetFunction: Function
  ): void => {
    if (timeoutVariable) {
      clearTimeout(timeoutVariable as NodeJS.Timeout);
    }

    setTimeout(() => {
      if (
        !event.complete ||
        (typeof event.error !== 'undefined' && event.error.message)
      ) {
        errorMessageSetFunction(event.error ? event.error.message : undefined);
      } else {
        errorMessageSetFunction(undefined);
      }
    }, 500);
  };

  return (
    <Popup>
      <ContentWrapper className={styles.background} collapseAt="sm">
        <ContentSection className={styles.paymentPopup} collapseAt="sm">
          <Button
            className={styles.close}
            onClick={(): void => setPopupVisible(false)}
          />

          <h3>Payment</h3>

          <div
            className={`${styles.cardBrands} ${
              cardBrand !== '' && styles.active
            }`}>
            <div
              className={`${styles.cardBrand} ${styles.visa} ${
                cardBrand === 'visa' && styles.selected
              }`}
            />
            <div
              className={`${styles.cardBrand} ${styles.amex} ${
                cardBrand === 'amex' && styles.selected
              }`}
            />
            <div
              className={`${styles.cardBrand} ${styles.discover} ${
                cardBrand === 'discover' && styles.selected
              }`}
            />
            <div
              className={`${styles.cardBrand} ${styles.mastercard} ${
                cardBrand === 'mastercard' && styles.selected
              }`}
            />
          </div>

          <Form onSubmit={handleSubmit}>
            <FormGroup className={cardErrorMessage ? 'is-invalid' : ''}>
              <div className="label-feedback">
                <Label>Card number</Label>
                <FormFeedback valid>Success</FormFeedback>
                <FormFeedback valid={false}>{cardErrorMessage}</FormFeedback>
              </div>
              <CardNumberElement
                options={options}
                className={`${styles.input} ${
                  cardErrorMessage && styles.invalid
                }`}
                onChange={(event: StripeCardNumberElementChangeEvent): void => {
                  setCardBrand(event.brand);
                  errorMessageTimeoutHandler(
                    cardErrorMessageTimeout,
                    event,
                    (message: string) => {
                      if (event.complete) {
                        setHasCard(true);
                      } else {
                        setHasCard(false);
                        setCardErrorMessage(message);
                      }
                    }
                  );
                }}
              />
            </FormGroup>

            <FormGroup className={expiryErrorMessage ? 'is-invalid' : ''}>
              <div className="label-feedback">
                <Label>Expiry date</Label>
                <FormFeedback valid>Success</FormFeedback>
                <FormFeedback valid={false}>{expiryErrorMessage}</FormFeedback>
              </div>
              <CardExpiryElement
                options={options}
                className={`${styles.input} ${
                  expiryErrorMessage && styles.invalid
                }`}
                onChange={(event: StripeCardExpiryElementChangeEvent): void => {
                  errorMessageTimeoutHandler(
                    expiryErrorMessageTimeout,
                    event,
                    (message: string) => {
                      if (event.complete) {
                        setHasExpiry(true);
                      } else {
                        setHasExpiry(false);
                        setExpiryErrorMessage(message);
                      }
                    }
                  );
                }}
              />
            </FormGroup>

            <FormGroup className={cvcErrorMessage ? 'is-invalid' : ''}>
              <div className="label-feedback">
                <Label>Security code</Label>
                <FormFeedback valid>Success</FormFeedback>
                <FormFeedback valid={false}>{cvcErrorMessage}</FormFeedback>
              </div>
              <CardCvcElement
                options={options}
                className={`${styles.input} ${
                  cvcErrorMessage && styles.invalid
                }`}
                onChange={(event: StripeCardCvcElementChangeEvent): void => {
                  errorMessageTimeoutHandler(
                    cvcErrorMessageTimeout,
                    event,
                    (message: string) => {
                      if (event.complete) {
                        setHasCVC(true);
                      } else {
                        setHasCVC(false);
                        setCvcErrorMessage(message);
                      }
                    }
                  );
                }}
              />
            </FormGroup>

            {errorMessage && <Alert color="danger">{errorMessage}</Alert>}

            <Button
              type="submit"
              size="lg"
              block
              disabled={
                !stripe || isLoading || !hasCard || !hasCVC || !hasExpiry
              }
              className={styles.button}>
              Save payment details
            </Button>
          </Form>
        </ContentSection>
      </ContentWrapper>
    </Popup>
  );
};

export default UpdatePaymentPopup;
