import React, { useState } from 'react';
import { Button, Alert } from 'reactstrap';
import styles from './update-subscription-popup.module.scss';
import { PLAN_NAME } from 'src/pages/constants';
import { Coupon, Plan } from 'src/pages/deprecated/payment/types';
import { getCurrencySymbol, isUserFromNewZealand } from 'src/lib/utils';
import { useSelector } from 'react-redux';
import { IAppState } from 'src/store';

type UpdateSubscriptionSummaryProps = {
  plan: Plan;
  weeklyMealPlanQty: number;
  currentCheckinCount: number | undefined;
  setPopupVisible: any;
  purchaseSubscription: () => Promise<void>;
  coupon?: Coupon;
};

export const UpdateSubscriptionSummary = ({
  plan,
  weeklyMealPlanQty,
  currentCheckinCount,
  setPopupVisible,
  purchaseSubscription,
  coupon,
}: UpdateSubscriptionSummaryProps) => {
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const user = useSelector((state: IAppState) => state.auth?.user);

  const isNewZealandUser =
    isUserFromNewZealand() || (user?.country ?? '') === 'New Zealand';

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      setErrorMessage('');
      await purchaseSubscription();
      setIsSubmitting(false);
      setPopupVisible(false);
    } catch (error) {
      setErrorMessage(
        'There was an error updating your subscription, please try again or contact us'
      );
    }
  };

  const price = Number(plan.mealPlanVariants[weeklyMealPlanQty]?.unitAmount);

  let amountOff = 0;
  if (coupon) {
    amountOff =
      (plan.mealPlanVariants[weeklyMealPlanQty]?.unitAmountCents *
        coupon.percentOff) /
      10000;
  }

  return (
    <div>
      <h3>Summary</h3>
      <div className={styles.field}>
        <div className={styles.inner}>
          <div className={styles.text}>{`${plan.id} subscription`}</div>
          <div className={styles.price}>
            {getCurrencySymbol(false) +
              Number(
                plan.mealPlanVariants[weeklyMealPlanQty]?.unitAmount
              ).toFixed(2)}
          </div>
        </div>
      </div>
      {!!coupon && !!amountOff && (
        <div className={styles.field}>
          <div className={styles.inner}>
            <div className={styles.text}>{coupon.percentOff}% off</div>
            <div className={styles.price}>
              {getCurrencySymbol(false)} -{amountOff.toFixed(2)}
            </div>
          </div>
        </div>
      )}
      <hr />
      <div className={styles.field}>
        <div className={styles.inner}>
          <div className={styles.text}>Total</div>
          <div className={styles.price}>
            {getCurrencySymbol(isNewZealandUser) +
              (price - Number(amountOff.toFixed(2))).toFixed(2)}{' '}
            every{' '}
            {plan?.intervalCount || 0 > 1
              ? `${plan?.intervalCount} ${plan?.interval}s`
              : plan?.interval}
          </div>
        </div>
      </div>
      {!PLAN_NAME.STANDARD_PLAN.includes(plan.id) &&
      currentCheckinCount &&
      currentCheckinCount < 8 ? (
        <div className={styles.field}>
          <div className={styles.inner}>
            <p>
              Please note, changes will take effect after your minimum 8 week
              subscription
            </p>
          </div>
        </div>
      ) : (
        <>
          <div className={styles.field}>
            <div className={styles.inner}>
              <p>
                Please note, changes will take effect on your next billing
                cycle.
              </p>
            </div>
          </div>

          {errorMessage !== '' && <Alert color="danger">{errorMessage}</Alert>}
        </>
      )}
      <Button block onClick={() => handleSubmit()} disabled={isSubmitting}>
        Update subscription
      </Button>
    </div>
  );
};
