import React, { FC } from 'react';
import { Box } from '@mui/material';
import styles from './number-input.module.scss';

export interface NumberInputProps {
  label: string;
  value: string | number | undefined;
  onChangeValue: (value: string) => void;
  autoFocus?: boolean;
}

export const NumberInput: FC<NumberInputProps> = ({
  label,
  value,
  onChangeValue,
  autoFocus,
}) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    if (inputValue === '' || /^-?\d+(\.\d{0,2})?$/.test(inputValue)) {
      onChangeValue(inputValue);
    }
  };
  return (
    <Box className={styles.numberInput}>
      <span className={styles.label}>{label}</span>
      <input
        className={styles.input}
        value={value}
        pattern="[0-9]*[.,]?[0-9]*"
        inputMode="decimal"
        onChange={handleInputChange}
        autoFocus={autoFocus}
      />
    </Box>
  );
};
